<ol>
  <li>
    <p>
      <b>Présentation et identification du RESPONSABLE</b>
    </p>
    <p>
      Le site web et le logo sont la propriété de GESCASER (ci-après le RESPONSABLE) à Valeur Ajoutée
      Numéro d'identification fiscale A25276635 et siège social Sant Pere 1 d'Almacelles.
    </p>
  </li>
  <li>
    <p><b>Description de l'activité</b></p>
    <p>
      Par le biais de ce site Internet, le RESPONSABLE souhaite vous informer sur ses activités et ses services.
      Le RESPONSABLE pourra, en fonction de l'évolution et des progrès de l'entreprise et du secteur,
      étendre son site Web pour inclure de nouveaux services, activités ou contenus, afin d'améliorer la
      services et la qualité du service rendu. Inversement, le RESPONSABLE se réserve également le
      droit d'annuler, de modifier, de remplacer ou de restreindre le contenu, les services ou les activités,
      expressément et sans préavis aux utilisateurs.
    </p>
    <p>
      Ce site Web s'adresse à la fois aux clients et à tous les autres utilisateurs qui le visitent (ci-après dénommés
      en tant qu'UTILISATEURS).
    </p>
    <p>
      Si vous avez des questions concernant ces conditions générales, veuillez nous contacter à
      gescaser@gescaser.com
    </p>
  </li>
  <li>
    <p>
      <b>Acceptation des conditions générales et utilisation du site Web</b>
    </p>
    <p>
      L'utilisation de ce site Web est régie par les conditions générales d'utilisation suivantes (ci-après
      les CONDITIONS GÉNÉRALES). Veuillez les lire attentivement, car l'accès à ce site Web et l'utilisation de ses
      contenu implique leur pleine acceptation.
    </p>
    <p>
      L'accès à ce site et aux informations qu'il contient est entièrement gratuit,
      sans préjudice du coût de la connexion téléphonique et des moyens utilisés pour établir le
      lien.
    </p>
    <p>
      Cependant, sur ce site Web, vous pouvez vous renseigner sur les différents services qui peuvent être loués par
      contacter le CONTRÔLEUR ; qui seront fournis selon les Conditions Spécifiques convenues
      entre les parties (ci-après les CONDITIONS PARTICULIERES).
    </p>
    <p>
      Nous vous informons que, pour accéder aux informations du site, nous n'avons pas besoin de votre
      données personnelles. Toutefois, si vous effectuez une demande en ligne ou prenez des dispositions
      liés aux services offerts ou demander l'un des services spécifiques offerts par le biais du
      site internet, il sera nécessaire de collecter vos données personnelles et, dans ce cas, nous vous demandons de
      lire attentivement notre POLITIQUE DE CONFIDENTIALITÉ, qui doit également être lue, comprise et acceptée.
    </p>
    <p>En acceptant ce contrat, vous déclarez :</p>
    <ol type="a">
      <li>
        Que vous êtes majeur et/ou avez la capacité de contracter, conformément à la
        les lois contractuelles générales de l'État où vous résidez.
      </li>
      <li>
        Que vous avez préalablement lu, compris et accepté les CONDITIONS GÉNÉRALES, la CONFIDENTIALITÉ
        POLICE et, le cas échéant, les CONDITIONS PARTICULIERES.
      </li>
    </ol>
  </li>
  <li>
    <p><b>Modifications des CONDITIONS GÉNÉRALES</b></p>
    <p>
      Les informations sur ce site Web et les services offerts changent continuellement et sont
      développé pour offrir la meilleure expérience et la meilleure valeur possible.
    </p>
    <p>
      Lorsque quelque chose est modifié qui implique une modification des CONDITIONS GÉNÉRALES, les SPÉCIFIQUES
      CONDITIONS et/ou la POLITIQUE DE CONFIDENTIALITÉ, ladite modification doit être effectuée et publiée
      et mis à jour. Par conséquent, il est recommandé de les lire tous périodiquement et/ou, ci-dessus
      le tout, avant de conclure tout contrat.
    </p>
    <p>
      Les CONDITIONS GÉNÉRALES, CONDITIONS SPÉCIFIQUES et/ou POLITIQUE DE CONFIDENTIALITÉ mises à jour seront disponibles sur
      ce site Web à tout moment.
    </p>
    <p>
      L'UTILISATEUR de ce site Web accepte ces modifications à partir du moment où il continue à utiliser
      le site Web et/ou les services fournis.
    </p>
  </li>
  <li>
    <p><b>Obligations essentielles de l'UTILISATEUR.</b></p>
    <p>
      Tous les UTILISATEURS, à partir du moment où ils commencent à utiliser le site Web et/ou contractent l'un des services
      qui y sont proposés, sont tenus de respecter les obligations essentielles suivantes vis-à-vis des tiers
      les parties et le CONTRÔLEUR :
    </p>
    <ol type="a">
      <li>
        Pour utiliser le site Web sans encourir des actions illégales ou illicites, ou des actions contraires à
        les dispositions des CONDITIONS GÉNÉRALES, de la POLITIQUE DE CONFIDENTIALITÉ et, le cas échéant, du
        CONDITIONS PARTICULIERES et leurs mises à jour.
      </li>
      <li>
        Ne pas endommager, désactiver, surcharger ou détériorer le site Web ou empêcher son utilisation normale ou
        jouissance.
      </li>
      <li>
        Ne pas tenter d'enfreindre les niveaux d'accès, de manipuler les données, de dupliquer et d'exporter
        données ou informations protégées par la propriété intellectuelle ou d'autres droits légaux, l'accès
        zones restreintes des systèmes informatiques du CONTRÔLEUR ou de tiers, introduire
        programmes, virus ou tout autre dispositif provoquant ou pouvant provoquer des modifications de l'ordinateur
        système du CONTRÔLEUR ou de tiers.
      </li>
      <li>
        Connaître les exigences techniques et légales nécessaires pour un accès correct aux
        ce site Web et, le cas échéant, la sous-traitance des produits et/ou services ; qui peut
        impliquent le téléchargement de certains programmes informatiques ou d'autres éléments logiques sur leur
        appareils informatiques.
      </li>
      <li>
        Ne pas utiliser, lier, divulguer, céder ou transférer à des tiers de quelque manière que ce soit, le contenu
        publié sur le site Web ou une partie de celui-ci, ou dans les informations du CONTRÔLEUR,
        sans l'autorisation écrite préalable expresse du RESPONSABLE.
      </li>
      <li>
        Ne pas nuire à l'image du CONTRÔLEUR de quelque manière que ce soit, ni exercer aucune activité ou faire
        tout commentaire pouvant nuire à l'image et à la renommée de GESCASER.
      </li>
      <li>
        Lire, comprendre et, si vous êtes d'accord, accepter les CONDITIONS GÉNÉRALES, la POLITIQUE DE CONFIDENTIALITÉ
        et, le cas échéant, les CONDITIONS PARTICULIERES et leurs mises à jour et plus particulièrement les
        POLITIQUE EN MATIÈRE DE LIENS ET/OU DE COOKIES du CONTRÔLEUR.
      </li>
    </ol>
  </li>
  <li>
    <p><b>Propriété intellectuelle</b></p>
    <p>
      Ce site Web, son contenu et ses logos sont protégés par les lois espagnoles et internationales
      lois sur la propriété intellectuelle et industrielle, et tous ces droits correspondent exclusivement à la
      CONTRÔLEUR, l'usage en étant réservé.
    </p>
    <p>
      La reproduction, la distribution, la communication, la transformation et/ou la divulgation publique et, en
      général, toute autre forme d'utilisation non autorisée, par quelque moyen que ce soit, de tout ou partie du contenu de
      ce site Web, sa conception, sa sélection et la présentation des matériaux inclus ici sont
      interdit; il en va de même pour les marques et les noms commerciaux inclus dans ce site Web. Tel
      les actions ne peuvent être effectuées qu'avec l'autorisation expresse et écrite préalable du RESPONSABLE
      et à condition qu'une référence explicite soit faite à la propriété du CONTRÔLEUR du
      droits de propriété intellectuelle et industrielle susmentionnés.
    </p>
    <p>
      En utilisant le site Web et/ou en contractant les services, l'UTILISATEUR n'acquiert aucun droit sur
      eux, ayant simplement le droit de les utiliser selon les dispositions des présentes GÉNÉRALES
      CONDITIONS et/ou, le cas échéant, les CONDITIONS PARTICULIERES.
    </p>
    <p>
      Dans le cas où vous, l'UTILISATEUR, détecteriez une activité susceptible de porter atteinte à des droits de propriété intellectuelle ou
      droit de propriété industrielle ou tout autre type de droit, veuillez nous en informer en envoyant un message à
      l'adresse email suivante : gescaser@gescaser.com
    </p>
  </li>
  <li>
    <p><b>Politique de confidentialité</b></p>
    <p>
      n le respect des dispositions de la législation nationale et européenne applicable en matière de
      protection des données personnelles, nous vous informons que les données personnelles que nos clients ou
      les clients potentiels peuvent nous fournir (tous deux dénommés le(s) "Client(s)"), soit par
      via le formulaire mis à disposition sur notre site Internet (le « Site Internet »), en envoyant un e-mail à l'adresse
      prévu à cet effet, par le biais d'e-mails adressés à nos professionnels ou par tout autre
      moyens de communication, seront inclus dans un fichier dont GESCASER (ci-après le
      CONTRÔLEUR) avec le numéro d'identification fiscale A25276635 et siège social à Sant Pere 1 à partir de
      Almacelles, est le propriétaire et le responsable du traitement des données.
    </p>
    <ol type="a">
      <li>
        Consentement et données collectées
        <p>
          En fournissant au RESPONSABLE les données personnelles demandées, le Client déclare que
          il a lu et accepte expressément la présente politique de confidentialité et, par conséquent, donne
          son consentement libre, spécifique, non équivoque et éclairé au RESPONSABLE pour traiter
          ses données personnelles conformément aux dispositions du présent document.
        </p>
        <p>
          Sauf mention contraire expresse, toutes les informations que nous demandons à nos Clients
          sur les formulaires disponibles sur le Site est obligatoire. Refus de fournir les données requises
          rendra impossible le traitement de la demande spécifique en question. Informez nous s'il vous plait
          immédiatement de toute modification de vos données afin que les informations contenues dans nos fichiers soient
          toujours à jour et exempt d'erreurs. A cet égard, le Client déclare que le
          les informations et données fournies sont exactes, à jour et véridiques.
        </p>
      </li>
      <li>
        Finalité du traitement et base juridique
        <p>
          Le but du traitement par le RESPONSABLE est de traiter la demande formulée, de répondre
          vos questions et demandes d'informations et, le cas échéant, maintenir et gérer le
          relation professionnelle, commerciale et/ou contractuelle établie avec l'un de nos
          bureaux et/ou professionnels (ci-après le(s) « Service(s) »).
        </p>
        <p>
          La base juridique du traitement du la cession de vos données personnelles par le RESPONSABLE est basée sur : i)
          le consentement du Client au traitement décrit dans la présente Politique de confidentialité et ii) le
          fourniture des Services du CONTRÔLEUR demandés par le Client.
        </p>
      </li>
      <li>
        À qui communiquons-nous vos données
        <p>
          Le CONTRÔLEUR ne transférera pas vos données personnelles à des tiers, sauf si cela est légalement
          requis ou si le Client nous a préalablement autorisés à le faire ou s'il est nécessaire
          pour la fourniture des Services demandés.
        </p>
        <p>
          Afin de pouvoir offrir le Service approprié au Client et de gérer les
          relation avec vous, le CONTRÔLEUR contracte la fourniture de services auprès de certains
          les sociétés qui, sous réserve du respect des prescriptions légales, peuvent avoir accès à
          vos données personnelles uniquement aux fins de fourniture du Service.
        </p>
      </li>
      <li>
        Transferts internationaux de données personnelles
        <p>
          Le règlement général sur la protection des données (RGPD) propose plusieurs mécanismes pour permettre aux
          transfert de données personnelles en dehors de l'Union européenne (UE). Ces mécanismes ont été mis en place
          pour vérifier que le niveau de protection est adéquat ou pour assurer la mise en œuvre de
          des mesures de sécurité appropriées lors du transfert de données personnelles vers un pays non membre de l'UE.
        </p>
        <p>
          Ce niveau de sécurité peut être atteint grâce à des clauses contractuelles types. Il est
          possible de confirmer que le niveau de protection est adéquat par une vérification
          procédures telles que celles prévues dans le cadre du bouclier de protection des données UE-États-Unis.
        </p>
        <p>
          Dans nos contrats actuels sur le traitement des données, nous nous engageons à maintenir un mécanisme qui
          permet le transfert de données personnelles en dehors de l'UE, comme stipulé dans la politique de protection des données
          Directif. En outre, nous prendrons l'engagement approprié à compter du 25 mai 2018
          (inclus), lors de l'entrée en vigueur du RGPD.
        </p>
        <p>
          Les autorités européennes de protection des données ont également confirmé que notre contrat standard
          clauses répondent aux exigences de sécurité nécessaires. Cela démontre que GESCASER
          les engagements contractuels sont conformes à la directive sur la protection des données et offrent une
          cadre pour le transfert de données à caractère personnel de l'UE vers d'autres parties du monde.
        </p>
      </li>
      <li>
        Durée de conservation des données personnelles
        <p>
          Nous conserverons vos données personnelles pendant toute la durée de la relation contractuelle ou,
          le cas échéant, jusqu'à ce que vous exprimiez votre souhait de voir vos données personnelles supprimées de notre
          dossier. À partir de ce moment, le CONTRÔLEUR gardera vos données personnelles bloquées pendant la
          délais légalement requis, et en tout état de cause, pour une durée de 10 ans conformément à
          la réglementation relative à la prévention du blanchiment de capitaux et du financement du terrorisme. Nous allons
          détruire vos données personnelles après l'expiration du délai légal.
        </p>
      </li>
      <li>
        Exercice des droits
        <p>
          Vous pouvez exercer vos droits d'accès, de rectification, de suppression, de limitation des
          traitement, opposition et portabilité des données personnelles par l'envoi d'une communication écrite
          avec une photocopie de votre pièce d'identité au RESPONSABLE à l'adresse e-mail suivante :
          gescaser@gescaser.com
        </p>
      </li>
      <li>
        Plainte auprès d'une autorité de contrôle
        <p>
          Vous êtes informé que vous avez le droit de déposer une plainte auprès de l'Office espagnol des données
          Agence de protection (www.aepd.es) comme autorité de contrôle si vous considérez que
          LE CONTRÔLEUR n'a pas traité vos données personnelles conformément à la
          législation sur la protection des données personnelles.
        </p>
      </li>
      <li>
        Sécurité
        <p>
          Le RESPONSABLE s'engage à adopter les mesures de sécurité techniques et organisationnelles
          établi par la loi pour garantir la sécurité de vos données personnelles et empêcher leur
          altération, perte, traitement ou accès non autorisé, compte tenu de l'état de
          technologie, la nature des données stockées et les risques auxquels elles sont exposées, le tout en
          conformément aux dispositions de la réglementation applicable.
        </p>
      </li>
      <li>
        Attribution de données de tiers au CONTRÔLEUR
        <p>
          En cas de fourniture au RESPONSABLE des données personnelles de tiers nécessaires
          pour la fourniture des Services, vous déclarez avoir préalablement obtenu leur
          consentement exprès pour les transférer au CONTRÔLEUR.
        </p>
      </li>
      <li>
        Modifications de notre politique de confidentialité
        <p>
          Le CONTRÔLEUR peut mettre à jour cette politique de confidentialité à tout moment. Une version mise à jour de ce
          La Politique de confidentialité sera toujours disponible sur la Plateforme. Si ces modifications affectent les finalités
          non prévu dans la présente politique de confidentialité, nous vous informerons suffisamment à l'avance de
          ce traitement ultérieur par courrier électronique (à la dernière adresse fournie), afin que vous puissiez
          exercer les droits que vous jugez appropriés et auxquels vous avez droit en vertu de la
          réglementation applicable.
        </p>
      </li>
    </ol>
  </li>
  <li>
    <p><b>Responsabilité</b></p>
    <p>
      Le RESPONSABLE s'efforcera à tout moment de fournir le service avec la meilleure
      qualité. Sans préjudice de ce qui précède, nous déclinons toute responsabilité découlant des défaillances,
      interruptions ou dommages causés par des défaillances du système, des interférences ou des déconnexions ou par le
      dysfonctionnement du service.
    </p>
    <p>
      De même, le RESPONSABLE décline toute responsabilité et/ou responsabilité pour les dommages de toute nature
      ou nature résultant de l'action illégitime de tiers par quelque moyen que ce soit ou de l'utilisation
      du site de manière abusive ou inappropriée par les utilisateurs, et en aucun cas
      le CONTRÔLEUR assumera-t-il quelque responsabilité que ce soit pour l'action ou la négligence d'autrui qui
      peuvent ou non affecter, directement ou indirectement, les serveurs et autres équipements informatiques du
      L'UTILISATEUR ou des tiers.
    </p>
    <p>
      L'UTILISATEUR s'engage à indemniser et à dégager de toute responsabilité le CONTRÔLEUR, ses filiales, associés,
      dirigeants, collaborateurs et/ou employés de toute réclamation ou demande, y compris les honoraires d'avocats,
      faites par des tiers, à la suite de l'utilisation inappropriée ou non autorisée par l'UTILISATEUR des
      site Web et/ou des services proposés, ou la violation par l'UTILISATEUR des CONDITIONS GÉNÉRALES, PARTICULIÈRES
      CONDITIONS, le cas échéant, et/ou POLITIQUE DE CONFIDENTIALITÉ, ainsi que toute violation de la législation ou tout
      autres droits.
    </p>
    <p>
      Le CONTRÔLEUR ne peut garantir l'absence de virus ou d'autres logiciels malveillants susceptibles d'affecter le
      équipement informatique de l'UTILISATEUR. Ce dernier devrait donc prendre des mesures pour éviter d'éventuelles
      de tels risques compte tenu de l'état actuel des télécommunications.
    </p>
    <p>
      Le CONTRÔLEUR n'est pas responsable des interruptions du site Web causées par un cas de force majeure ou
      circonstances indépendantes de sa volonté.
    </p>
    <p>
      De même, le CONTRÔLEUR peut interrompre temporairement l'accès, pour des raisons de sécurité ou en raison de la
      restructuration des ressources informatiques, afin d'améliorer le service d'utilisation du site.
    </p>
    <p>
      Le RESPONSABLE ne fournit à l'UTILISATEUR que les informations publiées sur le site. L'utilisateur,
      par conséquent, est seul responsable de l'utilisation correcte de ces informations et de s'assurer que
      cette utilisation est faite conformément aux CONDITIONS GENERALES, aux CONDITIONS PARTICULIERES où
      applicable, et la POLITIQUE DE CONFIDENTIALITÉ.
    </p>
    <p>
      Si l'UTILISATEUR n'est pas satisfait de l'utilisation du site Web, de son contenu ou de ses services ou de tout
      partie des présentes CONDITIONS GÉNÉRALES, de la POLITIQUE DE CONFIDENTIALITÉ et/ou des CONDITIONS SPÉCIFIQUES, son
      le seul et unique recours consistera à cesser d'accéder au site Web et/ou d'utiliser ses services.
    </p>
  </li>
  <li>
    <p><b>Liens et&nbsp;Cookies</b></p>
    <p>
      Si vous vous connectez à un lien ou à une bannière autre que ce site Web, vous devez savoir que vous
      naviguerez sur un autre site Internet, dont les règles d'utilisation et la politique de confidentialité sont
      hors du contrôle du CONTRÔLEUR. Par conséquent, il est conseillé de vérifier ces règles
      d'utilisation et politique de confidentialité, afin de connaître les règles applicables et le traitement qu'elles
      faire de vos données personnelles.
    </p>
    <p>
      Il est interdit d'inclure des contenus ou des services dans des sites Web de tiers qui pourraient nuire à la
      image de ce site Web ou le CONTRÔLEUR, et ce dernier se réserve le droit d'interdire, de limiter
      ou exiger la suppression des liens établis, lorsqu'elle le juge opportun, sans sous aucune
      circonstances étant tenu de verser une quelconque indemnité pour ce motif.
    </p>
    <p>
      Le site Web www (le « site Web ») du CONTRÔLEUR utilise des cookies, qui sont de petits fichiers
      contenant des informations relatives à votre navigation sur ce Site et dont la finalité principale est de
      simplifier votre navigation. Veuillez continuer à lire pour en savoir plus sur les cookies que nous utilisons,
      leur but et d'autres informations d'intérêt.
    </p>
    <p>Que sont les cookies ?</p>
    <p>
      Un cookie est un fichier qui est placé sur le site Web lorsque vous y accédez et que vous l'utilisez. Les cookies nous permettent de
      stocker et récupérer des informations sur vos habitudes de navigation sur le Site. Les informations qu'ils
      obtenir est lié, par exemple, au nombre de pages que vous visitez, à la fréquence et à la récurrence
      des visites, la durée de votre visite, etc.
    </p>
    <p>Quoi type de cookies que nous utilisons ?</p>
    <ol type="a">
      <li>
        Selon l'entité qui les gère :
        <p>
          Cookies propres : ceux qui sont envoyés à votre ordinateur depuis nos ordinateurs, ou les domaines de
          qui fournissent le service que vous demandez.
        </p>
        <p>
          Cookies tiers : ceux qui sont envoyés à votre ordinateur depuis un ordinateur ou un domaine qui
          n'est pas géré par nous, mais par une autre organisation associée.
        </p>
      </li>
      <li>
        Selon combien de temps ils restent actifs :
        <p>
          Cookies de session : ceux qui stockent les informations nécessaires pendant la session et la
          changements qui y sont liés.
        </p>
        <p>
          Cookies persistants : ils sont stockés sur le disque dur et lisent notre site Web chaque fois que vous
          faire une visite de retour. Un site permanent a une date d'expiration spécifique. Le cookie s'arrêtera
          travailler après cette date.
        </p>
      </li>
      <li>
        Selon le but :
        <p>
          Cookies techniques : ils sont nécessaires à la navigation et au bon fonctionnement du
          site Internet.
        </p>
        <p>
          Cookies de personnalisation : ceux qui vous permettent d'accéder au service avec des
          caractéristiques en fonction d'un certain nombre de critères tels que la langue, le type de
          navigateur par lequel le service est accessible, les paramètres régionaux à partir desquels vous accédez au service,
          etc.
        </p>
        <p>
          Cookies d'analyse : ceux qui nous permettent de quantifier le nombre d'utilisateurs et donc la
          mesure et analyse statistique de l'utilisation faite par les utilisateurs des services fournis.
        </p>
        <p>
          Si vous ne souhaitez pas autoriser l'utilisation de cookies sur notre site Web, veuillez suivre ces
          des instructions:
        </p>
        <p>
          Ces procédures sont sujettes à révision ou modification par les développeurs de navigateurs, de sorte
          nous ne pouvons pas garantir que la dernière version entièrement conforme soit disponible à tout moment.
        </p>
        <p>
          Pour empêcher l'installation de cookies, veuillez suivre les instructions de votre navigateur :
        </p>
        <p>
          <b>Internet Explorer :</b> cliquez sur "Outils &gt; Options Internet », puis sélectionnez le
          Onglet "Confidentialité". Sélectionnez le paramètre souhaité et cliquez sur le bouton Paramètres avancés.
          Cochez la case "Ignorer la gestion automatique des cookies". Sélectionnez "Accepter" ou "Bloquer"
          option. Nous vous recommandons d'activer l'option "Toujours autoriser les cookies de session" pour activer le
          navigation optimale sur notre site.
        </p>
        <p>
          <b>Firefox :</b> cliquez sur "Outils &gt; Options" dans la barre de menu et sélectionnez "Confidentialité"
          languette. Selon la version du navigateur que vous utilisez, cochez ou décochez « Accepter les cookies
          des sites » ou « Dites aux sites que je ne souhaite pas être suivi ». Tout cookie installé peut être
          supprimé en cliquant sur l'option "supprimer les cookies individuels".
        </p>
        <p>
          <b>Chrome :</b> cliquez sur "Outils &gt; Paramètres », puis sélectionnez l'onglet « Confidentialité &gt;
          Paramètres de contenu ». Après avoir sélectionné l'option "Afficher les options avancées", sélectionnez le
          paramétrage des cookies. Si vous ne souhaitez pas que des cookies soient installés, vous pouvez sélectionner l'option
          "Bloquer les cookies tiers et données de site".
        </p>
        <p>
          <b>Opéra :</b> Dans les "Paramètres &gt; Options", allez dans l'onglet "Avancé". Dans le
          Dans le menu "Cookies", sélectionnez les options "Accepter" ou "Ne jamais accepter les cookies". Si tu veux
          avoir un plus grand contrôle sur les cookies qui sont installés sur votre ordinateur, nous
          vous recommandons de sélectionner l'option "Demander avant d'accepter les cookies".
        </p>
        <p>
          <b>Safari :</b> cliquez sur "Outils &gt; Préférences" et sélectionnez l'onglet "Sécurité". Sous le
          rubrique "Accepter les cookies" choisissez "Toujours" ou "Jamais". Si vous avez un iPhone, vous devez vous rendre sur
          “Paramètres &gt; Safari » puis sélectionnez si vous acceptez ou non les cookies.
        </p>
      </li>
    </ol>
    <p>
      Si vous utilisez un autre navigateur, vous pouvez obtenir plus d'informations sur la configuration de l'installation de
      cookies via la section d'aide ou l'assistance de ce navigateur.
    </p>
    <p>
      Si vous avez besoin d'aide pour effectuer la configuration des cookies de votre navigateur, veuillez nous envoyer un
      envoyez un e-mail à gescaser@gescaser.com et nous vous contacterons dès que possible pour vous aider.
    </p>
    <p>Mises à jour et modifications de la Politique relative aux cookies :</p>
    <p>
      Le CONTRÔLEUR peut modifier cette politique de cookies conformément aux dispositions légales et réglementaires
      exigences, ou dans le but d'adapter cette politique aux instructions émises par le
      Agence espagnole de protection des données, raison pour laquelle il est conseillé à l'utilisateur de le visiter périodiquement.
    </p>
  </li>
  <li>
    <p><b>Nullité et inefficacité des clauses</b></p>
    <p>
      Si un clause incluse dans les présentes CONDITIONS GENERALES et/ou les CONDITIONS PARTICULIERES est déclarée
      totalement ou partiellement nulle et non avenue ou inefficace, cette nullité ou inefficacité
      n'affectent que la partie de la disposition qui est nulle et non avenue ou inefficace, et le GÉNÉRAL
      CONDITIONS et CONDITIONS SPÉCIFIQUES restent en vigueur à tous autres égards, et ces
      disposition, ou une partie de celle-ci qui est affectée, sera considérée comme nulle et non avenue.
    </p>
  </li>
  <li>
    <p><b>Droit applicable et juridiction</b></p>
    <p>
      Pour tout problème ou divergence pouvant survenir concernant le site Web, le contenu et les services
      contracté, l'UTILISATEUR et le CONTRÔLEUR, renonçant expressément à toute autre juridiction qui pourrait
      leur appliquer, se soumettre expressément à la loi espagnole, à la juridiction des tribunaux espagnols et, en
      particulier, les tribunaux de Madrid, à moins que la loi applicable n'en détermine spécifiquement un autre
      juridiction ou législation différente.
    </p>
  </li>
</ol>
