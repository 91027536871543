import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpErrorResponse,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { Injectable } from '@angular/core';
import { LocalStorageService } from '@services/localStorage/local-storage.service';
import { Router } from '@angular/router';
import * as HttpStatus from 'http-status-codes';
import { TranslationMWService } from '@services/translation-middleware/translation-middleware.service';

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {
  constructor(
    private toastr: ToastrService,
    private translationMWService: TranslationMWService,
    private router: Router,
    private localStorageService: LocalStorageService
  ) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      // if want to retry the call whn error
      // retry(1),
      catchError((error: HttpErrorResponse) => {
        let errorMessage = '';

        if (error.error instanceof ErrorEvent) {
          // client-side error
          errorMessage = error.error.message;
          this.createToastr(errorMessage, 'client');
        } else {
          // server-side error
          if (
            (error.status >= HttpStatus.StatusCodes.INTERNAL_SERVER_ERROR &&
              error.status <= HttpStatus.StatusCodes.NETWORK_AUTHENTICATION_REQUIRED) ||
            error.status === HttpStatus.StatusCodes.BAD_REQUEST
          ) {
            this.createToastr('', error.status.toString());
          }

          if (this.localStorageService.userIsAuthenticated) {
            if (error.status === HttpStatus.StatusCodes.UNAVAILABLE_FOR_LEGAL_REASONS) {
              // User disabled
              this.toastr.error(
                this.translationMWService.instant(
                  'error-interceptor.toastr.error.user-disabled.message'
                ),
                this.translationMWService.instant(
                  'error-interceptor.toastr.error.user-disabled.title'
                )
              );
            }
            if (error.status === HttpStatus.StatusCodes.LOCKED) {
              // User's company disabled
              this.toastr.error(
                this.translationMWService.instant(
                  'error-interceptor.toastr.error.company-disabled.message'
                ),
                this.translationMWService.instant(
                  'error-interceptor.toastr.error.company-disabled.title'
                )
              );
            }
            // Expired token or unauthorized access to endpoint
            if (error.status === HttpStatus.StatusCodes.UNAUTHORIZED) {
              this.toastr.error(
                this.translationMWService.instant(
                  'error-interceptor.toastr.error.unauthorized.message'
                ),
                this.translationMWService.instant(
                  'error-interceptor.toastr.error.unauthorized.title'
                )
              );
            }
          }
          if (
            [
              HttpStatus.StatusCodes.UNAVAILABLE_FOR_LEGAL_REASONS,
              HttpStatus.StatusCodes.LOCKED,
              HttpStatus.StatusCodes.UNAUTHORIZED,
            ].includes(error.status)
          ) {
            this.localStorageService.clear();
            void this.router.navigate(['/auth/login']);
          }
        }
        // eslint-disable-next-line no-console
        console.log('error catched!', error, errorMessage);

        return throwError(error);
      })
    );
  }

  private createToastr(message: string, errorId: string) {
    if (this.toastr.toasts.length === 0 || message !== this.toastr.previousToastMessage) {
      const errorTitle = this.translationMWService.instant(`error.${errorId}.title`);
      this.toastr.error(message, errorTitle as string);
    }
  }
}
