<ol>
  <li>
    <p>
      <b>Presentation and identification of the CONTROLLER</b>
    </p>
    <p>
      The website and logo is the property of GESCASER (hereinafter the CONTROLLER) with Value Added
      Tax ID number A25276635 and registered office Sant Pere 1 from Almacelles.
    </p>
  </li>
  <li>
    <p><b>Description of activity</b></p>
    <p>
      Through this website, the CONTROLLER wishes to inform you about its activities and services.
      The CONTROLLER may, depending on the development and progress of the company and the sector,
      extend its website to include new services, activities or contents, in order to improve the
      services and quality of the service provided. Conversely, the CONTROLLER also reserves the
      right to cancel, modify, substitute or restrict the contents, services or activities,
      expressly and without prior notice to the users.
    </p>
    <p>
      This website is aimed at both customers and all other users who visit it (hereinafter referred
      to as USERS).
    </p>
    <p>
      If you have any questions about these General Conditions, please contact us at
      gescaser@gescaser.com
    </p>
  </li>
  <li>
    <p>
      <b>Acceptance of General Conditions and use of the website</b>
    </p>
    <p>
      The use of this website is regulated by the following General Conditions of Use (hereinafter
      the GENERAL CONDITIONS). Please read them carefully, as access to this website and use of its
      contents implies full acceptance of them.
    </p>
    <p>
      Access to this website and the information contained herein is completely free of charge,
      without prejudice to the cost of the telephone connection and the means used to establish the
      connection.
    </p>
    <p>
      However, on this website, you can find out about the different services that can be hired by
      contacting the CONTROLLER; which will be provided according to the Specific Conditions agreed
      between the parties (hereinafter the SPECIFIC CONDITIONS).
    </p>
    <p>
      We inform you that, in order to access the information on the website, we do not need your
      personal data. However, in the event that you make an online enquiry or make any arrangements
      related to the services offered or request any of the specific services offered through the
      website, it will be necessary to collect your personal data and, in this case, we ask you to
      carefully read our PRIVACY POLICY, which must also be read, understood and accepted.
    </p>
    <p>By accepting this contract, you declare:</p>
    <ol type="a">
      <li>
        That you are of legal age and/or have the capacity to contract, in accordance with the
        general contracting laws of the State where you reside.
      </li>
      <li>
        That you have previously read, understood and accepted the GENERAL CONDITIONS, the PRIVACY
        POLICY and, where applicable, the SPECIFIC CONDITIONS.
      </li>
    </ol>
  </li>
  <li>
    <p><b>Changes to the GENERAL CONDITIONS</b></p>
    <p>
      The information on this website and the services offered are continually changing and being
      developed to provide the best possible experience and value.
    </p>
    <p>
      When something is altered that implies a change to the GENERAL CONDITIONS, the SPECIFIC
      CONDITIONS and/or the PRIVACY POLICY, said alteration shall be made, and shall be published
      and updated. Therefore, you are recommended to read all of them periodically and/or, above
      all, before concluding any contract.
    </p>
    <p>
      The updated GENERAL CONDITIONS, SPECIFIC CONDITIONS and/or PRIVACY POLICY will be available on
      this website at all times.
    </p>
    <p>
      The USER of this website shall accept such alterations from the moment he/she continues to use
      the website and/or the services provided.
    </p>
  </li>
  <li>
    <p><b>Essential USER obligations.</b></p>
    <p>
      All USERS, from the moment they begin to use the website and/or contract any of the services
      offered on it, are required to comply with the following essential obligations vis-à-vis third
      parties and the CONTROLLER:
    </p>
    <ol type="a">
      <li>
        To use the website without incurring in illegal or unlawful actions, or actions contrary to
        the provisions of the GENERAL CONDITIONS, the PRIVACY POLICY and, where applicable, the
        SPECIFIC CONDITIONS and their updates.
      </li>
      <li>
        Not to damage, disable, overload or impair the website or prevent its normal use or
        enjoyment.
      </li>
      <li>
        Not to make any attempt to infringe access levels, manipulate data, duplicate and export
        data or information protected by intellectual property or other legal rights, access
        restricted areas of the computer systems of the CONTROLLER or third parties, introduce
        programmes, viruses or any other device that causes or may cause changes to the computer
        system of the CONTROLLER or third parties.
      </li>
      <li>
        To be familiar with the technical and legal requirements necessary for correct access to
        this website and, if applicable, the contracting of the products and/or services; which may
        involve the downloading of certain computer programmes or other logical elements onto their
        computer devices.
      </li>
      <li>
        Not to use, link, disclose, assign or transfer to third parties in any way, the contents
        published on the website or any part of them, or in the information of the CONTROLLER,
        without the express prior written authorization of the CONTROLLER.
      </li>
      <li>
        Not to damage the image of the CONTROLLER in any way, nor to perform any activity or make
        any comment that could damage the image and good name of GESCASER.
      </li>
      <li>
        To read, understand and, if you agree, accept the GENERAL CONDITIONS, the PRIVACY POLICY
        and, where applicable, the SPECIFIC CONDITIONS and their updates and, more specifically, the
        LINKS AND/OR COOKIES POLICY of the CONTROLLER.
      </li>
    </ol>
  </li>
  <li>
    <p><b>Intellectual Property</b></p>
    <p>
      This website, its contents and its logos are protected by Spanish and international
      intellectual and industrial property laws, and all such rights correspond exclusively to the
      CONTROLLER, the use thereof being reserved.
    </p>
    <p>
      The reproduction, distribution, communication, transformation and/or public disclosure and, in
      general, any other form of unauthorized use, by any means, of all or part of the contents of
      this website, its design, selection and presentation of the materials included herein is
      prohibited; the same being true of the brands and trade names included in this website. Such
      actions may only be carried out with the express prior written authorization of the CONTROLLER
      and provided that explicit reference is made to the CONTROLLER’s ownership of the
      aforementioned intellectual and industrial property rights.
    </p>
    <p>
      By using the website and/or contracting the services, the USER does not acquire any right over
      them, simply being entitled to use them according to the provisions of these GENERAL
      CONDITIONS and/or, where applicable, the SPECIFIC CONDITIONS.
    </p>
    <p>
      In the event that you, the USER, detect any activity that may infringe any intellectual or
      industrial property right or any other type of right, please notify us by sending a message to
      the following email address: gescaser@gescaser.com
    </p>
  </li>
  <li>
    <p><b>Privacy Policy</b></p>
    <p>
      n compliance with the provisions of applicable national and European legislation on the
      protection of personal data, we inform you that the personal data that our customers or
      potential customers may provide us with (both referred to as the “Customer(s)”), either by
      using the form provided on our website (the “Website”), by sending an e-mail to the address
      provided for this purpose, by means of e-mails addressed to our professionals or by any other
      means of communication, will be included in a file of which GESCASER (hereinafter the
      CONTROLLER) with Value Added Tax ID number A25276635 and registered office in Sant Pere 1 from
      Almacelles, is the owner and data controller.
    </p>
    <ol type="a">
      <li>
        Consent and collected data
        <p>
          By providing the CONTROLLER with the personal data requested, the Customer declares that
          he/she has read and expressly accepted the present Privacy Policy and, therefore, gives
          his/her free, specific, unambiguous and informed consent to the CONTROLLER to process
          his/her personal data in accordance with the provisions of this document.
        </p>
        <p>
          Unless specifically stated to the contrary, all information we request from our Customers
          on the forms available on the Website is required. Refusal to provide the required data
          will make it impossible to deal with the specific request in question. Please inform us
          immediately of any changes to your data so that the information contained in our files is
          always up to date and free of errors. In this regard, the Customer declares that the
          information and data provided are accurate, up to date and truthful.
        </p>
      </li>
      <li>
        Purpose of processing and legal basis
        <p>
          The purpose of the processing by the CONTROLLER is to deal with the request made, answer
          your queries and requests for information and, where appropriate, maintain and manage the
          professional, commercial and/or contractual relationship established with any of our
          offices and/or professionals (hereinafter the “Service(s)”).
        </p>
        <p>
          The legal basis for the processing of your personal data by the CONTROLLER is based on: i)
          the Customer's consent to the processing described in this Privacy Policy and ii) the
          provision of the Services of the CONTROLLER requested by the Customer.
        </p>
      </li>
      <li>
        Who we communicate your data to
        <p>
          The CONTROLLER will not transfer your personal data to third parties, unless it is legally
          required or if the Customer has previously authorized us to do so or if it is necessary
          for the provision of the requested Services.
        </p>
        <p>
          In order to be able to offer the appropriate Service to the Customer and to manage the
          relationship with you, the CONTROLLER contracts the provision of services from certain
          companies which, subject to compliance with the legal requirements, may be given access to
          your personal data solely for the purposes providing the Service.
        </p>
      </li>
      <li>
        International transfers of personal data
        <p>
          The General Data Protection Regulation (GDPR) offers several mechanisms to enable the
          transfer of personal data outside the European Union (EU). These mechanisms have been set
          up to check that the level of protection is adequate or to ensure the implementation of
          appropriate security measures when transferring personal data to a non-EU country.
        </p>
        <p>
          This level of security can be achieved through standard contractual clauses. It is
          possible to confirm that the level of protection is adequate through verification
          procedures such as those in the EU-US Privacy Shield framework.
        </p>
        <p>
          In our current contracts on data processing, we undertake to maintain a mechanism that
          enables the transfer of personal data outside the EU, as stipulated in the Data Protection
          Directive. In addition, we will make the appropriate commitment as of 25 May 2018
          (inclusive), when the GDPR enters into force.
        </p>
        <p>
          European data protection authorities have also confirmed that our standard contractual
          clauses meet the necessary security requirements. This demonstrates that GESCASER
          contractual commitments comply with the Data Protection Directive and provide a legal
          framework for the transfer of personal data from the EU to other parts of the world.
        </p>
      </li>
      <li>
        Period of storage of personal data
        <p>
          We will retain your personal data for the duration of the contractual relationship or,
          where applicable, until you state your wish to have your personal data deleted from our
          file. From that moment on, the CONTROLLER will keep your personal data blocked during the
          legally required periods, and in any event, for a period of 10 years in accordance with
          the regulations on the prevention of money laundering and financing of terrorism. We will
          destroy your personal data after the expiry of the legally required period.
        </p>
      </li>
      <li>
        Exercise of rights
        <p>
          You may exercise your rights of access, rectification, deletion, limitation of the
          processing, opposition and portability of personal data by sending a written communication
          with a photocopy of your ID card to the CONTROLLER at the following e-mail address:
          gescaser@gescaser.com
        </p>
      </li>
      <li>
        Complaint to a supervisory authority
        <p>
          You are informed that you have the right to file a complaint with the Spanish Data
          Protection Agency (www.aepd.es) as the supervisory authority if you consider that the
          CONTROLLER has not processed your personal data in accordance with the applicable
          legislation on the protection of personal data.
        </p>
      </li>
      <li>
        Security
        <p>
          The CONTROLLER undertakes to adopt the technical and organizational security measures
          established by law to guarantee the security of your personal data and to prevent their
          alteration, loss, unauthorized processing or access, taking into account the state of
          technology, the nature of the data stored and the risks to which they are exposed, all in
          accordance with the provisions of the applicable regulations.
        </p>
      </li>
      <li>
        Assignment of third party data to the CONTROLLER
        <p>
          In the event of providing the CONTROLLER with the personal data of third parties required
          for the provision of the Services, you declare that you have previously obtained their
          express consent to transfer them to the CONTROLLER.
        </p>
      </li>
      <li>
        Changes to our privacy policy
        <p>
          The CONTROLLER may update this Privacy Policy at any time. An updated version of this
          Privacy Policy will always be available on the Platform. If these changes affect purposes
          not provided for in this Privacy Policy, we will inform you sufficiently in advance of
          this subsequent processing by email (to the last address provided), so that you may
          exercise the rights you deem appropriate and to which you are entitled under the
          applicable regulations.
        </p>
      </li>
    </ol>
  </li>
  <li>
    <p><b>Liability</b></p>
    <p>
      The CONTROLLER shall endeavour at all times to provide the service with the highest possible
      quality. Without prejudice to the foregoing, we decline all liability arising from failures,
      interruptions or damage caused by system failures, interference or disconnections or by the
      malfunction of the service.
    </p>
    <p>
      Similarly, the CONTROLLER declines all liability and/or responsibility for damages of any kind
      or nature resulting from the illegitimate action of third parties by any means or from the use
      of the website in an improper or inappropriate manner by the users, and under no circumstances
      shall the CONTROLLER bear any liability whatsoever for the action or negligence of others that
      may or may not affect, directly or indirectly, the servers and other computer equipment of the
      USER or third parties.
    </p>
    <p>
      The USER agrees to indemnify and hold harmless the CONTROLLER, its subsidiaries, associates,
      managers, collaborators and/or employees from any claim or demand, including lawyers' fees,
      made by third parties, as a result of the USER'S inappropriate or unauthorized use of the
      website and/or the services offered, or the USER'S breach of the GENERAL CONDITIONS, SPECIAL
      CONDITIONS, if applicable, and/or PRIVACY POLICY, as well as any breach of legislation or any
      other rights.
    </p>
    <p>
      The CONTROLLER cannot guarantee the absence of viruses or other malware that may affect the
      computer equipment of the USER. The latter should therefore take measures to avoid possible
      risks of this kind in view of the current state of telecommunications.
    </p>
    <p>
      The CONTROLLER is not responsible for interruptions to the website caused by force majeure or
      circumstances beyond its control.
    </p>
    <p>
      Likewise, the CONTROLLER may interrupt access temporarily, for security reasons or due to the
      restructuring of computer resources, in order to improve the website usage service.
    </p>
    <p>
      The CONTROLLER only provides the USER with the information published on the website. The USER,
      therefore, is solely responsible for the correct use of such information and for ensuring that
      such use is made in accordance with the GENERAL CONDITIONS, the SPECIFIC CONDITIONS where
      applicable, and the PRIVACY POLICY.
    </p>
    <p>
      If the USER is not satisfied with the use of the website, its contents or services or with any
      part of these GENERAL CONDITIONS, the PRIVACY POLICY and/or SPECIFIC CONDITIONS, his or her
      sole and exclusive remedy will be to stop accessing the website and/or using its services.
    </p>
  </li>
  <li>
    <p><b>Links and&nbsp;Cookies</b></p>
    <p>
      If you connect to any link or banner other than this website, you should be aware that you
      will be browsing another website on the Internet, whose rules of use and privacy policy are
      beyond the control of the CONTROLLER. Therefore, it is advisable for you to check such rules
      of use and privacy policy, in order to find out the applicable rules and the processing they
      make of your personal data.
    </p>
    <p>
      It is forbidden to include contents or services in third party websites that could harm the
      image of this website or the CONTROLLER, and the latter reserves the right to prohibit, limit
      or demand the cancellation of the links made, when it deems appropriate, without under any
      circumstances being required to pay any kind of compensation for this reason.
    </p>
    <p>
      The website www (the “Website”) of the CONTROLLER uses cookies, which are small files
      containing information about your browsing of this Website and whose main purpose is to
      simplify your browsing hereof. Please continue reading to learn more about the cookies we use,
      their purpose and other information of interest.
    </p>
    <p>What are cookies?</p>
    <p>
      A cookie is a file that is placed in Website when you access and use it. Cookies allow us to
      store and retrieve information about your browsing habits on the Website. The information they
      obtain is related, for example, to the number of pages you visit, the frequency and recurrence
      of visits, the length of your visit, etc.
    </p>
    <p>What kind of cookies we use?</p>
    <ol type="a">
      <li>
        According to the entity that manages them:
        <p>
          Own cookies: those that are sent to your computer from our computers, or domains from
          which provide the service you request.
        </p>
        <p>
          Third party cookies: those that are sent to your computer from a computer or domain that
          is not managed by us, but by other associated organization.
        </p>
      </li>
      <li>
        Depending on how long they remain active:
        <p>
          Session cookies: those that store the necessary information during the session and the
          changes related to it.
        </p>
        <p>
          Persistent cookies: they are stored on the hard disk and reads our website each time you
          make a return visit. A permanent site has a specific expiration date. The cookie will stop
          working after that date.
        </p>
      </li>
      <li>
        According to the purpose:
        <p>
          Technical cookies: they are necessary for navigation and the proper functioning of the
          website.
        </p>
        <p>
          Customization cookies: those that allow you to access the service with predefined
          characteristics depending on a number of criteria features, such as language, the type of
          browser through which the service is accessed, the locale from which you access service,
          etc.
        </p>
        <p>
          Analysis cookies: those that allow us to quantify the number of users and thus the
          measurement and statistical analysis of the use made by users of the services provided.
        </p>
        <p>
          If you do not want to allow the use of cookies on our Website, please follow these
          instructions:
        </p>
        <p>
          These procedures are subject to revision or modification by the developers of browsers, so
          we can not guarantee that the last fully conforming version is available at all times.
        </p>
        <p>
          To prevent the installation of cookies, please follow the instructions of your browser:
        </p>
        <p>
          <b>Internet Explorer:</b> click on “Tools &gt; Internet Options” menu and then select the
          “Privacy” tab. Select the desired setting and click on the advanced settings button.
          Activate the “Override automatic cookie handling” box. Select the “Accept” or “Block”
          option. We recommend activating the “Always allow session cookies” option to enable the
          optimal browsing on our website.
        </p>
        <p>
          <b>Firefox:</b> click on “Tools &gt; Options” in the menu bar and select the “Privacy”
          tab. Depending on the browser version you are using, select or deselect “Accept cookies
          from sites” or “Tell sites that I do not want to be tracked”. Any cookies installed can be
          removed by clicking on the “remove individual cookies” option.
        </p>
        <p>
          <b>Chrome:</b> click on “Tools &gt; Settings” menu, and then select the tab “Privacy &gt;
          Content settings”. After selecting the option “Show advanced options”, select the desired
          cookie setting. If you do not want cookies to be installed, you may select the option
          “Block third-party cookies and site data”.
        </p>
        <p>
          <b>Opera:</b> In the “Settings &gt; Options” menu, go to the “Advanced” tab. In the
          “Cookies” menu select the options “Accept” or “not accept cookies ever.” If you want to
          have a greater level of control over the cookies that are installed on your computer, we
          recommend you to select the option “Ask before accepting cookies.”
        </p>
        <p>
          <b>Safari:</b> click on “Tools &gt; Preferences” and select the “Security” tab. Under the
          heading “Accept cookies” choose “Always” or “Never”. If you have an iPhone, you must go to
          “Settings &gt; Safari” and then select whether you do or do not want to accept cookies.
        </p>
      </li>
    </ol>
    <p>
      If you use another browser, you can get more information on configuring the installation of
      cookies through the help section or assistance from that browser.
    </p>
    <p>
      If you need help to carry out the configuration of your browser cookies, please send us an
      email to gescaser@gescaser.com and we will contact you as soon as possible to help you.
    </p>
    <p>Updates and changes to the Cookies Policy:</p>
    <p>
      The CONTROLLER may change this Cookies Policy in accordance with legal and regulatory
      requirements, or with the aim of adapting this policy to the instructions issued by the
      Spanish Data Protection Agency, for which reason the user is advised to visit it periodically.
    </p>
  </li>
  <li>
    <p><b>Nullity and ineffectiveness of clauses</b></p>
    <p>
      If any clause included in these GENERAL CONDITIONS and/or the SPECIFIC CONDITIONS is declared
      totally or partially null and void or ineffective, such nullity or ineffectiveness shall
      affect only that provision part thereof that is null and void or ineffective, and the GENERAL
      CONDITIONS and SPECIFIC CONDITIONS shall remain in force in all other respects, and such
      provision, or part thereof that is affected, shall be deemed null and void.
    </p>
  </li>
  <li>
    <p><b>Applicable law and jurisdiction</b></p>
    <p>
      For any issue or discrepancy that may arise regarding the website, contents and services
      contracted, the USER and the CONTROLLER, expressly waiving any other jurisdiction that may
      apply to them, expressly submit to Spanish law, the jurisdiction of Spanish Courts and, in
      particular, the Courts of Madrid, unless the applicable law specifically determines another
      jurisdiction or different legislation.
    </p>
  </li>
</ol>
