<ol>
  <li>
    <p>
      <b>Presentaci&oacute; i identificaci&oacute; del CONTROLADOR</b>
    </p>
    <p>
      La p&agrave;gina web i logo &eacute;s la propietat de GESCASER (des d'ara el CONTROLADOR) amb Valorar ID
      d'Impost Afegit numera Un25276635 i oficina registrada Sant Pere 1 d'Almacelles.
    </p>
  </li>
  <li>
    <p><b>Descripci&oacute; d'activitat</b></p>
    <p>
      A trav&eacute;s d'aquesta p&agrave;gina web, els desitjos de CONTROLADOR per informar-te aproximadament les seves activitats i serveis.
      El CONTROLADOR pot, depenent en el desenvolupament i progr&eacute;s de l'empresa i el sector,
      estendre la seva p&agrave;gina web per incloure serveis nous, activitats o continguts, per tal de millorar els
      serveis i la qualitat del servei va proporcionar. Per contra, el CONTROLADOR tamb&eacute; reserva el
      correcte de cancel&middot;lar, modifica, substitut o restringir els continguts, serveis o activitats,
      expressly i sense previ av&iacute;s als usuaris.
    </p>
    <p>
      Aquesta p&agrave;gina web &eacute;s apuntada a ambd&oacute;s clients i tots altres usuaris que el visiten (des d'ara referit
      a tan USUARIS).
    </p>
    <p>
      Si tens qualssevol q&uuml;estions sobre aquestes Condicions Generals, si us plau contactar-nos a
      gescaser@gescaser.com
    </p>
  </li>
  <li>
    <p>
      <b>Acceptaci&oacute; d'&uacute;s i Condicions Generals de la p&agrave;gina web</b>
    </p>
    <p>
      L'&uacute;s d'aquesta p&agrave;gina web &eacute;s regulat per les Condicions Generals seg&uuml;ents d'&Uacute;s (des d'ara
      les CONDICIONS GENERALS). Si us plau llegit els amb compte, mentre acc&eacute;s a aquesta p&agrave;gina web i l'&uacute;s dels seus
      continguts implica acceptaci&oacute; plena d'ells.
    </p>
    <p>
      Acc&eacute;s a aquesta p&agrave;gina web i la informaci&oacute; van contenir herein &eacute;s completament lliure de c&agrave;rrec,
      sense perjudici del cost de la connexi&oacute; telef&ograve;nica i el mitj&agrave; utilitzat per establir la
      connexi&oacute;.
    </p>
    <p>
      Tanmateix, en aquesta p&agrave;gina web, pots descobrir sobre els serveis diferents que poden ser contractat per
      contactar el CONTROLADOR; quin ser&agrave; proporcionat segons les Condicions Espec&iacute;fiques acordades
      entre els partits (des d'ara les CONDICIONS ESPEC&Iacute;FIQUES).
    </p>
    <p>
      T'informem que, per tal d'accedir la informaci&oacute; en la p&agrave;gina web, no necessitem la vostra
      dada personal. Tanmateix, en l'esdeveniment que fas una investigaci&oacute; en l&iacute;nia o fer qualssevol arranjaments
      van relacionar als serveis oferts o demanar qualsevol dels serveis espec&iacute;fics va oferir a trav&eacute;s de la
      p&agrave;gina web, sigui necessari de recollir la vostra dada personal i, en aquest cas, et preguntem a
      amb compte llegit la nostra POL&Iacute;TICA DE PRIVADESA, els quals tamb&eacute; han de ser llegits, ent&egrave;s i va acceptar.
    </p>
    <p>Per acceptar aquest contracte, declares:</p>
    <ol type="a">
      <li>
        All&ograve; ets d'edat legal i/o tenir la capacitat de contreure, d'acord amb les
        lleis contractants generals de l'Estat on resideixes.
      </li>
      <li>
        All&ograve; t'anteriorment ha llegit, ent&egrave;s i va acceptar les CONDICIONS GENERALS, la POL&Iacute;TICA DE PRIVADESA
        i, on aplicable, les CONDICIONS ESPEC&Iacute;FIQUES.
      </li>
    </ol>
  </li>
  <li>
    <p><b>Canvis a les CONDICIONS GENERALS</b></p>
    <p>
      La informaci&oacute; en aquesta p&agrave;gina web i els serveis van oferir cont&iacute;nuament est&agrave; canviant i sent
      desenvolupat per proporcionar l'experi&egrave;ncia possible millor i valor.
    </p>
    <p>
      Quan alguna cosa &eacute;s alterada que implica un canvi a les CONDICIONS GENERALS, les CONDICIONS
      ESPEC&Iacute;FIQUES i/o la POL&Iacute;TICA DE PRIVADESA, va dir l'alteraci&oacute; ser&agrave; feta, i ser&agrave; publicat
      i va actualitzar. Per aix&ograve;, ets recomanat per llegir tot d'ells peri&ograve;dicament i/o, sobretot,
      abans de concloure qualsevol contracte.
    </p>
    <p>
      Les CONDICIONS GENERALS actualitzades, CONDICIONS ESPEC&Iacute;FIQUES i/o la pol&iacute;tica de privadesa ser&agrave; disponible en
      aquesta p&agrave;gina web en tot moment.
    </p>
    <p>
      L'USUARI d'aquesta p&agrave;gina web acceptar&agrave; tals alteracions del moment ell/continua utilitzar
      la p&agrave;gina web i/o els serveis van proporcionar.
    </p>
  </li>
  <li>
    <p><b>Obligacions d'USUARI essencial.</b></p>
    <p>
      Tots els  USUARIS, del moment comencen per utilitzar la p&agrave;gina web i/o contreure qualsevol dels serveis
      va oferir damunt el, &eacute;s requerit a comply amb les obligacions essencials seg&uuml;ents vis-&agrave;-vis tercers
      partits i el CONTROLADOR:
    </p>
    <ol type="a">
      <li>
        Per utilitzar la p&agrave;gina web sense inc&oacute;rrer en en il&middot;legal o unlawful accions, o les accions contr&agrave;ries
        a les provisions de les CONDICIONS GENERALS, la POL&Iacute;TICA DE PRIVADESA i, on aplicable, les
        CONDICIONS ESPEC&Iacute;FIQUES i les seves actualitzacions.
      </li>
      <li>
        No per avariar, impossibilita, overload o impair la p&agrave;gina web o impedir el seu &uacute;s normal o
        diversi&oacute;.
      </li>
      <li>
        No per fer qualsevol intent a infringe nivells d'acc&eacute;s, manipula dada, duplicat i dada
        d'exportaci&oacute; o la informaci&oacute; protegida per propietat intel&middot;lectual o altres drets legals, l'acc&eacute;s
        va restringir &agrave;rees dels sistemes d'ordinador del CONTROLADOR o tercers partits, introduir
        programes, virus o qualsevol altre dispositiu que causes o pot causar canvis al sistema
        d'ordinador del CONTROLADOR o tercers partits.
      </li>
      <li>
        Per ser familiar amb els requisits t&egrave;cnics i legals necessaris per acc&eacute;s correcte a
        aquesta p&agrave;gina web i, si aplicable, el contractant dels productes i/o serveis; quins poden
        implicar el descarregant de programes d'ordinador segur o altres elements l&ograve;gics als seus
        dispositius d'ordinador.
      </li>
      <li>
        No per utilitzar, enlla&ccedil;, revela, assigna o transfer&egrave;ncia a tercers partits en qualsevol manera, els continguts
        van publicar en la p&agrave;gina web o qualsevol part d'ells, o en la informaci&oacute; del CONTROLADOR,
        sense l'expressar autoritzaci&oacute; escrita pr&egrave;via del CONTROLADOR.
      </li>
      <li>
        No per avariar la imatge del CONTROLADOR en qualsevol manera, ni per actuar qualsevol activitat o fer
        qualsevol comentari que podria avariar la imatge i nom bo de GESCASER.
      </li>
      <li>
        Per llegir, ent&eacute;n i, si acordes, acceptar les CONDICIONS GENERALS, la POL&Iacute;TICA DE PRIVADESA
        i, on aplicable, les CONDICIONS ESPEC&Iacute;FIQUES i les seves actualitzacions i, m&eacute;s concretament, els
        ENLLA&Ccedil;OS I/O POL&Iacute;TICA de GALETES del CONTROLADOR.
      </li>
    </ol>
  </li>
  <li>
    <p><b>Propietat intel&middot;lectual</b></p>
    <p>
      Aquesta p&agrave;gina web, els seus continguts i el seu logos &eacute;s protegit per intel&middot;lectual espanyol i
      internacional i lleis de propietat industrial, i tots tals drets corresponen exclusivament al
      CONTROLADOR, l'&uacute;s d'aix&ograve; el ser va reservar.
    </p>
    <p>
      La reproducci&oacute;, distribuci&oacute;, comunicaci&oacute;, transformaci&oacute; i/o revelaci&oacute; p&uacute;blica i, en general,
      qualsevol altra forma d'unauthorized &uacute;s, per qualsevol mitj&agrave;, de tot o part dels continguts d'aquesta
      p&agrave;gina web, el seu disseny, la selecci&oacute; i la presentaci&oacute; dels materials van incloure herein &eacute;s
      prohibit; el mateix sent cert de les marques i els noms comercials inclosos en aquesta p&agrave;gina web. Tals
      accions nom&eacute;s poden ser dutes a terme amb l'expressar autoritzaci&oacute; escrita pr&egrave;via del CONTROLADOR
      i amb la condici&oacute; que la refer&egrave;ncia expl&iacute;cita &eacute;s feta a la propietat del CONTROLADOR de l'aforementioned
      intel&middot;lectual i drets de propietat industrial.
    </p>
    <p>
      Per utilitzar la p&agrave;gina web i/o contraient els serveis, l'USUARI no adquireix qualsevol correcte per damunt
      els, senzillament sent titulat per utilitzar-los segons les provisions d'aquestes CONDICIONS
      GENERALS i/o, on aplicable, les CONDICIONS ESPEC&Iacute;FIQUES.
    </p>
    <p>
      En l'esdeveniment que et, l'USUARI, detectar qualsevol activitat que pot infringe qualsevol propietat intel&middot;lectual
      o industrial dret o qualsevol altre tipus de correcte, si us plau notificar-nos per enviar un missatge a l'adre&ccedil;a
      de correu electr&ograve;nic seg&uuml;ent: gescaser@gescaser.com
    </p>
  </li>
  <li>
    <p><b>Pol&iacute;tica de privadesa</b></p>
    <p>
      n Conformitat amb les provisions de legislaci&oacute; nacional i europea aplicable en
      la protecci&oacute; de dada personal, t'informem que la dada personal que els nostres clients o
      els clients potencials ens poden proporcionar amb (ambd&oacute;s referit a com el Client(s)”), qualsevol per
      utilitzar la forma proporcionada en la nostra p&agrave;gina web (la P&agrave;gina web”), per enviar un correu electr&ograve;nic a l'adre&ccedil;a
      proporcionada per aquest prop&ograve;sit, mitjan&ccedil;ant els correus electr&ograve;nics van adre&ccedil;ar als nostres professionals o per qualsevol altre
      mitj&agrave; de comunicaci&oacute;, ser&agrave; incl&ograve;s en un arxiu del qual GESCASER (des d'ara el
      CONTROLADOR) amb Valorar ID d'Impost Afegit numera Un25276635 i oficina registrada en Sant Pere 1 d'Almacelles,
      &eacute;s el propietari i controlador de dada.
    </p>
    <ol type="a">
      <li>
        Consentiment i dada recollida
        <p>
          Per proporcionar el CONTROLADOR amb la dada personal demanada, el Client declara que
          ell/ha llegit i expressly va acceptar la Pol&iacute;tica de privadesa present i, per tant, d&oacute;na
          el seu/el seu lliure, espec&iacute;fic, consentiment inequ&iacute;voc i informat al CONTROLADOR per processar
          la seva/la seva dada personal d'acord amb les provisions d'aquest document.
        </p>
        <p>
          Llevat que concretament declarat al contrari, tota informaci&oacute; demanem dels nostres Clients
          en les formes disponibles en la P&agrave;gina web &eacute;s requerida. Negativa per proporcionar la dada requerida
          el far&agrave; impossible de tractar la petici&oacute; espec&iacute;fica en q&uuml;esti&oacute;. Si us plau informar-nos
          immediatament de qualssevol canvis a la vostra dada de manera que la informaci&oacute; continguda en els nostres arxius &eacute;s
          sempre actualitzat i lliure d'errors. Pel que fa a aix&ograve;, el Client declara que la
          informaci&oacute; i la dada proporcionada &eacute;s acurat, actualitzat i vera&ccedil;.
        </p>
      </li>
      <li>
        Prop&ograve;sit de processar i base legal
        <p>
          El prop&ograve;sit del processament pel CONTROLADOR &eacute;s per tractar la petici&oacute; feta, resposta
          les vostres consultes i peticions per informaci&oacute; i, on pertoqui, mant&eacute; i dirigir el
          professional, anunci i/o la relaci&oacute; contractual establerta amb qualsevol de les nostres
          oficines i/o professionals (des d'ara el Servei(s)”).
        </p>
        <p>
          La base legal pel processament de la vostra dada personal pel CONTROLADOR &eacute;s basada damunt: i)
          el consentiment del Client al processament descrit en aquesta Pol&iacute;tica de privadesa i ii) la
          provisi&oacute; dels Serveis del CONTROLADOR demanat pel Client.
        </p>
      </li>
      <li>
        Qui comuniquem la vostra dada a
        <p>
          El CONTROLADOR no transferir&agrave; la vostra dada personal a tercers partits, llevat que &eacute;s legalment
          requerit o si el Client ha anteriorment ens va autoritzar per fer tan o si &eacute;s necessari
          per la provisi&oacute; del va demanar Serveis.
        </p>
        <p>
          Per tal de ser capa&ccedil; d'oferir el Servei apropiat al Client i per dirigir la
          relaci&oacute; amb tu, el CONTROLADOR contreu la provisi&oacute; de serveis d'empreses
          segures que, tema a conformitat amb els requisits legals, pot ser acc&eacute;s donat a
          la vostra dada personal nom&eacute;s pels prop&ograve;sits que proporcionen el Servei.
        </p>
      </li>
      <li>
        Transfer&egrave;ncies internacionals de dada personal
        <p>
          El Control de Protecci&oacute; de Dada General (GDPR) ofereix diversos mecanismes per habilitar la
          transfer&egrave;ncia de dada personal a fora de la Uni&oacute; Europea (UE). Aquests mecanismes han estat instal&middot;lats
          per comprovar que el nivell de protecci&oacute; &eacute;s adequat o per assegurar la implementaci&oacute; de
          mesures de seguretat apropiada quan transferint dada personal a un pa&iacute;s extracomunitari.
        </p>
        <p>
          Aquest nivell de seguretat pot ser aconseguit a trav&eacute;s de cl&agrave;usules contractuals est&agrave;ndards. &Eacute;s possible de confirmar que el nivell de protecci&oacute; &eacute;s adequat a trav&eacute;s de verification
          procediments com aquells en la UE-ENS marc d'Escut de la Intimitat.
        </p>
        <p>
          En els nostres contractes actuals en processament de dades, emprenem per mantenir un mecanisme que
          habilita la transfer&egrave;ncia de dada personal a fora de la UE, mentre estipulat en la Directiva de Protecci&oacute;
          de la Dada. A m&eacute;s a m&eacute;s, farem el comprom&iacute;s apropiat mentre de 25 maig 2018
          (inclusive), quan el GDPR entra a a for&ccedil;a.
        </p>
        <p>
          Autoritats de protecci&oacute; de dada europees tamb&eacute; han confirmat que les nostres cl&agrave;usules contractuals
          est&agrave;ndards coneixen els requisits de seguretat necessaris. Aix&ograve; demostra que GESCASER
          compromisos contractuals comply amb la Directiva de Protecci&oacute; de la Dada i proporcionar un marc
          legal per la transfer&egrave;ncia de dada personal de la UE a altres parts del m&oacute;n.
        </p>
      </li>
      <li>
        Per&iacute;ode d'emmagatzematge de dada personal
        <p>
          Retindrem la vostra dada personal per la duraci&oacute; de la relaci&oacute; contractual o,
          on aplicable, fins que declares el vostre desig de tenir la vostra dada personal eliminada del nostre
          arxiu. D'aquell moment en&ccedil;&agrave;, el CONTROLADOR mantindr&agrave; la vostra dada personal blocada durant
          el legalment va requerir per&iacute;odes, i en tot cas, per un per&iacute;ode de 10 anys d'acord amb
          els controls en la prevenci&oacute; de diners laundering i finan&ccedil;ant de terrorisme. Destruirem
           la vostra dada personal despr&eacute;s de l'expiry del per&iacute;ode legalment requerit.
        </p>
      </li>
      <li>
        Exercici de drets
        <p>
          Pots exercitar els vostres drets d'acc&eacute;s, rectificaci&oacute;, deletion, limitaci&oacute; del
          processament, oposici&oacute; i portability de dada personal per enviar una comunicaci&oacute; escrita
          amb una fotoc&ograve;pia de la vostra targeta d'identitat al CONTROLADOR a l'adre&ccedil;a de correu electr&ograve;nic seg&uuml;ent:
          gescaser@gescaser.com
        </p>
      </li>
      <li>
        Queixa a una autoritat supervisora
        <p>
          Ets informat que tens el correcte d'arxivar una queixa amb l'Ag&egrave;ncia de Protecci&oacute;
          de Dada espanyola (www.aepd.es) com l'autoritat supervisora si consideres que el
          CONTROLADOR no ha processat la vostra dada personal d'acord amb la legislaci&oacute;
          aplicable en la protecci&oacute; de dada personal.
        </p>
      </li>
      <li>
        Seguretat
        <p>
          El CONTROLADOR empr&egrave;n per adoptar la seguretat t&egrave;cnica i organitzativa les mesures
          van establir per llei per garantir la seguretat de la vostra dada personal i per impedir la seva
          alteraci&oacute;, p&egrave;rdua, unauthorized processant o acc&eacute;s, tenint en compte l'estat de
          tecnologia, la naturalesa de la dada emmagatzemada i els riscos al qual s&oacute;n exposats, tot
          d'acord amb les provisions dels controls aplicables.
        </p>
      </li>
      <li>
        Assignment De tercera dada de partit al CONTROLADOR
        <p>
          En l'esdeveniment de proporcionar el CONTROLADOR amb la dada personal de tercers partits va requerir
          per la provisi&oacute; dels Serveis, declares que t'anteriorment ha obtingut el seu
          expressar consentiment per transferir-los al CONTROLADOR.
        </p>
      </li>
      <li>
        Canvis a la nostra pol&iacute;tica de privadesa
        <p>
          El CONTROLADOR pot actualitzar aquesta Pol&iacute;tica de privadesa a qualsevol temps. Una versi&oacute; actualitzada d'aquesta
          Pol&iacute;tica de privadesa sempre ser&agrave; disponible en la Plataforma. Si aquests canvis afecten els prop&ograve;sits
          no proporcionats per en aquesta Pol&iacute;tica de privadesa, t'informarem suficientment per endavant d'aquest
          processament subseg&uuml;ent per correu electr&ograve;nic (al durar l'adre&ccedil;a proporcionada), de manera que pots
          exercitar els drets tu deem apropiat i al qual ets titulat sota
          els controls aplicables.
        </p>
      </li>
    </ol>
  </li>
  <li>
    <p><b>Responsabilitat</b></p>
    <p>
      El CONTROLADOR  endeavour en tot moment per proporcionar el servei amb la qualitat possible
      m&eacute;s alta. Sense perjudici del foregoing, declinem tota responsabilitat que sorgeix de fracassos,
      interrupcions o avariar causat per fracassos de sistema, interfer&egrave;ncia o desconnexions o pel
      malfunction del servei.
    </p>
    <p>
      Semblantment, el CONTROLADOR declina tota responsabilitat i/o responsabilitat per danys de qualsevol amable
      o la naturalesa que resulta de l'acci&oacute; il&middot;leg&iacute;tima de tercers partits per qualsevol mitj&agrave; o de l'&uacute;s
      de la p&agrave;gina web en una manera impr&ograve;pia o inadequada pels usuaris, i en cap circumst&agrave;ncia
       el CONTROLADOR aguanta qualsevol responsabilitat whatsoever per l'acci&oacute; o neglig&egrave;ncia d'altres que
      poden o no pot afectar, directament o indirectament, els servidors i altre equipament d'ordinador de l'USUARI
      o tercers partits.
    </p>
    <p>
      L'USUARI acorda indemnitzar i control harmless el CONTROLADOR, les seves filials, associa,
      directors, col&middot;laboradors i/o empleats de qualsevol reclamaci&oacute; o demanda, incloent els costos dels advocats,
      va fer per tercers partits, arran de l'USUARI  inadequat o unauthorized &uacute;s de la
      p&agrave;gina web i/o els serveis van oferir, o la ruptura de l'USUARI de les CONDICIONS GENERALS, CONDICIONS
      ESPECIALS, si aplicable, i/o POL&Iacute;TICA DE PRIVADESA, aix&iacute; com qualsevol ruptura de legislaci&oacute; o qualsevol
      altres drets.
    </p>
    <p>
      El CONTROLADOR no pot garantir l'abs&egrave;ncia de virus o altre malware que poden afectar l'equipament
      d'ordinador de l'USUARI. L'&uacute;ltim per aix&ograve; hauria d'agafar mesures per evitar riscos
      possibles d'aquesta classe en vista de l'estat actual de telecomunicacions.
    </p>
    <p>
      El CONTROLADOR no &eacute;s responsable per les interrupcions a la p&agrave;gina web van causar per for&ccedil;a majeure o
      circumst&agrave;ncies all&egrave;n el seu control.
    </p>
    <p>
      Aix&iacute; mateix, el CONTROLADOR pot interrompre acc&eacute;s temporalment, per raons de seguretat o a causa de la
      reestructuraci&oacute; de recursos d'ordinador, per tal de millorar el servei d'&uacute;s de la p&agrave;gina web.
    </p>
    <p>
      El CONTROLADOR nom&eacute;s proporciona l'USUARI amb la informaci&oacute; publicada en la p&agrave;gina web. L'USUARI,
      per tant, &eacute;s nom&eacute;s responsable per l'&uacute;s correcte de tal informaci&oacute; i per assegurar que
      tal &uacute;s &eacute;s fet d'acord amb les CONDICIONS GENERALS, les CONDICIONS ESPEC&Iacute;FIQUES on
      aplicable, i la POL&Iacute;TICA DE PRIVADESA.
    </p>
    <p>
      Si l'USUARI no &eacute;s satisfet amb l'&uacute;s de la p&agrave;gina web, els seus continguts o serveis o amb
      qualsevol part d'aquestes CONDICIONS GENERALS, la POL&Iacute;TICA DE PRIVADESA i/o CONDICIONS ESPEC&Iacute;FIQUES, el seu o el seu
      remei &uacute;nic i exclusiu ser&agrave; per parar accedint la p&agrave;gina web i/o utilitzant els seus serveis.
    </p>
  </li>
  <li>
    <p><b>Enlla&ccedil;os i&nbsp;Galetes</b></p>
    <p>
      Si connectes a qualsevol enlla&ccedil; o pancarta altre que aquesta p&agrave;gina web, hauries de ser conscient que ser&agrave;s browsing una altra p&agrave;gina web en l'Internet, les regles del qual d'&uacute;s i pol&iacute;tica de privadesa s&oacute;n
      all&egrave;n el control del CONTROLADOR. Per aix&ograve;, &eacute;s aconsellable per tu per comprovar tals regles
      d'&uacute;s i pol&iacute;tica de privadesa, per tal de descobrir les regles aplicables i el processament fan de la vostra dada personal.
    </p>
    <p>
      &Eacute;s prohibit per incloure continguts o serveis dins terceres p&agrave;gines web de partit que podrien fer mal la
      imatge d'aquesta p&agrave;gina web o el CONTROLADOR, i les reserves &uacute;ltimes el correcte de prohibir, l&iacute;mit
      o reclamar l'anulaci&oacute; dels enlla&ccedil;os va fer, quan ell deems apropiat, sense sota qualssevol
      circumst&agrave;ncies que s&oacute;n requerit per pagar qualsevol classe d'indemnitzaci&oacute; per aquesta ra&oacute;.
    </p>
    <p>
      La p&agrave;gina web www (la P&agrave;gina web”) de les galetes d'usos del CONTROLADOR, els quals s&oacute;n arxius petits
      contenint informaci&oacute; sobre el vostre browsing d'aquesta P&agrave;gina web i el prop&ograve;sit principal de la qual &eacute;s per
      simplificar el vostre browsing hereof. Si us plau continua llegir per aprendre m&eacute;s sobre les galetes utilitzem,
      el seu prop&ograve;sit i altra informaci&oacute; d'inter&egrave;s.
    </p>
    <p>Quin &eacute;s galetes?</p>
    <p>
      Una galeta &eacute;s un arxiu que &eacute;s col&middot;locada dins P&agrave;gina web quan accedeixes i utilitzar-lo. Les galetes ens permeten per
      emmagatzemar i recuperar informaci&oacute; sobre el vostre browsing h&agrave;bits en la P&agrave;gina web. La informaci&oacute; obtenen &eacute;s relacionat, per exemple, al n&uacute;mero de p&agrave;gines visites, la freq&uuml;&egrave;ncia i recurrence
      de visites, la longitud de la vostra visita, etc.
    </p>
    <p>Quina classe de galetes utilitzem?</p>
    <ol type="a">
      <li>
        Segons l'entitat que els dirigeix:
        <p>
          Galetes pr&ograve;pies: els que s&oacute;n enviats al vostre ordinador dels nostres ordinadors, o &agrave;mbits de
          quin proporcionar el servei demanes.
        </p>
        <p>
          Terceres galetes de partit: els que s&oacute;n enviats al vostre ordinador d'un ordinador o &agrave;mbit que
          no &eacute;s dirigit per nosaltres, per&ograve; per altra organitzaci&oacute; associada.
        </p>
      </li>
      <li>
        Depenent damunt quant temps queden actius:
        <p>
          Galetes de sessi&oacute;: els que emmagatzemen la informaci&oacute; necess&agrave;ria durant la sessi&oacute; i els
          canvis van relacionar a ell.
        </p>
        <p>
          Galetes persistents: s&oacute;n emmagatzemats en el disc dur i llegeix la nostra p&agrave;gina web cada cop fas una visita de retorn. Un lloc permanent t&eacute; un espec&iacute;fic expiration data. La galeta parar&agrave;
          treballar despr&eacute;s d'all&ograve; data.
        </p>
      </li>
      <li>
        Segons el prop&ograve;sit:
        <p>
          Galetes t&egrave;cniques: s&oacute;n necessaris per navegaci&oacute; i el funcionament apropiat de la
          p&agrave;gina web.
        </p>
        <p>
          Customization Galetes: els que et permeten per accedir el servei amb predefined
          les caracter&iacute;stiques que depenen en un n&uacute;mero de caracter&iacute;stiques de criteris, com llengua, el tipus del
          navegador a trav&eacute;s de quin el servei &eacute;s accedit, el locale de quin accedeixes servei,
          etc.
        </p>
        <p>
          Galetes d'an&agrave;lisi: els que ens permeten per quantificar el n&uacute;mero d'usuaris i per aix&ograve; la
          mida i an&agrave;lisi estad&iacute;stica de l'&uacute;s fet pels usuaris dels serveis van proporcionar.
        </p>
        <p>
          Si no vols permetre l'&uacute;s de galetes en la nostra P&agrave;gina web, si us plau seguir aquestes
          instruccions:
        </p>
        <p>
          Aquests procediments s&oacute;n subjectes a revisi&oacute; o modificaci&oacute; pels desenvolupadors de navegadors, aix&iacute; que
          no podem garantir que el durar plenament adaptant-se la versi&oacute; &eacute;s disponible en tot moment.
        </p>
        <p>
          Per impedir la instal&middot;laci&oacute; de galetes, si us plau seguir les instruccions del vostre navegador:
        </p>
        <p>
          <b>Explorer d'Internet:</b> clic en “carta &gt; d'Opcions d'Internet d'Eines i llavors seleccionar el “tabulador”
          d'Intimitat. Seleccionar l'enquadrament desitjat i clic en el va avan&ccedil;ar bot&oacute; d'enquadraments.
          Activate El “Override la galeta autom&agrave;tica que maneja” caixa. Seleccionar el “Acceptar” o “opci&oacute;
          de Bloc. Recomanem activating el “Sempre permetre opci&oacute; de galetes de la sessi&oacute; per habilitar l'optimal
          browsing en la nostra p&agrave;gina web.
        </p>
        <p>
          <b>Firefox:</b> Clic en “Opcions &gt; d'Eines” en el bar de carta i seleccionar el “tabulador”
          d'Intimitat. Depenent en la versi&oacute; de navegador est&agrave;s utilitzant, selecciona o desseleccionar “Acceptar galetes
          de llocs” o “Dir llocs que no vull ser seguit”. Qualssevol galetes instal&middot;lades pot ser
          tret per clicking en el “treure opci&oacute; de galetes individuals.
        </p>
        <p>
          <b>Croma:</b> clic en “carta &gt; d'Enquadraments de les Eines, i llavors seleccionar els enquadraments “de Contingut &gt;
          d'Intimitat de tabulador”. Despr&eacute;s de seleccionar l'Espectacle “d'opci&oacute; va avan&ccedil;ar opcions”, seleccionar l'enquadrament
          de galeta desitjat. Si no vols galetes per ser instal&middot;lades, pots seleccionar el Bloc
          “d'opci&oacute; tercer-galetes de partit i dada de lloc”.
        </p>
        <p>
          <b>&Ograve;pera:</b> En la “carta &gt; d'Opcions” dels Enquadraments, va a el “tabulador” Avan&ccedil;at. En
          la “carta” de Galetes selecciona les opcions “Accepten” o “no acceptar galetes mai.” Si vols
          tenir un nivell m&eacute;s gran de control sobre les galetes que s&oacute;n instal&middot;lades en el vostre ordinador, et recomanem per seleccionar l'opci&oacute; “Pregunta abans d'acceptar galetes.”
        </p>
        <p>
          <b>Safari:</b> clic en “Prefer&egrave;ncies &gt; d'Eines” i seleccionar el “tabulador” de Seguretat. Sota
          l'encap&ccedil;alant “Accepta les galetes” escullen “Sempre” o “Mai”. Si tens un iPhone, has d'anar a “Safari
           &gt; d'Enquadraments” i llavors seleccionar si fas o no vol acceptar galetes.
        </p>
      </li>
    </ol>
    <p>
      Si utilitzes un altre navegador, pots aconseguir m&eacute;s informaci&oacute; damunt configurant la instal&middot;laci&oacute; de
      galetes a trav&eacute;s de la secci&oacute; d'ajuda o assist&egrave;ncia d'aquell navegador.
    </p>
    <p>
      Si necessites ajuda per dur a terme la configuraci&oacute; de les vostres galetes de navegador, si us plau enviar-nos un
      correu electr&ograve;nic a gescaser@gescaser.com i et contactarem al m&eacute;s aviat possible per ajudar-te.
    </p>
    <p>Actualitzacions i canvis a la Pol&iacute;tica de Galetes:</p>
    <p>
      El CONTROLADOR pot canviar aquesta Pol&iacute;tica de Galetes d'acord amb requisits legals i
      reguladors, o amb l'objectiu d'adaptar aquesta pol&iacute;tica a les instruccions va emetre per l'Ag&egrave;ncia
      de Protecci&oacute; de Dada espanyola, per quina ra&oacute; l'usuari &eacute;s aconsellat per visitar-lo peri&ograve;dicament.
    </p>
  </li>
  <li>
    <p><b>Nul&middot;litat i ineffectiveness de cl&agrave;usules</b></p>
    <p>
      Si qualsevol cl&agrave;usula inclosa en aquestes CONDICIONS GENERALS i/o les CONDICIONS ESPEC&Iacute;FIQUES &eacute;s declarada
      totalment o parcialment null i void o inefica&ccedil;, tal nul&middot;litat o ineffectiveness afectar&agrave;
       nom&eacute;s que part de provisi&oacute; d'aix&ograve; que &eacute;s null i void o inefica&ccedil;, i les CONDICIONS
      GENERALS i les condicions ESPEC&Iacute;FIQUES quedaran en vigor dins tots altres respectes, i tal
      provisi&oacute;, o part d'aix&ograve; que &eacute;s afectada, ser&agrave; deemed null i void.
    </p>
  </li>
  <li>
    <p><b>Jurisdicci&oacute; i llei aplicable</b></p>
    <p>
      Per qualsevol assumpte o discrep&agrave;ncia que pot sorgir pel que fa a la p&agrave;gina web, els continguts i els serveis
      van contreure, l'USUARI i el CONTROLADOR, expressly waiving qualsevol altra jurisdicci&oacute; que pot
      aplicar a ells, expressly entrega a llei espanyola, la jurisdicci&oacute; de Tribunals espanyols i, en particular,
      els Tribunals de Madrid, llevat que la llei aplicable concretament determina una altra
      jurisdicci&oacute; o legislaci&oacute; diferent.
    </p>
  </li>
</ol>
