<ol>
  <li>
    <p>
      <b>Представление и определение ОТВЕТСТВЕННОГО</b>
    </p>
    <p>
        Веб-сайт и логотип являются собственностью GESCASER (далее КОНТРОЛЕР) с добавленной стоимостью.
        Идентификационный номер налогоплательщика A25276635 и зарегистрированный офис Sant Pere 1 из Almacelles.
      </p>
    </li>
    <li>
      <p><b>Описание деятельности</b></p>
      <p>
        Через этот веб-сайт КОНТРОЛЕР желает информировать вас о своей деятельности и услугах.
        КОНТРОЛЕР может, в зависимости от развития и прогресса компании и сектора,
        расширить свой веб-сайт, включив в него новые услуги, действия или контент, чтобы улучшить
        услуги и качество предоставляемых услуг. И наоборот, КОНТРОЛЛЕР также резервирует
        право отменять, изменять, заменять или ограничивать контент, услуги или действия,
        явно и без предварительного уведомления пользователей.
      </p>
      <p>
        Этот веб-сайт предназначен как для клиентов, так и для всех других пользователей, которые его посещают (далее
        как ПОЛЬЗОВАТЕЛИ).
      </p>
      <p>
        Если у вас есть какие-либо вопросы об этих Общих условиях, пожалуйста, свяжитесь с нами по адресу
        gescaser@gescaser.com
      </p>
    </li>
    <li>
      <p>
        <b>Принятие Общих условий и использование веб-сайта</b>
      </p>
      <p>
        Использование данного веб-сайта регулируется следующими Общими условиями использования (далее
        ОБЩИЕ УСЛОВИЯ). Пожалуйста, внимательно прочитайте их, так как доступ к этому веб-сайту и использование его
        содержание подразумевает полное принятие их.
      </p>
      <p>
        Доступ к этому веб-сайту и информации, содержащейся на нем, является полностью бесплатным.
        без ущерба для стоимости телефонной связи и средств, используемых для установления
        связь.
      </p>
      <p>
        Однако на этом веб-сайте вы можете узнать о различных услугах, которые могут быть наняты
        связаться с КОНТРОЛЛЕРОМ; которые будут предоставлены в соответствии с конкретными условиями, согласованными
        между сторонами (далее - ОСОБЫЕ УСЛОВИЯ).
      </p>
      <p>
        Сообщаем вам, что для доступа к информации на веб-сайте нам не нужны ваши
        личные данные. Однако, если вы сделаете онлайн-запрос или договоритесь
        связанных с предлагаемыми услугами, или запрашивать какие-либо конкретные услуги, предлагаемые через
        веб-сайте необходимо будет собрать ваши личные данные, и в этом случае мы просим вас
        внимательно прочитайте нашу ПОЛИТИКУ КОНФИДЕНЦИАЛЬНОСТИ, которую также необходимо прочитать, понять и принять.
      </p>
      <p>Принимая этот договор, вы заявляете:</p>
      <ol type="a">
        <li>
          Что вы достигли совершеннолетия и/или обладаете дееспособностью в соответствии с
          общее договорное право штата, в котором вы проживаете.
        </li>
        <li>
          Что вы ранее прочитали, поняли и приняли ОБЩИЕ УСЛОВИЯ, КОНФИДЕНЦИАЛЬНОСТЬ
          ПОЛИТИКА и, где применимо, ОСОБЫЕ УСЛОВИЯ.
        </li>
      </ol>
    </li>
    <li>
      <p><b>Изменения в ОБЩИХ УСЛОВИЯХ</b></p>
      <p>
        Информация на этом веб-сайте и предлагаемые услуги постоянно меняются и обновляются.
        разработан, чтобы обеспечить наилучший возможный опыт и ценность.
      </p>
      <p>
        Когда что-то изменяется, что подразумевает изменение ОБЩИХ УСЛОВИЙ, КОНКРЕТНЫХ
        УСЛОВИЯ и/или ПОЛИТИКА КОНФИДЕНЦИАЛЬНОСТИ, указанные изменения должны быть внесены и опубликованы
        и обновлено. Поэтому вам рекомендуется периодически читать их все и/или выше
        все, до заключения любого контракта.
      </p>
      <p>
        Обновленные ОБЩИЕ УСЛОВИЯ, ОСОБЫЕ УСЛОВИЯ и/или ПОЛИТИКА КОНФИДЕНЦИАЛЬНОСТИ будут доступны на
        этот сайт во все времена.
      </p>
      <p>
        ПОЛЬЗОВАТЕЛЬ этого веб-сайта принимает такие изменения с момента, когда он продолжает использовать
        веб-сайт и/или предоставляемые услуги.
      </p>
    </li>
    <li>
      <p><b>Основные обязательства ПОЛЬЗОВАТЕЛЯ.</b></p>
      <p>
        Все ПОЛЬЗОВАТЕЛИ с момента, когда они начинают использовать веб-сайт и/или заключают контракт на любую из услуг.
        предлагаемые на нем, должны соблюдать следующие основные обязательства по отношению к третьим
        Стороны и КОНТРОЛЕР:
      </p>
      <ol type="a">
        <li>
          Использовать веб-сайт, не подвергаясь незаконным или противоправным действиям или действиям, противоречащим
          положения ОБЩИХ УСЛОВИЙ, ПОЛИТИКИ КОНФИДЕНЦИАЛЬНОСТИ и, где применимо,
          ОСОБЫЕ УСЛОВИЯ и их обновления.
        </li>
        <li>
          Не повреждать, не отключать, не перегружать и не нарушать работу веб-сайта, не препятствовать его нормальному использованию или
          удовольствие.
        </li>
        <li>
          Не предпринимать попыток нарушать уровни доступа, манипулировать данными, дублировать и экспортировать
          данные или информация, защищенные интеллектуальной собственностью или другими законными правами, доступ
          зоны ограниченного доступа компьютерных систем КОНТРОЛЛЕРА или третьих лиц, вводить
          программы, вирусы или любое другое устройство, которое вызывает или может вызвать изменения в компьютере
          системы КОНТРОЛЛЕРА или третьих лиц.
        </li>
        <li>
            Быть знакомым с техническими и юридическими требованиями, необходимыми для правильного доступа к
            этот веб-сайт и, если применимо, договор о продуктах и/или услугах; который может
            связаны с загрузкой определенных компьютерных программ или других логических элементов на их
            компьютерные устройства.
          </li>
          <li>
            Не использовать, не связывать, не раскрывать, не переуступать и не передавать третьим лицам содержание
            опубликованные на веб-сайте или в любой его части, или в информации КОНТРОЛЛЕРА,
            без явного предварительного письменного разрешения КОНТРОЛЛЕРА.
          </li>
          <li>
            Ни в коем случае не наносить ущерб имиджу КОНТРОЛЛЕРА, а также не выполнять какие-либо действия или делать
            любой комментарий, который может повредить имиджу и доброму имени GESCASER.
          </li>
          <li>
            Прочитать, понять и, если вы согласны, принять ОБЩИЕ УСЛОВИЯ, ПОЛИТИКУ КОНФИДЕНЦИАЛЬНОСТИ
            и, где это применимо, ОСОБЫЕ УСЛОВИЯ и их обновления и, в частности,
            ПОЛИТИКА ССЫЛОК И/ИЛИ COOKIES КОНТРОЛЛЕРА.
          </li>
        </ol>
      </li>
      <li>
        <p><b>Интеллектуальная собственность</b></p>
        <p>
          Этот веб-сайт, его содержимое и логотипы защищены испанскими и международными
          законы об интеллектуальной и промышленной собственности, и все такие права соответствуют исключительно
          КОНТРОЛЛЕР, использование которого зарезервировано.
        </p>
        <p>
          Воспроизведение, распространение, сообщение, преобразование и/или публичное раскрытие и, в
          вообще, любая другая форма несанкционированного использования любыми средствами всего или части содержимого
          этот веб-сайт, его дизайн, подбор и представление материалов, включенных в него, являются
          запрещенный; то же самое относится к брендам и торговым наименованиям, представленным на этом веб-сайте. Такой
          действия могут осуществляться только с явного предварительного письменного разрешения КОНТРОЛЛЕРА
          и при условии, что делается явная ссылка на право собственности КОНТРОЛЛЕРА на
          вышеупомянутые права интеллектуальной и промышленной собственности.
        </p>
        <p>
          Используя веб-сайт и/или заключая договор на оказание услуг, ПОЛЬЗОВАТЕЛЬ не приобретает никаких прав на
          их, просто имея право использовать их в соответствии с положениями настоящих ОБЩИХ
          УСЛОВИЯ и/или, где применимо, ОСОБЫЕ УСЛОВИЯ.
        </p>
        <p>
          В случае, если вы, ПОЛЬЗОВАТЕЛЬ, обнаружите какие-либо действия, которые могут нарушать какие-либо интеллектуальные или
          право промышленной собственности или любой другой вид права, пожалуйста, сообщите нам, отправив сообщение на
          следующий адрес электронной почты: gescaser@gescaser.com
        </p>
      </li>
      <li>
        <p><b>Политика конфиденциальности</b></p>
        <p>
          n соблюдение положений применимого национального и европейского законодательства о
          защиты личных данных, мы сообщаем вам, что личные данные, которые наши клиенты или
          потенциальные клиенты могут предоставить нам (оба именуемые «Клиент(ы)»), либо путем
          используя форму, представленную на нашем веб-сайте («Веб-сайт»), отправив электронное письмо на адрес
          предоставленных для этой цели, посредством электронных писем, адресованных нашим специалистам, или любым другим
          средства связи, будут включены в файл, в котором GESCASER (далее
          КОНТРОЛЕР) с идентификационным номером налога на добавленную стоимость A25276635 и зарегистрированным офисом в Сант-Пере 1 от
          Almacelles, является владельцем и контроллером данных.
        </p>
        <ol type="a">
          <li>
            Согласие и собранные данные
            <p>
              Предоставляя КОНТРОЛЛЕРУ запрошенные персональные данные, Клиент заявляет, что
              он / она прочитал и прямо принял настоящую Политику конфиденциальности и, следовательно, дает
              его/ее свободное, конкретное, недвусмысленное и информированное согласие КОНТРОЛЕРУ на обработку
              его персональные данные в соответствии с положениями настоящего документа.
            </p>
            <p>
              Если специально не указано иное, вся информация, которую мы запрашиваем у наших Клиентов
              в формах, доступных на Веб-сайте, требуется. Отказ в предоставлении необходимых данных
              сделает невозможным обработку конкретного рассматриваемого запроса. Пожалуйста, сообщите нам
              немедленно о любых изменениях ваших данных, чтобы информация, содержащаяся в наших файлах, была
              всегда в актуальном состоянии и без ошибок. В связи с этим Заказчик заявляет, что
              предоставленная информация и данные являются точными, актуальными и правдивыми.
            </p>
          </li>
          <li>
            Цель обработки и правовая основа
            <p>
              Целью обработки КОНТРОЛЛЕРОМ является обработка сделанного запроса, ответ
              ваши запросы и запросы на информацию и, при необходимости, поддерживать и управлять
              профессиональные, коммерческие и/или договорные отношения, установленные с любым из наших
              офисы и/или специалисты (далее «Услуги»).
            </p>
            <p>
              Правовая основа обработка ваших персональных данных КОНТРОЛЛЕРОМ основано на: i)
              согласие Клиента на обработку, описанную в настоящей Политике конфиденциальности, и ii)
              предоставление Услуг КОНТРОЛЛЕРА по запросу Заказчика.
            </p>
          </li>
          <li>
            Кому мы передаем ваши данные
            <p>
              КОНТРОЛЕР не будет передавать ваши личные данные третьим лицам, если это не предусмотрено законом.
              требуется, или если Клиент ранее уполномочил нас сделать это, или если это необходимо
              для оказания запрашиваемых Услуг.
            </p>
            <p>
              Чтобы иметь возможность предлагать соответствующую Услугу Клиенту и управлять
              отношения с вами, КОНТРОЛЕР заключает договор на предоставление услуг с определенными
              компании, которым при соблюдении требований законодательства может быть предоставлен доступ к
              ваши персональные данные исключительно для целей предоставления Сервиса.
            </p>
          </li>
          <li>
            Международная передача персональных данных
            <p>
              Общий регламент по защите данных (GDPR) предлагает несколько механизмов, позволяющих
              передача персональных данных за пределы Европейского Союза (ЕС). Эти механизмы были установлены
              вплоть до проверки адекватности уровня защиты или обеспечения реализации
              соответствующие меры безопасности при передаче персональных данных в страну, не входящую в ЕС.
            </p>
            <p>
              Этот уровень безопасности может быть достигнут с помощью стандартных договорных положений. это
              можно подтвердить адекватность уровня защиты путем проверки
              процедуры, подобные тем, которые предусмотрены в рамках Соглашения о правилах обмена конфиденциальной информацией между ЕС и США.
            </p>
            <p>
              В наших текущих контрактах на обработку данных мы обязуемся поддерживать механизм, который
              позволяет передавать персональные данные за пределы ЕС, как это предусмотрено в Положении о защите данных.
              Директива. Кроме того, мы возьмем на себя соответствующие обязательства с 25 мая 2018 г.
              (включительно), когда GDPR вступит в силу.
            </p>
            <p>
              Европейские органы по защите данных также подтвердили, что наши стандартные договорные
              положения соответствуют необходимым требованиям безопасности. Это свидетельствует о том, что GESCASER
              договорные обязательства соответствуют Директиве о защите данных и обеспечивают юридическую
              рамки для передачи персональных данных из ЕС в другие части мира.
            </p>
          </li>
          <li>
            Срок хранения персональных данных
            <p>
              Мы будем хранить ваши персональные данные в течение срока действия договорных отношений или,
              где это применимо, до тех пор, пока вы не заявите о своем желании удалить ваши личные данные из нашего
              файл. С этого момента КОНТРОЛЕР будет блокировать ваши личные данные в течение
              периоды, предусмотренные законодательством, и в любом случае в течение 10 лет в соответствии с
              положения о предотвращении отмывания денег и финансирования терроризма. Мы будем
              уничтожить ваши персональные данные по истечении установленного законом срока.
            </p>
          </li>
          <li>
            Осуществление прав
            <p>
              Вы можете воспользоваться своими правами на доступ, исправление, удаление, ограничение
              обработка, возражение и переносимость персональных данных путем отправки письменного сообщения
              с ксерокопией вашего удостоверения личности КОНТРОЛЕРУ по следующему адресу электронной почты:
              gescaser@gescaser.com
            </p>
          </li>
          <li>
            Жалоба в надзорный орган
            <p>
              Вам сообщают, что вы имеете право подать жалобу в Испанскую службу данных.
              Агентство по защите (www.aepd.es) в качестве надзорного органа, если вы считаете, что
              КОНТРОЛЛЕР не обрабатывал ваши персональные данные в соответствии с применимыми
              законодательство о защите персональных данных.
            </p>
          </li>
          <li>
            Безопасность
            <p>
              ДИСПЕТЧЕР обязуется принять технические и организационные меры безопасности
              установленный законом, чтобы гарантировать безопасность ваших личных данных и предотвратить их
              изменение, утеря, несанкционированная обработка или доступ с учетом состояния
              технология, характер хранимых данных и риски, которым они подвергаются, — все это в
              соответствии с положениями применимых правил.
            </p>
          </li>
          <li>
            Присвоение сторонних данных КОНТРОЛЛЕРУ
            <p>
              В случае предоставления КОНТРОЛЛЕРУ персональных данных третьих лиц, необходимых
              для предоставления Услуг, вы заявляете, что ранее получили их
              выразить согласие на передачу их КОНТРОЛЛЕРУ.
            </p>
          </li>
          <li>
            Изменения в нашей политике конфиденциальности
            <p>
            КОНТРОЛЕР может в любое время обновить настоящую Политику конфиденциальности. Обновленная версия этого
          Политика конфиденциальности всегда будет доступна на Платформе. Если эти изменения затрагивают цели
          не предусмотренные настоящей Политикой конфиденциальности, мы проинформируем вас достаточно заблаговременно
          эта последующая обработка по электронной почте (на последний указанный адрес), чтобы вы могли
          осуществлять права, которые вы считаете уместными и на которые вы имеете право в соответствии с
          применимые правила.
        </p>
      </li>
    </ol>
  </li>
  <li>
    <p><b>Ответственность</b></p>
    <p>
      ДИСПЕТЧЕР должен всегда стремиться предоставить услугу с максимально возможным
      качественный. Без ущерба для вышеизложенного, мы отказываемся от любой ответственности, возникающей в результате сбоев,
      прерывания или повреждения, вызванные системными сбоями, помехами или отключениями или
      неисправность службы.
    </p>
    <p>
      Аналогичным образом, КОНТРОЛЕР снимает с себя всякую ответственность и/или ответственность за ущерб любого рода.
      или характера в результате неправомерных действий третьих лиц любыми средствами или в результате использования
      использования веб-сайта пользователями ненадлежащим или ненадлежащим образом и ни при каких обстоятельствах
      КОНТРОЛЕР несет какую-либо ответственность за действия или халатность других лиц, которые
      может влиять или не влиять, прямо или косвенно, на серверы и другое компьютерное оборудование
      ПОЛЬЗОВАТЕЛЯ или третьих лиц.
    </p>
    <p>
      ПОЛЬЗОВАТЕЛЬ соглашается возместить ущерб и оградить КОНТРОЛЛЕРА, его дочерние компании, партнеров,
      менеджеров, сотрудников и / или сотрудников от любых претензий или требований, включая гонорары адвокатов,
      сделанные третьими лицами в результате ненадлежащего или несанкционированного использования ПОЛЬЗОВАТЕЛЕМ
      веб-сайт и/или предлагаемые услуги, или нарушение ПОЛЬЗОВАТЕЛЕМ ОБЩИХ УСЛОВИЙ, СПЕЦИАЛЬНЫХ
      УСЛОВИЯ, если применимо, и/или ПОЛИТИКА КОНФИДЕНЦИАЛЬНОСТИ, а также любые нарушения законодательства или любые
      другие права.
    </p>
    <p>
      КОНТРОЛЕР не может гарантировать отсутствие вирусов или других вредоносных программ, которые могут повлиять на
      компьютерное оборудование ПОЛЬЗОВАТЕЛЯ. Поэтому последний должен принять меры, чтобы избежать возможного
      риски такого рода ввиду современного состояния телекоммуникаций.
    </p>
    <p>
      КОНТРОЛЕР не несет ответственности за перебои в работе веб-сайта, вызванные форс-мажорными обстоятельствами или
      обстоятельства, не зависящие от него.
    </p>
    <p>
      Аналогичным образом, КОНТРОЛЕР может временно прервать доступ по соображениям безопасности или из-за
      реструктуризация компьютерных ресурсов, чтобы улучшить сервис использования веб-сайта.
    </p>
    <p>
      КОНТРОЛЕР предоставляет ПОЛЬЗОВАТЕЛЮ только информацию, опубликованную на сайте. Пользователь,
      поэтому несет единоличную ответственность за правильное использование такой информации и за обеспечение того, чтобы
      такое использование осуществляется в соответствии с ОБЩИМИ УСЛОВИЯМИ, СПЕЦИАЛЬНЫМИ УСЛОВИЯМИ, где
      применимо, и ПОЛИТИКЕ КОНФИДЕНЦИАЛЬНОСТИ.
    </p>
    <p>
      Если ПОЛЬЗОВАТЕЛЬ не удовлетворен использованием веб-сайта, его содержимым или услугами или каким-либо
      части настоящих ОБЩИХ УСЛОВИЙ, ПОЛИТИКИ КОНФИДЕНЦИАЛЬНОСТИ и/или ОСОБЫХ УСЛОВИЙ, его или ее
      единственным и исключительным средством правовой защиты будет прекращение доступа к веб-сайту и/или использования его услуг.
    </p>
  </li>
  <li>
    <p><b>Ссылки и файлы cookie</b></p>
    <p>
      Если вы подключаетесь к какой-либо ссылке или баннеру, отличному от этого веб-сайта, вы должны знать, что вы
      будет просматривать другой веб-сайт в Интернете, правила использования и политика конфиденциальности которого
      вне контроля КОНТРОЛЛЕРА. Поэтому вам рекомендуется ознакомиться с такими правилами
      использования и политики конфиденциальности, чтобы узнать применимые правила и их обработку
      сделать из ваших личных данных.
    </p>
    <p>
      Запрещается размещать содержимое или услуги на сторонних веб-сайтах, которые могут нанести ущерб
      изображения этого веб-сайта или КОНТРОЛЛЕРА, и последний оставляет за собой право запрещать, ограничивать
      или требовать отмены сделанных ссылок, когда сочтет это целесообразным, без каких-либо
      обстоятельства, требующие выплаты какой-либо компенсации по этой причине.
    </p>
    <p>
      Веб-сайт www («Веб-сайт») КОНТРОЛЛЕРА использует файлы cookie, которые представляют собой небольшие файлы.
      содержащий информацию о вашем просмотре этого веб-сайта и основной целью которого является
      упростить просмотр настоящего документа. Пожалуйста, продолжайте читать, чтобы узнать больше о файлах cookie, которые мы используем,
      их назначение и другую интересующую информацию.
    </p>
    <p>Что такое файлы cookie?</p>
    <p>
      Файл cookie — это файл, который размещается на веб-сайте, когда вы получаете к нему доступ и используете его. Файлы cookie позволяют нам
      хранить и извлекать информацию о ваших привычках просмотра веб-сайта. Информация, которую они
      получение связано, например, с количеством посещенных вами страниц, частотой и повторением
      количество посещений, продолжительность вашего визита и т. д.
    </p>
    <p>Что какие файлы cookie мы используем?</p>
    <ol type="a">
      <li>
        Согласно организации, которая ими управляет:
        <p>
          Собственные файлы cookie: те, которые отправляются на ваш компьютер с наших компьютеров или доменов с
          которые предоставляют запрашиваемую вами услугу.
        </p>
        <p>
          Куки-файлы третьих сторон: те, которые отправляются на ваш компьютер с компьютера или домена,
          управляется не нами, а другой ассоциированной организацией.
        </p>
      </li>
      <li>
        В зависимости от того, как долго они остаются активными:
        <p>
          Сеансовые файлы cookie: те, которые сохраняют необходимую информацию во время сеанса и
          связанные с ним изменения.
        </p>
        <p>
          Постоянные файлы cookie: они хранятся на жестком диске и считываются с нашего веб-сайта каждый раз, когда вы
          совершить ответный визит. Постоянный сайт имеет определенный срок действия. Файл cookie остановится
          работает после этой даты.
        </p>
      </li>
      <li>
        По назначению:
        <p>
          Технические файлы cookie: они необходимы для навигации и правильного функционирования сайта.
          интернет сайт.
        </p>
        <p>
          Файлы cookie для настройки: те, которые позволяют вам получить доступ к услуге с заранее определенными
          характеристики в зависимости от ряда критериальных особенностей, таких как язык, тип
          браузер, через который осуществляется доступ к сервису, регион, из которого вы получаете доступ к сервису,
          и т. д.
        </p>
        <p>
          Аналитические файлы cookie: те, которые позволяют нам количественно определить количество пользователей и, следовательно,
          измерение и статистический анализ использования пользователями предоставляемых услуг.
        </p>
        <p>
          Если вы не хотите разрешать использование файлов cookie на нашем Веб-сайте, следуйте этим
          инструкции:
        </p>
        <p>
          Эти процедуры могут быть пересмотрены или изменены разработчиками браузеров, поэтому
          мы не можем гарантировать, что последняя полностью соответствующая версия доступна в любое время.
        </p>
        <p>
          Чтобы предотвратить установку файлов cookie, следуйте инструкциям вашего браузера:
        </p>
        <p>
          <b>Internet Explorer:</b> нажмите «Инструменты» &gt; Свойства обозревателя», а затем выберите
          вкладка «Конфиденциальность». Выберите нужный параметр и нажмите кнопку дополнительных настроек.
          Активируйте поле «Переопределить автоматическую обработку файлов cookie». Выберите «Принять» или «Заблокировать».
          вариант. Мы рекомендуем активировать опцию «Всегда разрешать файлы cookie сеанса», чтобы включить
          оптимальный просмотр нашего веб-сайта.
        </p>
        <p>
          <b>Firefox:</b> нажмите «Инструменты» &gt; Параметры» в строке меню и выберите пункт «Конфиденциальность».
          вкладка В зависимости от используемой версии браузера установите или снимите флажок «Принимать файлы cookie».
          с сайтов» или «Сообщить сайтам, что я не хочу, чтобы меня отслеживали». Любые установленные файлы cookie могут быть
          удалить, нажав на опцию «удалить отдельные файлы cookie».
        </p>
        <p>
          <b>Chrome:</b> нажмите «Инструменты» &gt; Настройки», а затем выберите вкладку «Конфиденциальность &gt;
          Настройки контента". После выбора опции «Показать дополнительные параметры» выберите нужный
          настройка куки. Если вы не хотите, чтобы куки-файлы устанавливались, вы можете выбрать опцию
          «Блокировать сторонние файлы cookie и данные сайта».
        </p>
        <p>
          <b>Opera:</b> в разделе «Настройки &gt; Параметры» перейдите на вкладку «Дополнительно». в
          В меню «Cookies» выберите опции «Принимать» или «Никогда не принимать файлы cookie». Если хотите
          иметь более высокий уровень контроля над файлами cookie, установленными на вашем компьютере, мы
          рекомендуем вам выбрать опцию «Спрашивать, прежде чем принимать файлы cookie».
        </p>
        <p>
          <b>Safari:</b> нажмите «Инструменты &gt; Настройки» и выберите вкладку «Безопасность». Под
          в разделе «Принимать файлы cookie» выберите «Всегда» или «Никогда». Если у вас есть iPhone, вы должны перейти к
          «Настройки &gt; Safari», а затем выберите, хотите ли вы принимать файлы cookie или нет.
        </p>
      </li>
    </ol>
    <p>
      Если вы используете другой браузер, вы можете получить дополнительную информацию о настройке установки
      файлы cookie через раздел справки или помощь этого браузера.
    </p>
    <p>
      Если вам нужна помощь в настройке файлов cookie вашего браузера, отправьте нам
      напишите по адресу gescaser@gescaser.com, и мы свяжемся с вами как можно скорее, чтобы помочь вам.
    </p>
    <p>Обновления и изменения в Политике использования файлов cookie:</p>
    <p>
      КОНТРОЛЕР может изменить настоящую Политику использования файлов cookie в соответствии с законодательными и нормативными актами.
      требований или с целью адаптации этой политики к инструкциям, изданным
      Испанское агентство по защите данных, поэтому пользователю рекомендуется периодически его посещать.
    </p>
  </li>
  <li>
    <p><b>Недействительность и недействительность положений</b></p>
    <p>
      Если любое положение, включенное в настоящие ОБЩИЕ УСЛОВИЯ и/или СПЕЦИАЛЬНЫЕ УСЛОВИЯ, объявляется
      полностью или частично недействительным или недействительным, такая недействительность или недействительность
      затрагивают только ту часть положения, которая является недействительной или недействительной, и ОБЩИЕ
      УСЛОВИЯ и ОСОБЫЕ УСЛОВИЯ остаются в силе во всех других отношениях, и такие
      положение или его часть, на которые оно влияет, считаются недействительными.
    </p>
  </li>
  <li>
    <p><b>Применимое законодательство и юрисдикция</b></p>
    <p>
      Для любых проблем или несоответствий, которые могут возникнуть в отношении веб-сайта, содержимого и услуг.
      контракт, ПОЛЬЗОВАТЕЛЬ и КОНТРОЛЕР, прямо отказываясь от любой другой юрисдикции, которая может
      применять к ним, прямо подчиняться испанскому законодательству, юрисдикции испанских судов и, в
      в частности, суды Мадрида, если применимое право специально не определяет иное
      юрисдикции или другого законодательства.
    </p>
  </li>
</ol>