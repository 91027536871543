<ol>
  <li>
    <p>
      <b>Presentaci&oacute;n e identificaci&oacute;n del CONTROLADOR</b>
    </p>
    <p>
      El sitio web y el logotipo es la propiedad de GESCASER (de ahora en adelante el CONTROLADOR)
      con Valorar Impuesto A&ntilde;adido ID n&uacute;mero Un25276635 y oficina registrada Sant Pere
      1 de Almacelles.
    </p>
  </li>
  <li>
    <p><b>Descripci&oacute;n de actividad</b></p>
    <p>
      A trav&eacute;s de este sitio web, los deseos de CONTROLADOR para informarte aproximadamente
      sus actividades y servicios. El CONTROLADOR puede, seg&uacute;n el desarrollo y progreso de la
      empresa y el sector, extender su sitio web para incluir servicios nuevos, actividades o
      contenidos, para mejorar los servicios y la calidad del servicio proporcion&oacute;. En
      cambio, el CONTROLADOR tambi&eacute;n reserva el correcto de cancelar, modifica, sustituto o
      restringir los contenidos, servicios o actividades, expressly y sin previo aviso a los
      usuarios.
    </p>
    <p>
      Este sitio web est&aacute; apuntado en ambos clientes y todos otros usuarios que lo visitan
      (de ahora en adelante referidos a tan USUARIOS).
    </p>
    <p>
      Si tienes cualesquier cuestiones sobre estas Condiciones Generales, complacer contactarnos en
      gescaser@gescaser.com
    </p>
  </li>
  <li>
    <p>
      <b>Aceptaci&oacute;n de uso y Condiciones Generales del sitio web</b>
    </p>
    <p>
      El uso de este sitio web est&aacute; regulado por las Condiciones Generales siguientes de Uso
      (de ahora en adelante las CONDICIONES GENERALES). Complacer le&iacute;do les cuidadosamente,
      cuando acceso a este sitio web y el uso de sus contenidos implica aceptaci&oacute;n llena de
      ellos.
    </p>
    <p>
      Acceso a este sitio web y la informaci&oacute;n contuvieron herein es completamente libre de
      cargo, sin perjuicio de el coste de la conexi&oacute;n telef&oacute;nica y el significa
      utilizado para establecer la conexi&oacute;n..
    </p>
    <p>
      Aun as&iacute;, en este sitio web, puedes descubrir sobre los servicios diferentes que puede
      ser contratado por contactar el CONTROLADOR; cu&aacute;l ser&aacute; proporcionado
      seg&uacute;n las Condiciones Espec&iacute;ficas estado de acuerdo entre los partidos (de ahora
      en adelante las CONDICIONES ESPEC&Iacute;FICAS).
    </p>
    <p>
      Te inform&aacute;is que, para acceder la informaci&oacute;n en el sitio web, no necesitamos
      vuestro dato personal. Aun as&iacute;, en el acontecimiento que haces una consulta on-line o
      hacer cualesquier arreglos relacionaron a los servicios ofrecidos o pedir cualquiera de los
      servicios espec&iacute;ficos ofreci&oacute; a trav&eacute;s del sitio web, sea necesario de
      recoger vuestro dato personal y, en este caso, te pregunt&aacute;is a cuidadosamente
      le&iacute;dos nuestra POL&Iacute;TICA DE PRIVACIDAD, los cuales tambi&eacute;n tienen que ser
      le&iacute;dos, entendidos y acept&oacute;.
    </p>
    <p>Por aceptar este contrato, declaras:</p>
    <ol type="a">
      <li>
        Aquello eres de edad legal y/o tener la capacidad de contraer, de acuerdo con las leyes
        contratandas generales del Estado donde resides.
      </li>
      <li>
        Aquello te anteriormente ha le&iacute;do, entendido y acept&oacute; las CONDICIONES
        GENERALES, la POL&Iacute;TICA DE PRIVACIDAD y, donde aplicable, las CONDICIONES
        ESPEC&Iacute;FICAS.
      </li>
    </ol>
  </li>
  <li>
    <p><b>Cambios a las CONDICIONES GENERALES</b></p>
    <p>
      La informaci&oacute;n en este sitio web y los servicios ofrecieron continuamente est&aacute;
      cambiando y siendo desarrollado para proporcionar la experiencia posible mejor y valor.
    </p>
    <p>
      Cu&aacute;ndo algo est&aacute; alterado aquello implica un cambio a las CONDICIONES GENERALES,
      las CONDICIONES ESPEC&Iacute;FICAS y/o la POL&Iacute;TICA DE PRIVACIDAD, la alteraci&oacute;n
      dicha ser&aacute; hecha, y ser&aacute; publicado y actualiz&oacute;. Por tanto, est&aacute;s
      recomendado para leer todo de ellos peri&oacute;dicamente y/o, sobre todo, antes de concluir
      cualquier contrato.
    </p>
    <p>
      Las CONDICIONES GENERALES actualizadas, CONDICIONES ESPEC&Iacute;FICAS y/o la pol&iacute;tica
      de privacidad ser&aacute; disponible en este sitio web en todo momento.
    </p>
    <p>
      El USUARIO de este sitio web aceptar&aacute; tales alteraciones del momento
      &eacute;l/contin&uacute;a utilizar el sitio web y/o los servicios proporcionaron.
    </p>
  </li>
  <li>
    <p><b>Obligaciones de USUARIO esencial.</b></p>
    <p>
      Todos los USUARIOS, del momento empiezan para utilizar el sitio web y/o contraer cualquiera de
      los servicios ofreci&oacute; encima lo, est&aacute; requerido a comply con las obligaciones
      esenciales siguientes vis-&agrave;-vis terceros partidos y el CONTROLADOR:
    </p>
    <ol type="a">
      <li>
        Para utilizar el sitio web sin incurrir en ilegal o unlawful acciones, o acciones
        contrariamente a las provisiones de las CONDICIONES GENERALES, la POL&Iacute;TICA DE
        PRIVACIDAD y, donde aplicable, las CONDICIONES ESPEC&Iacute;FICAS y sus actualizaciones.
      </li>
      <li>
        No para averiar, imposibilita, sobrecarga o impair el sitio web o impedir su uso normal o
        diversi&oacute;n..
      </li>
      <li>
        No para hacer cualquier intento a infringe niveles de acceso, manipula dato, duplicado y
        dato de exportaci&oacute;n o la informaci&oacute;n protegida por propiedad intelectual u
        otros derechos legales, el acceso restringi&oacute; &aacute;reas de los sistemas de
        ordenador del CONTROLADOR o terceros partidos, introducir programas, virus o cualquiera otro
        dispositivo que causas o puede causar cambios al sistema de ordenador del CONTROLADOR o
        terceros partidos.
      </li>
      <li>
        Para ser familiar con los requisitos t&eacute;cnicos y legales necesarios para acceso
        correcto a este sitio web y, si aplicable, el contrayendo de los productos y/o servicios;
        cu&aacute;les pueden implicar el descargando de programas de ordenador seguro u otros
        elementos l&oacute;gicos a sus dispositivos de ordenador.
      </li>
      <li>
        No para utilizar, enlace, revela, asigna o transferencia a terceros partidos en cualquier
        manera, los contenidos publicaron en el sitio web o cualquier parte de ellos, o en la
        informaci&oacute;n del CONTROLADOR, sin el expresar autorizaci&oacute;n escrita previa del
        CONTROLADOR.
      </li>
      <li>
        No para averiar la imagen del CONTROLADOR en cualquier manera, ni para actuar cualquier
        actividad o hacer cualquier comentario que podr&iacute;a averiar la imagen y nombre bueno de
        GESCASER.
      </li>
      <li>
        Para leer, entiende y, si est&aacute;s de acuerdo, aceptar las CONDICIONES GENERALES, la
        POL&Iacute;TICA DE PRIVACIDAD y, donde aplicable, las CONDICIONES ESPEC&Iacute;FICAS y sus
        actualizaciones y, m&aacute;s espec&iacute;ficamente, los ENLACES Y/O POL&Iacute;TICA de
        GALLETAS del CONTROLADOR.
      </li>
    </ol>
  </li>
  <li>
    <p><b>Propiedad intelectual</b></p>
    <p>
      Este sitio web, sus contenidos y sus logotipos est&aacute;n protegidos por intelectual
      espa&ntilde;ol e internacional y leyes de propiedad industrial, y todos tales derechos
      corresponden exclusivamente al CONTROLADOR, el uso del mismo siendo reserv&oacute;.
    </p>
    <p>
      La reproducci&oacute;n, distribuci&oacute;n, comunicaci&oacute;n, transformaci&oacute;n y/o
      revelaci&oacute;n p&uacute;blica y, en general, cualquiera otra forma de unauthorized uso, por
      cualquier medio, de todo o parte de los contenidos de este sitio web, su dise&ntilde;o,
      selecci&oacute;n y presentaci&oacute;n de los materiales incluidos herein est&aacute;
      prohibido; el mismo siendo cierto de las marcas y el comercio nombra incluidos en este sitio
      web. Tales acciones s&oacute;lo pueden ser llevadas a cabo con el expresar autorizaci&oacute;n
      escrita previa del CONTROLADOR y proporcionado que la referencia expl&iacute;cita est&aacute;
      hecha a la propiedad del CONTROLADOR del intelectual antedicho y derechos de propiedad
      industrial.
    </p>
    <p>
      Por utilizar el sitio web y/o contrayendo los servicios, el USUARIO no adquiere cualquier
      correcto encima les, sencillamente siendo titulado para utilizarles seg&uacute;n las
      provisiones de estas CONDICIONES GENERALES y/o, donde aplicables, las CONDICIONES
      ESPEC&Iacute;FICAS.
    </p>
    <p>
      En el acontecimiento que te, el USUARIO, detectar cualquier actividad que poder infringe
      cualquier propiedad intelectual o industrial derecho o cualquiera otro tipo de correcto,
      complacer notificarnos por enviar un mensaje a la direcci&oacute;n de correo
      electr&oacute;nico siguiente: gescaser@gescaser.com
    </p>
  </li>
  <li>
    <p><b>Pol&iacute;tica de privacidad</b></p>
    <p>
      n Conformidad con las provisiones de legislaci&oacute;n nacional y europea aplicable en la
      protecci&oacute;n de dato personal, te inform&aacute;is que el dato personal que nuestros
      clientes o los clientes potenciales nos pueden proporcionar con (ambos referidos a como el
      Cliente(s)”), cualquiera por utilizar la forma proporcionada en nuestro sitio web (el Sitio
      web”), por enviar un email a la direcci&oacute;n proporcionada para este prop&oacute;sito,
      mediante los emails dirigieron a nuestros profesionales o por cualquier otro medio de
      comunicaci&oacute;n, ser&aacute; incluido en un archivo del cual GESCASER (de ahora en
      adelante el CONTROLADOR) con Valorar Impuesto A&ntilde;adido ID n&uacute;mero Un25276635 y
      oficina registrada en Sant Pere 1 de Almacelles, es el due&ntilde;o y controlador de dato.
    </p>
    <ol type="a">
      <li>
        Consentimiento y dato recogido
        <p>
          Por proporcionar el CONTROLADOR con el dato personal pidi&oacute;, el Cliente declara que
          &eacute;l/ha le&iacute;do y expressly acept&oacute; la Pol&iacute;tica de privacidad
          presente y, por tanto, da su/su libre, espec&iacute;fico, consentimiento inequ&iacute;voco
          e informado al CONTROLADOR para procesar su/su dato personal de acuerdo con las
          provisiones de este documento.
        </p>
        <p>
          A no ser que espec&iacute;ficamente declarado al contrario, toda informaci&oacute;n
          pedimos de nuestros Clientes en las formas disponibles en el Sitio web est&aacute;
          requerido. Negativa para proporcionar el dato requerido lo har&aacute; imposible para
          tratar la petici&oacute;n espec&iacute;fica en cuesti&oacute;n. Complacer informarnos
          inmediatamente de cualesquier cambios a vuestro dato de modo que la informaci&oacute;n
          contenida en nuestros archivos es siempre actualizados y libres de errores. A este
          particular, el Cliente declara que la informaci&oacute;n y el dato proporcionado es
          cuidadoso, actualizado y veraz.
        </p>
      </li>
      <li>
        Prop&oacute;sito de procesar y base legal
        <p>
          El prop&oacute;sito del procesamiento por el CONTROLADOR es para tratar la petici&oacute;n
          hizo, respuesta vuestras consultas y peticiones para informaci&oacute;n y, cuando proceda,
          mantiene y dirigir el profesional, anuncio y/o la relaci&oacute;n contractual establecida
          con cualquier de nuestras oficinas y/o profesionales (de ahora en adelante el
          Servicio(s)”).
        </p>
        <p>
          La base legal para el procesamiento de vuestro dato personal por el CONTROLADOR
          est&aacute; basado encima: i) el consentimiento del Cliente al procesamiento descrito en
          esta Pol&iacute;tica de privacidad e ii) la provisi&oacute;n de los Servicios del
          CONTROLADOR pedido por el Cliente.
        </p>
      </li>
      <li>
        Qui&eacute;n comunicamos vuestro dato a
        <p>
          El CONTROLADOR no transferir&aacute; vuestro dato personal a terceros partidos, a no ser
          que es legalmente requerido o si el Cliente ha anteriormente nos autoric&eacute; para
          hacer tan o si es necesario para la provisi&oacute;n del pidi&oacute; Servicios.
        </p>
        <p>
          Para ser capaz de ofrecer el Servicio apropiado al Cliente y para dirigir la
          relaci&oacute;n contigo, el CONTROLADOR contrae la provisi&oacute;n de servicios de
          empresas seguras qu&eacute;, tema a conformidad con los requisitos legales, puede ser
          acceso dado a vuestro dato personal s&oacute;lo para los prop&oacute;sitos que
          proporcionan el Servicio.
        </p>
      </li>
      <li>
        Transferencias internacionales de dato personal
        <p>
          El Control de Protecci&oacute;n de datos General (GDPR) ofrece varios mecanismos para
          habilitar la transferencia de dato personal fuera de la Uni&oacute;n europea (UE). Estos
          mecanismos han sido instalados para comprobar que el nivel de protecci&oacute;n es
          adecuado o para asegurar la implementaci&oacute;n de medidas de seguridad apropiada
          cu&aacute;ndo transfiriendo dato personal a un pa&iacute;s extracomunitario.
        </p>
        <p>
          Este nivel de seguridad puede ser conseguido a trav&eacute;s de cl&aacute;usulas
          contractuales est&aacute;ndares. Es posible de confirmar que el nivel de protecci&oacute;n
          es adecuado a trav&eacute;s de verification procedimientos como aquellos en la UE-marco de
          Escudo de Intimidad de EE.UU..
        </p>
        <p>
          En nuestros contratos actuales en los datos que procesan, emprendemos para mantener un
          mecanismo que habilita la transferencia de dato personal fuera de la UE, cuando estipulado
          en la Directiva de Protecci&oacute;n de datos. Adem&aacute;s, haremos el compromiso
          apropiado cuando de 25 mayo 2018 (inclusive), cu&aacute;ndo el GDPR introduce a fuerza.
        </p>
        <p>
          Autoridades de protecci&oacute;n de datos europea haber tambi&eacute;n confirm&oacute; que
          nuestras cl&aacute;usulas contractuales est&aacute;ndares conocen los requisitos de
          seguridad necesarios. Esto demuestra que GESCASER compromisos contractuales comply con la
          Directiva de Protecci&oacute;n de datos y proporcionar un marco legal para la
          transferencia de dato personal de la UE a otras partes del mundo.
        </p>
      </li>
      <li>
        Periodo de almacenamiento de dato personal
        <p>
          Retendremos vuestro dato personal para la duraci&oacute;n de la relaci&oacute;n
          contractual o, donde aplicable, hasta que declaras vuestro deseo de tener vuestro dato
          personal eliminado de nuestro archivo. De aquel momento encima, el CONTROLADOR
          mantendr&aacute; vuestro dato personal bloqueado durante el legalmente requiri&oacute;
          periodos, y en cualquier acontecimiento, para un periodo de 10 a&ntilde;os de acuerdo con
          los controles en la prevenci&oacute;n de dinero laundering y financiando de terrorismo.
          Destruiremos vuestro dato personal despu&eacute;s del expiry del periodo legalmente
          requerido.
        </p>
      </li>
      <li>
        Ejercicio de derechos
        <p>
          Puedes ejercitar vuestros derechos de acceso, rectificaci&oacute;n, deletion,
          limitaci&oacute;n del procesamiento, oposici&oacute;n y portability de dato personal por
          enviar una comunicaci&oacute;n escrita con una fotocopia de vuestra ID tarjeta al
          CONTROLADOR en la direcci&oacute;n de email siguiente: gescaser@gescaser.com
        </p>
      </li>
      <li>
        Queja a una autoridad supervisora
        <p>
          Est&aacute;s informado que tienes el correcto de archivar una queja con la Agencia de
          Protecci&oacute;n de datos espa&ntilde;ola (www.aepd.es) como la autoridad supervisora si
          consideras que el CONTROLADOR no ha procesado vuestro dato personal de acuerdo con la
          legislaci&oacute;n aplicable en la protecci&oacute;n de dato personal.
        </p>
      </li>
      <li>
        Seguridad
        <p>
          El CONTROLADOR emprende para adoptar la seguridad t&eacute;cnica y organizativa las
          medidas establecieron por ley para garantizar la seguridad de vuestro dato personal y para
          impedir su alteraci&oacute;n, p&eacute;rdida, unauthorized procesando o acceso, teniendo
          en cuenta el estado de tecnolog&iacute;a, la naturaleza del dato almacenado y los riesgos
          al cual est&aacute;n expuestos, todo de acuerdo con las provisiones de los controles
          aplicables.
        </p>
      </li>
      <li>
        Asignaci&oacute;n de tercer dato de partido al CONTROLADOR
        <p>
          En el acontecimiento de proporcionar el CONTROLADOR con el dato personal de terceros
          partidos requiri&oacute; para la provisi&oacute;n de los Servicios, declaras que te ha
          anteriormente obtuvo su expresar consentimiento para transferirles al CONTROLADOR.
        </p>
      </li>
      <li>
        Cambios a nuestra pol&iacute;tica de privacidad
        <p>
          El CONTROLADOR puede actualizar esta Pol&iacute;tica de privacidad en cualquier tiempo.
          Una versi&oacute;n actualizada de esta Pol&iacute;tica de privacidad siempre ser&aacute;
          disponible en la Plataforma. Si estos cambios afectan los prop&oacute;sitos no
          proporcionados para en esta Pol&iacute;tica de privacidad, te informaremos suficientemente
          por adelantado de este procesamiento subsiguiente por email (a la &uacute;ltima
          direcci&oacute;n proporcionada), de modo que puedes ejercitar los derechos t&uacute; deem
          apropiado y al cual est&aacute;s titulado bajo los controles aplicables.
        </p>
      </li>
    </ol>
  </li>
  <li>
    <p><b>Responsabilidad</b></p>
    <p>
      El CONTROLADOR endeavour en todo momento para proporcionar el servicio con la calidad posible
      m&aacute;s alta. Sin perjuicio de el foregoing, declinamos toda responsabilidad que surge de
      fracasos, interrupciones o averiar causado por fracasos de sistema, interferencia o
      desconexiones o por el malfunction del servicio.
    </p>
    <p>
      De modo parecido, el CONTROLADOR declina toda responsabilidad y/o responsabilidad para
      da&ntilde;os de cualquier amables o la naturaleza que resulta de la acci&oacute;n
      ileg&iacute;tima de terceros partidos por cualquier medio o del uso del sitio web en una
      manera impropia o inapropiada por los usuarios, y en ninguna circunstancia el CONTROLADOR
      aguanta cualquier responsabilidad whatsoever para la acci&oacute;n o negligencia de otros
      aquello puede o no puede afectar, directamente o indirectamente, los servidores y otro
      equipamiento de ordenador del USUARIO o terceros partidos.
    </p>
    <p>
      El USUARIO apalabra indemnizar y control harmless el CONTROLADOR, sus filiales, asocia,
      directores, colaboradores y/o empleados de cualquier reclamaci&oacute;n o demanda, incluyendo
      los costes de los abogados, hechos por terceros partidos, a ra&iacute;z del USUARIO
      inapropiado o unauthorized uso del sitio web y/o los servicios ofrecieron, o la ruptura del
      USUARIO de las CONDICIONES GENERALES, CONDICIONES ESPECIALES, si aplicables, y/o
      POL&Iacute;TICA DE PRIVACIDAD, as&iacute; como cualquier ruptura de legislaci&oacute;n o
      cualquiera otros derechos.
    </p>
    <p>
      El CONTROLADOR no puede garantizar la ausencia de virus u otro malware que puede afectar el
      equipamiento de ordenador del USUARIO. El &uacute;ltimo por tanto tendr&iacute;a que tomar
      medidas para evitar riesgos posibles de esta clase en vista del estado actual de
      telecomunicaciones.
    </p>
    <p>
      El CONTROLADOR no es responsable para las interrupciones al sitio web causaron a la fuerza
      majeure o circunstancias allende su control.
    </p>
    <p>
      As&iacute; mismo, el CONTROLADOR puede interrumpir acceso temporalmente, para razones de
      seguridad o debido a la reestructuraci&oacute;n de recursos de ordenador, para mejorar el
      servicio de uso del sitio web.
    </p>
    <p>
      El CONTROLADOR s&oacute;lo proporciona el USUARIO con la informaci&oacute;n publicada en el
      sitio web. El USUARIO, por tanto, es s&oacute;lo responsable para el uso correcto de tal
      informaci&oacute;n y para asegurar que tal uso est&aacute; hecho de acuerdo con las
      CONDICIONES GENERALES, las CONDICIONES ESPEC&Iacute;FICAS donde aplicables, y la
      POL&Iacute;TICA DE PRIVACIDAD.
    </p>
    <p>
      Si el USUARIO no es satisfecho con el uso del sitio web, sus contenidos o servicios o con
      cualquier parte de estas CONDICIONES GENERALES, la POL&Iacute;TICA DE PRIVACIDAD y/o
      CONDICIONES ESPEC&Iacute;FICAS, su o su remedio &uacute;nico y exclusivo ser&aacute; para
      parar accediendo el sitio web y/o utilizando sus servicios.
    </p>
  </li>
  <li>
    <p><b>Enlaces y Galletas.&nbsp;</b></p>
    <p>
      Si conectas a cualquier enlace o pancarta otro que este sitio web, tendr&iacute;as que ser
      consciente que ser&aacute;s explorar otro sitio web en el Internet, cuyas reglas de uso y
      pol&iacute;tica de privacidad son allende el control del CONTROLADOR. Por tanto, es
      aconsejable para ti para comprobar tales reglas de uso y pol&iacute;tica de privacidad, para
      descubrir las reglas aplicables y el procesamiento hacen de vuestro dato personal.
    </p>
    <p>
      Est&aacute; prohibido para incluir contenidos o servicios en terceros sitios web de partido
      que podr&iacute;a hacer da&ntilde;o la imagen de este sitio web o el CONTROLADOR, y las
      reservas &uacute;ltimas el correctos de prohibir, l&iacute;mite o reclamar la anulaci&oacute;n
      de los enlaces hizo, cu&aacute;ndo &eacute;l deems apropiado, sin bajo cualesquier
      circunstancias que son requeridos para pagar cualquier clase de compensaci&oacute;n por esta
      raz&oacute;n.
    </p>
    <p>
      El sitio web www (el Sitio web”) de las galletas de usos del CONTROLADOR, los cuales son
      archivos peque&ntilde;os conteniendo informaci&oacute;n sobre vuestro explorando de este Sitio
      web y cuyo prop&oacute;sito principal es para simplificar vuestro explorando hereof. Complacer
      continuar leyendo para aprender m&aacute;s sobre las galletas utilizamos, su prop&oacute;sito
      y otra informaci&oacute;n de inter&eacute;s.
    </p>
    <p>Qu&eacute; es galletas?</p>
    <p>
      Una galleta es un archivo que est&aacute; colocado en Sitio web cu&aacute;ndo accedes y
      utilizarlo. Las galletas nos dejan para almacenar y recuperar informaci&oacute;n sobre
      vuestros h&aacute;bitos de explorar en el Sitio web. La informaci&oacute;n obtienen
      est&aacute; relacionado, por ejemplo, al n&uacute;mero de p&aacute;ginas visitas, la
      frecuencia y recurrence de visitas, la longitud de vuestra visita, etc.
    </p>
    <p>Qu&eacute; clase de galletas utilizamos?</p>
    <ol type="a">
      <li>
        Seg&uacute;n la entidad que les dirige:
        <p>
          Galletas propias: los que est&aacute;n enviados a vuestro ordenador de nuestros
          ordenadores, o &aacute;mbitos de qu&eacute; proporcionar el servicio pides.
        </p>
        <p>
          Terceras galletas de partido: los que est&aacute;n enviados a vuestro ordenador de un
          ordenador o &aacute;mbito que no es dirigido por nosotros, pero por otra
          organizaci&oacute;n asociada.
        </p>
      </li>
      <li>
        Seg&uacute;n cu&aacute;nto tiempo quedan activos:
        <p>
          Galletas de sesi&oacute;n: los que almacenan la informaci&oacute;n necesaria durante la
          sesi&oacute;n y los cambios relacionaron a &eacute;l.
        </p>
        <p>
          Galletas persistentes: est&aacute;n almacenados en el disco duro y lee nuestro sitio web
          cada vez haces una visita de regreso. Un sitio permanente tiene un espec&iacute;fico
          expiration fecha. La galleta parar&aacute; trabajar despu&eacute;s de aquella fecha.
        </p>
      </li>
      <li>
        Seg&uacute;n el prop&oacute;sito:
        <p>
          Galletas t&eacute;cnicas: son necesarios para navegaci&oacute;n y el funcionamiento
          apropiado del sitio web.
        </p>
        <p>
          Customization Galletas: los que te dejan para acceder el servicio con predefined
          caracter&iacute;sticas seg&uacute;n un n&uacute;mero de caracter&iacute;sticas de
          criterios, como lengua, el tipo del navegador a trav&eacute;s de qu&eacute; el servicio
          est&aacute; accedido, el locale de qu&eacute; accedes servicio, etc.
        </p>
        <p>
          Galletas de an&aacute;lisis: los que nos dejan para cuantificar el n&uacute;mero de
          usuarios y por ello la medida y an&aacute;lisis estad&iacute;stico del uso hecho por los
          usuarios de los servicios proporcionaron.
        </p>
        <p>
          Si no quieres dejar el uso de galletas en nuestro Sitio web, complacer seguir estas
          instrucciones:
        </p>
        <p>
          Estos procedimientos son sujetos a revisi&oacute;n o modificaci&oacute;n por los
          desarrolladores de navegadores, as&iacute; que no podemos garantizar que el &uacute;ltimo
          plenamente conformando la versi&oacute;n es disponible en todo momento.
        </p>
        <p>
          Para impedir la instalaci&oacute;n de galletas, complacer seguir las instrucciones de
          vuestro navegador:
        </p>
        <p>
          <b>Explorer de internet:</b> clic en “carta &gt; de Opciones de Internet de Herramientas y
          entonces seleccionar el “tabulador” de Intimidad. Seleccionar el encuadre deseado y clic
          en el adelant&oacute; bot&oacute;n de encuadres. Activar el “Override la galleta
          autom&aacute;tica que maneja” caja. Seleccionar el “Aceptar” o “opci&oacute;n de Bloque.
          Recomendamos activar el “Siempre dejar opci&oacute;n de galletas de la sesi&oacute;n para
          habilitar el optimal explorando en nuestro sitio web.
        </p>
        <p>
          <b>Firefox:</b> Clic en “Opciones &gt; de Herramientas” en la barra de carta y seleccionar
          el “tabulador” de Intimidad. Seg&uacute;n la versi&oacute;n de navegador est&aacute;s
          utilizando, selecciona o deselect “Aceptar galletas de sitios” o “Decir sitios que no
          quiero ser seguido”. Cualesquier galletas instaladas puede ser sacado por clicking en el
          “sacar opci&oacute;n de galletas individuales.
        </p>
        <p>
          <b>Chrome:</b> Clic en “carta &gt; de Encuadres de las Herramientas, y entonces
          seleccionar los encuadres “de Contenido &gt; de Intimidad de tabulador”. Despu&eacute;s de
          seleccionar el Espect&aacute;culo “de opci&oacute;n adelant&oacute; opciones”, seleccionar
          el encuadre de galleta deseado. Si no quieres galletas para ser instaladas, puedes
          seleccionar el Bloque “de opci&oacute;n tercer-galletas de partido y dato de sitio”.
        </p>
        <p>
          <b>&Oacute;pera:</b> En la “carta &gt; de Opciones” de los Encuadres, va al “tabulador”
          Adelantado. En la “carta” de Galletas selecciona las opciones “Aceptan” o “no aceptar
          galletas nunca.” Si quieres tener un nivel m&aacute;s grande de control sobre las galletas
          que est&aacute; instalado en vuestro ordenador, te recomend&aacute;is para seleccionar la
          opci&oacute;n “Pregunta antes aceptando galletas.”
        </p>
        <p>
          <b>Safari:</b> clic en “Preferencias &gt; de Herramientas” y seleccionar el “tabulador” de
          Seguridad. Bajo el encabezando “Acepta las galletas” escogen “Siempre” o “Nunca”. Si
          tienes un iPhone, tienes que ir a “Safari &gt; de Encuadres” y entonces seleccionar si
          haces o no quiere aceptar galletas.
        </p>
      </li>
    </ol>
    <p>
      Si utilizas otro navegador, puedes conseguir m&aacute;s informaci&oacute;n encima configurando
      la instalaci&oacute;n de galletas a trav&eacute;s de la secci&oacute;n de ayuda o asistencia
      de aquel navegador.
    </p>
    <p>
      Si necesitas ayuda para llevar a cabo la configuraci&oacute;n de vuestras galletas de
      navegador, complacer enviarnos un email a gescaser@gescaser.com y te contactaremos tan pronto
      como sea posible para ayudarte.
    </p>
    <p>Actualizaciones y cambios a la Pol&iacute;tica de Galletas:</p>
    <p>
      El CONTROLADOR puede cambiar esta Pol&iacute;tica de Galletas de acuerdo con requisitos
      legales y reguladores, o con el objetivo de adaptar esta pol&iacute;tica a las instrucciones
      emiti&oacute; por la Agencia de Protecci&oacute;n de datos espa&ntilde;ola, para qu&eacute;
      raz&oacute;n el usuario est&aacute; aconsejado para visitarlo peri&oacute;dicamente.
    </p>
  </li>
  <li>
    <p><b>Nulidad e ineffectiveness de cl&aacute;usulas</b></p>
    <p>
      Si cualquier cl&aacute;usula incluida en estas CONDICIONES GENERALES y/o las CONDICIONES
      ESPEC&Iacute;FICAS est&aacute; declarada totalmente o parcialmente null y void o ineficaces,
      tal nulidad o ineffectiveness afectar&aacute; s&oacute;lo que parte de provisi&oacute;n del
      mismo aquello es null y void o ineficaz, y las CONDICIONES GENERALES y las condiciones
      ESPEC&Iacute;FICAS se mantendr&aacute;n en vigor en todos otros respetos, y tal
      provisi&oacute;n, o parte del mismo aquello est&aacute; afectado, ser&aacute; deemed null y
      void.
    </p>
  </li>
  <li>
    <p><b>Jurisdicci&oacute;n y ley aplicables</b></p>
    <p>
      Para cualquier asunto o discrepancia que puede surgir con respecto al sitio web, los
      contenidos y los servicios contrajeron, el USUARIO y el CONTROLADOR, expressly waiving
      cualquiera otra jurisdicci&oacute;n que puede aplicar a ellos, expressly entrega a ley
      espa&ntilde;ola, la jurisdicci&oacute;n de Tribunales espa&ntilde;oles y, en particular, los
      Tribunales de Madrid, a no ser que la ley aplicable espec&iacute;ficamente determina otra
      jurisdicci&oacute;n o legislaci&oacute;n diferente.
    </p>
  </li>
</ol>
