import * as moment from 'moment';

import { Expose, Transform, Type } from 'class-transformer';
import { InstallationEventCountersEntity } from './installation-event-counters.entity';
import { SensorEntity } from './sensor.entity';
import {
  formattedCoordinates,
  formattedDate,
  formattedTimeUTC,
  offsetDate,
  secondsToMMSSColon,
  utcDate,
  utcNow,
  utcSubtractDate,
} from '@utils/utils';
import { CompanyEntity } from './company.entity';
import { InstallationStructureEntity } from './installation-structure.entity';
import { SensorAlarmEntity } from './sensor-alarm.entity';
import { InstallationImageEntity } from './installation-image.entity';
import { InstallationFileEntity } from './installation-file.entity';
import { PricePlanEntity } from './price-plan.entity';
import { BaseDataProviderSplitEntity } from './base.entity';

export class InstallationEntity extends BaseDataProviderSplitEntity {
  @Expose()
  public name: string;

  @Expose()
  public companyId: string;

  @Expose()
  public registrationCode: string;

  @Expose()
  @Transform(({ value }) => value || 0, { toClassOnly: true })
  public latitude: number;

  @Expose()
  @Transform(({ value }) => value || 0, { toClassOnly: true })
  public longitude: number;

  public get onNullIsland(): boolean {
    return this.latitude === 0 && this.longitude === 0;
  }

  public get coordinates(): string {
    return `${this.latitude.toString()}, ${this.longitude.toString()}`;
  }

  public set coordinates(coords: string) {
    const parts: string[] = coords.split(', ');
    this.latitude = Number(parts[0]);
    this.longitude = Number(parts[1]);
  }

  public get formattedCoordinates(): string {
    return formattedCoordinates(this.coordinates);
  }

  @Expose()
  public allowUploading: boolean;

  @Expose()
  @Transform(({ value }) => Number(value), { toClassOnly: true })
  public blockingReason: number;

  @Expose()
  public allowEditCoords: boolean;

  @Expose()
  public dataFrequency: number;

  @Expose()
  public cloudKey: string;

  @Expose()
  public desktopKey: string;

  @Expose()
  public lastUpdated: string;

  public localizedLastUpdatedDate(language: string): string {
    return this.lastUpdated ? formattedDate(language, offsetDate(this.lastUpdated)) : '';
  }

  public localizedLastUpdatedTime(language: string): string {
    return this.lastUpdated ? formattedTimeUTC(language, new Date(this.lastUpdated + 'Z')) : '';
  }

  @Expose()
  public timeZone: string;

  @Expose()
  public reference: string;

  @Expose()
  @Transform(({ value }) => Number(value), { toClassOnly: true })
  public dataProvider: number;

  @Expose()
  public paid: boolean;

  @Expose()
  public deletable: boolean;

  @Expose()
  @Transform(({ value }) => Number(value), { toClassOnly: true })
  public state: number;

  @Expose()
  @Transform(({ value }) => Number(value), { toClassOnly: true })
  public locationState: number;

  @Expose()
  public lastStateChange: string;

  public lastRefreshed: string;

  public get utcLastRefreshed(): string {
    return utcDate(this.lastRefreshed);
  }

  public get dataRefreshRemainingSeconds(): number {
    return moment(this.utcLastRefreshed)
      .add(this.dataFrequency * 60, 'seconds')
      .diff(moment(utcNow()), 'seconds');
  }

  public dataRefreshDownCounter: number;

  public get formattedDataRefreshDownCounter(): string {
    return secondsToMMSSColon(this.dataRefreshDownCounter);
  }

  @Expose()
  public expiresAt: string;

  public localizedExpiresAt(language: string): string {
    return formattedDate(language, moment(this.expiresAt).toDate());
  }

  public get expiresAtTooltipSuffix(): string {
    return this.awayFromExpiration
      ? 'awayFromExpiration'
      : this.aboutToExpire
      ? 'aboutToExpire'
      : 'hasExpired';
  }

  public get awayFromExpiration(): boolean {
    return utcNow() < utcSubtractDate(this.expiresAt, 1, 'month');
  }

  public get aboutToExpire(): boolean {
    const now = utcNow();
    return utcSubtractDate(this.expiresAt, 1, 'month') <= now && now <= utcDate(this.expiresAt);
  }

  public get hasExpired(): boolean {
    return utcDate(this.expiresAt) < utcNow();
  }

  @Expose()
  public shortLink: string;

  @Expose()
  public shortLinkExpiresAt: string;

  public get utcShortLinkExpiresAt(): string {
    return utcDate(this.shortLinkExpiresAt);
  }

  public get hasAnActiveShortLink(): boolean {
    return this.shortLink && utcNow() < this.utcShortLinkExpiresAt;
  }

  public get shortLinkRemainingSeconds(): number {
    return !this.shortLink || this.utcShortLinkExpiresAt < utcNow()
      ? 0
      : moment(this.utcShortLinkExpiresAt).diff(moment(utcNow()), 'seconds');
  }

  public shortLinkDownCounter: number;

  public get formattedShortLinkDownCounter(): string {
    return secondsToMMSSColon(this.shortLinkDownCounter);
  }

  @Expose()
  public dataSummary: number;

  @Expose()
  public ctcPlusVersion: string;

  @Expose()
  public temperatureUoM: string;

  @Expose()
  public markedForStructureDeletion: boolean;

  @Expose()
  public markedForRemoval: boolean;

  @Expose()
  public structure: InstallationStructureEntity;

  @Expose()
  public canvasImage: InstallationImageEntity;

  @Expose()
  @Type(() => PricePlanEntity)
  public pricePlan: PricePlanEntity;

  @Expose()
  @Type(() => InstallationFileEntity)
  public files: InstallationFileEntity[];

  @Expose()
  @Type(() => InstallationEventCountersEntity)
  public events: InstallationEventCountersEntity[];

  @Expose()
  @Type(() => SensorEntity)
  public sensors: SensorEntity[];

  @Expose()
  @Type(() => CompanyEntity)
  public company: CompanyEntity;

  public get adminName(): string {
    return this.company.companyAdmin.fullName;
  }

  public get adminEmail(): string {
    return this.company.companyAdmin.email;
  }

  public get companyName(): string {
    return this.company.name;
  }

  @Expose()
  @Type(() => SensorAlarmEntity)
  public sensorAlarms: SensorAlarmEntity[];
}
