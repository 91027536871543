<ol>
  <li>
    <p>
      <b>VALDYTOJO pristatymas ir identifikavimas</b>
    </p>
    <p>
      Svetainė ir logotipas yra GESCASER (toliau KONTROLĖJAS) nuosavybė su pridėtine verte
      Mokesčių mokėtojo kodas A25276635 ir registruota buveinė Sant Pere 1 iš Almacelles.
    </p>
  </li>
  <li>
    <p><b>Veiklos aprašymas</b></p>
    <p>
      Per šią svetainę KONTROLĖJAS nori jus informuoti apie savo veiklą ir paslaugas.
      KONTROLIERIUS, atsižvelgdamas į įmonės ir sektoriaus raidą ir pažangą, gali
      išplėsti savo svetainę įtraukiant naujas paslaugas, veiklą ar turinį, kad pagerintų
      paslaugas ir teikiamos paslaugos kokybę. Ir atvirkščiai, VALDIKLIS taip pat pasilieka
      teisę atšaukti, keisti, pakeisti ar apriboti turinį, paslaugas ar veiklą,
      aiškiai ir be išankstinio įspėjimo vartotojams.
    </p>
    <p>
      Ši svetainė skirta tiek klientams, tiek visiems kitiems joje besilankantiems vartotojams (toliau –
      kaip VARTOTOJAI).
    </p>
    <p>
      Jei turite klausimų dėl šių Bendrųjų sąlygų, susisiekite su mumis el
      gescaser@gescaser.com
    </p>
  </li>
  <li>
    <p>
      <b>Sutikimas su bendromis sąlygomis ir naudojimasis svetaine</b>
    </p>
    <p>
      Naudojimąsi šia svetaine reglamentuoja šios Bendrosios naudojimo sąlygos (toliau
      BENDROSIOS SĄLYGOS). Atidžiai perskaitykite jas kaip prieigą prie šios svetainės ir jos naudojimą
      turinys reiškia visišką jų priėmimą.
    </p>
    <p>
      Prieiga prie šios svetainės ir joje esančios informacijos yra visiškai nemokama,
      nepažeidžiant telefono ryšio kainos ir priemonių, naudojamų ryšiui sukurti
      ryšį.
    </p>
    <p>
      Tačiau šioje svetainėje galite sužinoti apie įvairias paslaugas, kurias galima išsinuomoti
      susisiekimas su KONTROLERIU; kurios bus teikiamos pagal sutartas Specialiąsias sąlygas
      tarp šalių (toliau SPECIALIOSIOS SĄLYGOS).
    </p>
    <p>
      Informuojame, kad norint pasiekti svetainėje esančią informaciją, mums nereikia Jūsų
      Asmeniniai duomenys. Tačiau tuo atveju, jei pateikiate užklausą internetu arba susitarsite
      susijusių su siūlomomis paslaugomis arba paprašykite bet kokių konkrečių paslaugų, siūlomų per
      svetainėje, reikės rinkti Jūsų asmens duomenis ir tokiu atveju to prašome
      atidžiai perskaitykite mūsų PRIVATUMO POLITIKĄ, kurią taip pat reikia perskaityti, suprasti ir priimti.
    </p>
    <p>Priimdami šią sutartį pareiškiate:</p>
    <ol type="a">
      <li>
        Kad esate pilnametystės ir (arba) turite teisę sudaryti sutartį pagal
        valstybės, kurioje gyvenate, bendruosius sutarčių įstatymus.
      </li>
      <li>
        Kad jūs anksčiau perskaitėte, supratote ir sutikote su BENDROSIOS SĄLYGOS, PRIVATUMAS
        POLITIKA ir, jei taikoma, SPECIALIOSIOS SĄLYGOS.
      </li>
    </ol>
  </li>
  <li>
    <p><b>BENDRŲJŲ SĄLYGŲ pakeitimai</b></p>
    <p>
      Informacija šioje svetainėje ir siūlomos paslaugos nuolat keičiasi ir yra
      sukurta siekiant suteikti geriausią įmanomą patirtį ir vertę.
    </p>
    <p>
      Kai kas nors pakeičiama, o tai reiškia BENDROSIOS SĄLYGOS pakeitimą, SPECIFIC
      SĄLYGOS ir (arba) PRIVATUMO POLITIKA, minėti pakeitimai turi būti padaryti ir paskelbti
      ir atnaujinta. Todėl rekomenduojama juos visus skaityti periodiškai ir (arba) aukščiau
      prieš sudarant bet kokią sutartį.
    </p>
    <p>
      Atnaujintos BENDROSIOS SĄLYGOS, SPECIALIOSIOS SĄLYGOS ir (arba) PRIVATUMO POLITIKA bus prieinamos
      visą laiką šioje svetainėje.
    </p>
    <p>
      Šios svetainės VARTOTOJAS sutinka su tokiais pakeitimais nuo to momento, kai toliau naudojasi
      svetainę ir (arba) teikiamas paslaugas.
    </p>
  </li>
  <li>
    <p><b>Esminiai NAUDOTOJO įsipareigojimai.</b></p>
    <p>
      Visi VARTOTOJAI nuo to momento, kai jie pradeda naudotis svetaine ir (arba) sudaro sutartį dėl bet kurios paslaugos
      joje siūlomi, privalo laikytis šių esminių įsipareigojimų trečiųjų atžvilgiu
      šalys ir KONTROLĖJAS:
    </p>
    <ol type="a">
      <li>
        Naudotis svetaine neatliekant neteisėtų ar neteisėtų ar prieštaraujančių veiksmų
        BENDROSIOS SĄLYGOS, PRIVATUMO POLITIKOS nuostatos ir, jei taikoma,
        SPECIALIOS SĄLYGOS ir jų atnaujinimai.
      </li>
      <li>
        Nepažeisti, išjungti, neperkrauti ar negadinti interneto svetainės arba neleisti jos įprastai naudoti arba
        malonumas.
      </li>
      <li>
        Nemėginti pažeisti prieigos lygių, manipuliuoti duomenimis, kopijuoti ir eksportuoti
        duomenys ar informacija, saugoma intelektinės nuosavybės ar kitų teisinių teisių, prieiga
        KONTROLĖJO ar trečiųjų šalių kompiuterinių sistemų apribotas zonas, pristatyti
        programas, virusus ar bet kokį kitą įrenginį, kuris sukelia arba gali sukelti kompiuterio pakeitimus
        KONTROLĖJO ar trečiųjų šalių sistema.
      </li>
      <li>
        Būti susipažinęs su techniniais ir teisiniais reikalavimais, reikalingais norint teisingai pasiekti
        šią svetainę ir, jei taikoma, sutarčių dėl produktų ir (arba) paslaugų sudarymą; kuris gali
        apima tam tikrų kompiuterinių programų ar kitų loginių elementų atsisiuntimą į juos
        kompiuterių įrenginiai.
      </li>
      <li>
        Jokiu būdu nenaudoti, nesieti, neatskleisti, perleisti ar neperduoti trečiosioms šalims turinio
        paskelbta svetainėje ar bet kurioje jų dalyje, arba KONTROLĖJO informacijoje,
        be išankstinio raštiško VALDYTOJO leidimo.
      </li>
      <li>
        Jokiu būdu negadinti VALDYMO įvaizdžio, nei atlikti jokios veiklos ar gaminti
        bet koks komentaras, galintis sugadinti GESCASER įvaizdį ir gerą vardą.
      </li>
      <li>
        Norėdami perskaityti, suprasti ir, jei sutinkate, sutikti su BENDROMIS SĄLYGOMIS, PRIVATUMO POLITIKA
        ir, kai taikoma, SPECIALIOSIOS SĄLYGOS ir jų atnaujinimai, o konkrečiau
        KONTROLĖJO NUORODŲ IR (ARBA) Slapukų POLITIKA.
      </li>
    </ol>
  </li>
  <li>
    <p><b>Intelektinė nuosavybė</b></p>
    <p>
      Ši svetainė, jos turinys ir logotipai yra saugomi ispanų ir tarptautinių
      intelektinės ir pramoninės nuosavybės įstatymus, ir visos tokios teisės išimtinai atitinka
      KONTROLIERIUS, jo naudojimas yra rezervuotas.
    </p>
    <p>
      Atgaminimas, platinimas, perdavimas, transformavimas ir (arba) viešas atskleidimas ir
      bendras, bet koks kitas neteisėto viso ar dalies turinio naudojimas bet kokiomis priemonėmis
      ši svetainė, jos dizainas, čia įtrauktos medžiagos pasirinkimas ir pristatymas yra
      draudžiamas; tas pats pasakytina apie prekės ženklus ir prekių pavadinimus, įtrauktus į šią svetainę. Toks
      veiksmai gali būti atliekami tik gavus aiškų išankstinį raštišką VALDYTOJO sutikimą
      ir su sąlyga, kad pateikiama aiški nuoroda į KONTROLĖJO nuosavybės teisę į
      pirmiau minėtos intelektinės ir pramoninės nuosavybės teisės.
    </p>
    <p>
      Naudodamasis svetaine ir (arba) sudarydamas sutartį dėl paslaugų, VARTOTOJAS neįgyja jokių teisių į
      juos tiesiog turėdamas teisę jais naudotis pagal šių BENDROJIŲ NUOSTATŲ nuostatas
      SĄLYGOS ir (arba), kai taikoma, SPECIALIOSIOS SĄLYGOS.
    </p>
    <p>
      Tuo atveju, jei jūs, NAUDOTOJAS, aptiksite bet kokią veiklą, kuri gali pažeisti bet kokią intelektinę ar
      pramoninės nuosavybės teisės ar bet kokios kitos rūšies teisės, praneškite mums atsiųsdami pranešimą adresu
      el. pašto adresu: gescaser@gescaser.com
    </p>
  </li>
  <li>
    <p><b>Privatumo politika</b></p>
    <p>
      n atitikties galiojančių nacionalinių ir Europos teisės aktų nuostatoms
      asmens duomenų apsaugą, informuojame, kad asmens duomenys, kuriuos mūsų klientai ar
      potencialūs klientai gali mums suteikti (abu vadinami „Klientu (-iais)“) arba
      naudodamiesi mūsų svetainėje (toliau – Svetainė) pateikta forma, išsiųsdami el. laišką adresu
      šiuo tikslu numatytu elektroniniu paštu, adresuotu mūsų specialistams, ar bet kokiu kitu būdu
      ryšio priemonės, bus įtrauktos į bylą, kurios GESCASER (toliau –
      KONTROLIERIUS) su pridėtinės vertės mokesčio ID numeriu A25276635 ir registruota buveine Sant Pere 1 nuo
      Almacelles yra savininkas ir duomenų valdytojas.
    </p>
    <ol type="a">
      <li>
        Sutikimas ir surinkti duomenys
        <p>
          Klientas, pateikdamas DALYTOJUI prašomus asmens duomenis, pareiškia, kad
          jis perskaitė ir aiškiai sutiko su šia Privatumo politika, todėl sutinka
          savo laisvą, konkretų, nedviprasmišką ir informuotą sutikimą KONTROLĖJO tvarkymui
          savo asmens duomenis pagal šio dokumento nuostatas.
        </p>
        <p>
          Jei nenurodyta kitaip, visa informacija, kurios prašome iš savo klientų
          Tinklalapyje esančiose formose. Atsisakymas pateikti reikiamus duomenis
          bus neįmanoma patenkinti konkretaus atitinkamo prašymo. Prašome mus informuoti
          nedelsiant apie bet kokius jūsų duomenų pasikeitimus, kad mūsų failuose esanti informacija būtų tokia
          visada atnaujinta ir be klaidų. Atsižvelgdamas į tai, Klientas pareiškia, kad
          pateikta informacija ir duomenys yra tikslūs, naujausi ir teisingi.
        </p>
      </li>
      <li>
        Tvarkymo tikslas ir teisinis pagrindas
        <p>
          KONTROLĖJO atliekamo duomenų tvarkymo tikslas – išnagrinėti pateiktą užklausą, atsakyti
          jūsų užklausas ir prašymus suteikti informaciją ir, jei reikia, prižiūrėti ir valdyti
          profesiniai, komerciniai ir (arba) sutartiniai santykiai, užmegzti su bet kuriuo iš mūsų
          biurams ir/ar profesionalams (toliau – „Paslauga(-os)“).
        </p>
        <p>
          Teisinis pagrindas apdorojimas panaikina jūsų asmens duomenis dėl: i)
          Kliento sutikimas su šioje Privatumo politikoje aprašytu tvarkymu ir ii)
          Kliento pageidaujamų VALDYTOJO paslaugų teikimas.
        </p>
      </li>
      <li>
        Kam perduodame jūsų duomenis
        <p>
          DALYTOJAS neperduos Jūsų asmens duomenų trečiosioms šalims, nebent tai būtų teisėta
          reikalingas arba jei Klientas mus anksčiau įgaliojo tai padaryti arba jei tai būtina
          už prašomų Paslaugų teikimą.
        </p>
        <p>
          Kad galėtume pasiūlyti Klientui tinkamą Paslaugą ir valdyti
          santykius su jumis KONTROLĖJAS sudaro paslaugų teikimo sutartis su tam tikromis
          įmonėms, kurioms, laikantis teisės aktų reikalavimų, gali būti suteikta prieiga
          jūsų asmens duomenis tik Paslaugos teikimo tikslais.
        </p>
      </li>
      <li>
        Tarptautinis asmens duomenų perdavimas
        <p>
          Bendrasis duomenų apsaugos reglamentas (BDAR) siūlo keletą mechanizmų, leidžiančių
          asmens duomenų perdavimo už Europos Sąjungos (ES) ribų. Šie mechanizmai buvo nustatyti
          iki patikrinti, ar apsaugos lygis yra tinkamas, arba užtikrinti jos įgyvendinimą
          atitinkamas saugumo priemones perduodant asmens duomenis į ES nepriklausančią šalį.
        </p>
        <p>
          Tokį saugumo lygį galima pasiekti taikant standartines sutarties sąlygas. tai yra
          galima patikrinti, ar apsaugos lygis yra tinkamas
          tokias procedūras kaip ES ir JAV privatumo skydo sistemoje.
        </p>
        <p>
          Dabartinėse sutartyse dėl duomenų tvarkymo įsipareigojame išlaikyti mechanizmą, kuris
          leidžia perduoti asmens duomenis už ES ribų, kaip numatyta Duomenų apsaugos reglamente
          direktyva. Be to, nuo 2018 m. gegužės 25 d. prisiimsime atitinkamą įsipareigojimą
          (imtinai), kai įsigalios BDAR.
        </p>
        <p>
          Europos duomenų apsaugos institucijos taip pat patvirtino, kad mūsų standartinė sutartis
          punktai atitinka būtinus saugumo reikalavimus. Tai rodo, kad GESCASER
          sutartiniai įsipareigojimai atitinka Duomenų apsaugos direktyvą ir suteikia teisinę
          asmens duomenų perdavimo iš ES į kitas pasaulio dalis sistemą.
        </p>
      </li>
      <li>
        Asmens duomenų saugojimo laikotarpis
        <p>
          Jūsų asmens duomenis saugosime visą sutartinių santykių laikotarpį arba
          kur taikoma, kol nepareikšite savo pageidavimo, kad jūsų asmens duomenys būtų ištrinti iš mūsų
          failą. Nuo to momento DALYTOJAS jūsų asmens duomenis blokuos
          teisiškai reikalaujamus laikotarpius ir bet kuriuo atveju 10 metų laikotarpiui pagal
          pinigų plovimo ir terorizmo finansavimo prevencijos nuostatai. Mes
          sunaikinti Jūsų asmens duomenis pasibaigus teisės aktų nustatytam terminui.
        </p>
      </li>
      <li>
        Teisių įgyvendinimas
        <p>
          Galite pasinaudoti savo prieigos, taisymo, ištrynimo, apribojimo teisėmis
          Asmens duomenų tvarkymas, prieštaravimas ir perkeliamumas siunčiant raštišką pranešimą
          su asmens tapatybės kortelės kopija KONTROLĖJUI šiuo el. pašto adresu:
          gescaser@gescaser.com
        </p>
      </li>
      <li>
        Skundas priežiūros institucijai
        <p>
          Esate informuotas, kad turite teisę pateikti skundą su Ispanijos duomenimis
          Apsaugos agentūra (www.aepd.es) kaip priežiūros institucija, jei manote, kad
          DALYTOJAS jūsų asmens duomenų netvarkė pagal galiojančius reikalavimus
          asmens duomenų apsaugos teisės aktai.
        </p>
      </li>
      <li>
        Saugumas
        <p>
          KONTROLIERIUS įsipareigoja imtis techninių ir organizacinių saugumo priemonių
          įstatymų nustatytas siekiant garantuoti Jūsų asmens duomenų saugumą ir užkirsti kelią jų atsiradimui
          pakeitimas, praradimas, neteisėtas apdorojimas ar prieiga, atsižvelgiant į būseną
          technologija, saugomų duomenų pobūdis ir rizika, su kuria jie susiduria
          pagal galiojančių reglamentų nuostatas.
        </p>
      </li>
      <li>
        Trečiųjų šalių duomenų priskyrimas VALDYTOJUI
        <p>
          Tuo atveju, kai DALYTOJUI pateikiami būtini trečiųjų šalių asmens duomenys
          dėl Paslaugų teikimo pareiškiate, kad anksčiau jas gavote
          aiškus sutikimas juos perduoti KONTROLĖJUI.
        </p>
      </li>
      <li>
        Mūsų privatumo politikos pakeitimai
        <p>
          KONTROLĖJAS gali bet kada atnaujinti šią Privatumo politiką. Atnaujinta to versija
          Privatumo politika visada bus pasiekiama Platformoje. Jei šie pakeitimai turi įtakos tikslams
          nenumatyta šioje privatumo politikoje, informuosime jus pakankamai iš anksto
          el. paštu (paskutiniu nurodytu adresu), kad galėtumėte
          naudotis teisėmis, kurias laikote tinkamomis ir kurias turite pagal Sutartį
          taikomus reglamentus.
        </p>
      </li>
    </ol>
  </li>
  <li>
    <p><b>Atsakomybė</b></p>
    <p>
      KONTROLIERIUS visada turi stengtis teikti aukščiausią įmanomą paslaugą
      kokybės. Nepažeisdami to, kas išdėstyta pirmiau, mes atsisakome visos atsakomybės, kylančios dėl gedimų,
      pertrūkių ar žalos, atsiradusios dėl sistemos gedimų, trukdžių ar atjungimų arba dėl
      paslaugos gedimas.
    </p>
    <p>
      Taip pat KONTROLĖJAS atsisako bet kokios atsakomybės ir (arba) atsakomybės už bet kokios rūšies žalą
      arba pobūdis, atsirandantis dėl neteisėtų trečiųjų šalių veiksmų bet kokiomis priemonėmis arba dėl naudojimo
      Vartotojai netinkamai ar netinkamai apdoroja svetainę ir jokiomis aplinkybėmis
      KONTROLĖLIS prisiima bet kokią atsakomybę už kitų veiksmus ar aplaidumą
      gali arba negali turėti tiesioginės ar netiesioginės įtakos serveriams ir kitai kompiuterinei įrangai
      VARTOTOJAS arba trečiosios šalys.
    </p>
    <p>
      VARTOTOJAS sutinka atlyginti žalą ir nepažeisti VALDYTOJO, jo dukterinių įmonių, partnerių,
      vadovams, bendradarbiams ir (arba) darbuotojams nuo bet kokių pretenzijų ar reikalavimų, įskaitant honorarus advokatams,
      padarė trečiosios šalys dėl to, kad VARTOTOJAS netinkamai ar neteisėtai naudojosi
      svetainė ir (arba) siūlomos paslaugos, arba VARTOTOJAS pažeidė BENDROSIOS SĄLYGOS, SPECIALUS
      SĄLYGOS, jei taikomos, ir (arba) PRIVATUMO POLITIKA, taip pat bet koks teisės aktų ar bet kokių
      kitos teisės.
    </p>
    <p>
      VALDYMAS negali garantuoti, kad nėra virusų ar kitų kenkėjiškų programų, kurios gali turėti įtakos
      NAUDOTOJO kompiuterinė įranga. Todėl pastarieji turėtų imtis priemonių, kad būtų išvengta galimų
      tokios rizikos, atsižvelgiant į dabartinę telekomunikacijų padėtį.
    </p>
    <p>
      KONTROLĖJAS neatsako už interneto svetainės trikdžius, atsiradusius dėl nenugalimos jėgos arba
      nuo jo nepriklausančių aplinkybių.
    </p>
    <p>
      Taip pat VALDYMAS gali laikinai nutraukti prieigą saugumo sumetimais arba dėl
      kompiuterinių išteklių pertvarka, siekiant pagerinti naudojimosi svetaine paslaugą.
    </p>
    <p>
      KONTROLĖJAS VARTOTOJUI pateikia tik svetainėje paskelbtą informaciją. Vartotojas,
      todėl yra vienintelė atsakinga už teisingą tokios informacijos naudojimą ir už tai
      toks naudojimas atliekamas pagal BENDROSIAS SĄLYGAS, SPECIALIAS SĄLYGAS, kur
      taikoma ir PRIVATUMO POLITIKA.
    </p>
    <p>
      Jei VARTOTOJAS nėra patenkintas naudojimusi svetaine, jos turiniu ar paslaugomis ar bet kuria kita
      dalis šių BENDROJŲ SĄLYGŲ, PRIVATUMO POLITIKOS ir (arba) SPECIALIŲJŲ SĄLYGŲ, jo
      vienintelė ir išskirtinė teisės gynimo priemonė bus nustoti naudotis svetaine ir (arba) jos paslaugomis.
    </p>
  </li>
  <li>
    <p><b>Nuorodos ir&nbsp;slapukai</b></p>
    <p>
      Jei prisijungiate prie kitos nuorodos ar reklamjuostės, išskyrus šią svetainę, turėtumėte žinoti, kad jūs
      naršys kitoje interneto svetainėje, kurios naudojimo taisyklės ir privatumo politika yra
      nekontroliuojamas VALDIKLIS. Todėl patartina patikrinti tokias taisykles
      naudojimo ir privatumo politikos, siekiant išsiaiškinti taikomas taisykles ir jų tvarkymą
      jūsų asmeninius duomenis.
    </p>
    <p>
      Draudžiama į trečiųjų šalių svetaines įtraukti turinį ar paslaugas, galinčius pakenkti
      šios svetainės ar KONTROLĖJO vaizdą, o pastarasis pasilieka teisę uždrausti, apriboti
      arba reikalauti atšaukti padarytas nuorodas, kai manoma tikslinga, be jokių sąlygų
      aplinkybės, dėl kurių dėl šios priežasties reikalaujama sumokėti bet kokią kompensaciją.
    </p>
    <p>
      KONTROLĖJO svetainėje www (toliau – Svetainė) naudojami slapukai, kurie yra maži failai
      kurioje yra informacijos apie jūsų naršymą šioje svetainėje ir kurios pagrindinis tikslas yra
      supaprastinkite naršymą. Skaitykite toliau, kad sužinotumėte daugiau apie mūsų naudojamus slapukus,
      jų paskirtis ir kita dominanti informacija.
    </p>
    <p>Kas yra slapukai?</p>
    <p>
      Slapukas yra failas, kuris įdedamas į svetainę, kai jį pasiekiate ir naudojate. Slapukai mums leidžia
      saugoti ir gauti informaciją apie savo naršymo svetainėje įpročius. Informacija, kurią jie
      gauti yra susijęs, pavyzdžiui, su lankomų puslapių skaičiumi, dažnumu ir pasikartojimu
      apsilankymų skaičius, jūsų apsilankymo trukmė ir kt.
    </p>
    <p>Ką kokius slapukus naudojame?</p>
    <ol type="a">
      <li>
        Pagal juos valdantį subjektą:
        <p>
          Nuosavos slapukai: tie, kurie siunčiami į jūsų kompiuterį iš mūsų kompiuterių arba domenų iš
          kurios teikia jūsų prašomą paslaugą.
        </p>
        <p>
          Trečiųjų šalių slapukai: tie, kurie siunčiami į jūsų kompiuterį iš kompiuterio ar domeno, kuris
          valdo ne mes, o kita susijusi organizacija.
        </p>
      </li>
      <li>
        Priklausomai nuo to, kiek laiko jie išlieka aktyvūs:
        <p>
          Seanso slapukai: tie, kurie išsaugo reikiamą informaciją seanso metu ir
          su juo susijusių pokyčių.
        </p>
        <p>
          Nuolatiniai slapukai: jie yra saugomi kietajame diske ir kiekvieną kartą, kai jūs nuskaito mūsų svetainę
          atlikti atsakomąjį vizitą. Nuolatinė svetainė turi tam tikrą galiojimo laiką. Slapukas sustos
          dirba po šios datos.
        </p>
      </li>
      <li>
        Pagal paskirtį:
        <p>
          Techniniai slapukai: jie būtini norint naršyti ir tinkamai veikti
          Interneto svetainė.
        </p>
        <p>
          Tinkinimo slapukai: tie, kurie leidžia pasiekti paslaugą su iš anksto nustatytais
          charakteristikos, priklausomai nuo daugelio kriterijų savybių, tokių kaip kalba, tipas
          naršyklę, per kurią pasiekiama paslauga, lokalę, iš kurios pasiekiate paslaugą,
          ir tt
        </p>
        <p>
          Analizės slapukai: tie, kurie leidžia kiekybiškai įvertinti vartotojų skaičių, taigi ir
          vartotojų naudojimosi teikiamomis paslaugomis matavimas ir statistinė analizė.
        </p>
        <p>
          Jei nenorite leisti mūsų svetainėje naudoti slapukų, vadovaukitės šiais nurodymais
          instrukcijos:
        </p>
        <p>
          Naršyklės kūrėjai gali peržiūrėti arba keisti šias procedūras, todėl
          negalime garantuoti, kad paskutinė visiškai atitinkanti versija visada bus prieinama.
        </p>
        <p>
          Norėdami išvengti slapukų įdiegimo, vadovaukitės savo naršyklės instrukcijomis:
        </p>
        <p>
          <b>Internet Explorer:</b> spustelėkite „Įrankiai &gt; Interneto parinktys“ meniu, tada pasirinkite
          Skirtukas „Privatumas“. Pasirinkite norimą nustatymą ir spustelėkite išplėstinių nustatymų mygtuką.
          Suaktyvinkite laukelį „Nepaisyti automatinio slapukų tvarkymo“. Pasirinkite „Priimti“ arba „Blokuoti“
          variantas. Rekomenduojame suaktyvinti parinktį „Visada leisti seanso slapukus“, kad įjungtumėte
          optimalų naršymą mūsų svetainėje.
        </p>
        <p>
          <b>Firefox:</b> spustelėkite „Įrankiai &gt; Parinktys“ meniu juostoje ir pasirinkite „Privatumas“
          skirtukas. Priklausomai nuo naudojamos naršyklės versijos, pažymėkite arba panaikinkite pasirinkimą „Priimti slapukus
          iš svetainių“ arba „Pasakykite svetainėms, kurių nenoriu, kad mane sektų“. Gali būti įdiegti bet kokie slapukai
          pašalinti spustelėdami parinktį „Pašalinti atskirus slapukus“.
        </p>
        <p>
          <b>Chrome:</b> spustelėkite „Įrankiai &gt; Nustatymai“ meniu, tada pasirinkite skirtuką „Privatumas &gt;
          Turinio nustatymai". Pasirinkę parinktį „Rodyti išplėstines parinktis“, pasirinkite norimą
          slapukų nustatymas. Jei nenorite, kad būtų įdiegti slapukai, galite pasirinkti parinktį
          „Blokuoti trečiųjų šalių slapukus ir svetainės duomenis“.
        </p>
        <p>
          <b>Opera:</b> skiltyje „Nustatymai &gt; Parinktys“, eikite į skirtuką „Išplėstinė“. Viduje konors
          Meniu „Slapukai“ pasirinkite parinktis „Sutinku“ arba „niekada nepriimti slapukų“. Jeigu nori
          mes galime geriau kontroliuoti jūsų kompiuteryje įdiegtus slapukus
          rekomenduojame pasirinkti parinktį „Klausti prieš priimant slapukus“.
        </p>
        <p>
          <b>Safari:</b> spustelėkite „Įrankiai &gt; Preferences“ ir pasirinkite skirtuką „Sauga“. Pagal
          antraštėje „Priimti slapukus“ pasirinkite „Visada“ arba „Niekada“. Jei turite iPhone, turite eiti į
          „Nustatymai &gt; Safari“ ir pasirinkite, ar norite priimti slapukus, ar nenorite.
        </p>
      </li>
    </ol>
    <p>
      Jei naudojate kitą naršyklę, galite gauti daugiau informacijos apie diegimo konfigūravimą
      slapukus per pagalbos skyrių arba pagalbą iš tos naršyklės.
    </p>
    <p>
      Jei jums reikia pagalbos konfigūruojant naršyklės slapukus, atsiųskite mums el
      laišką adresu gescaser@gescaser.com ir mes kuo greičiau su jumis susisieksime ir padėsime.
    </p>
    <p>Slapukų politikos atnaujinimai ir pakeitimai:</p>
    <p>
      KONTROLĖJAS gali pakeisti šią slapukų politiką vadovaudamasis teisės aktais
      reikalavimus, arba siekiant pritaikyti šią politiką pagal pateiktus nurodymus
      Ispanijos duomenų apsaugos agentūra, dėl kurios vartotojui patariama periodiškai joje apsilankyti.
    </p>
  </li>
  <li>
    <p><b>Sąlygų negaliojimas ir neveiksmingumas</b></p>
    <p>
      Jeigu yra paskelbta bet kuri sąlyga, įtraukta į šias BENDROSIAS SĄLYGAS ir (arba) SPECIALIOSES SĄLYGOS
      visiškai arba iš dalies negaliojančiais arba negaliojančiais, toks negaliojimas arba negaliojimas yra
      turi įtakos tik tai jos nuostatos daliai, kuri yra niekinė ir negaliojanti, ir BENDROSIOS NUOSTATOS
      SĄLYGOS ir SPECIALIOSIOS SĄLYGOS galioja visais kitais atžvilgiais, o
      nuostata ar jos dalis, kuriai tai daroma, laikoma niekine ir negaliojančia.
    </p>
  </li>
  <li>
    <p><b>Taikoma teisė ir jurisdikcija</b></p>
    <p>
      Dėl bet kokių problemų ar neatitikimų, kurie gali kilti dėl svetainės, turinio ir paslaugų
      sudarytą sutartį, VARTOTOJAS ir KONTROLĖJAS, aiškiai atsisakydami bet kokios kitos galimos jurisdikcijos
      jiems taikyti, aiškiai paklusti Ispanijos teisei, Ispanijos teismų jurisdikcijai ir
      ypač Madrido teismai, nebent taikoma teisė konkrečiai nustato kitą
      jurisdikcija arba kiti teisės aktai.
    </p>
  </li>
</ol>