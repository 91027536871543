import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CoreModule } from '@core/core.module';
import { AgreeToTOSDialogComponent } from './agree-to-tos-dialog.component';
import { CatalanTOSContentsComponent } from './tos-contents/ca-tos-contents.component';
import { GermanTOSContentsComponent } from './tos-contents/de-tos-contents.component';
import { EnglishTOSContentsComponent } from './tos-contents/en-tos-contents.component';
import { CastilianTOSContentsComponent } from './tos-contents/es-tos-contents.component';
import { FrenchTOSContentsComponent } from './tos-contents/fr-tos-contents.component';
import { LithuanianTOSContentsComponent } from './tos-contents/lt-tos-contents.component';
import { RussianTOSContentsComponent } from './tos-contents/ru-tos-contents.component';

@NgModule({
    declarations: [
        AgreeToTOSDialogComponent,
        EnglishTOSContentsComponent,
        CatalanTOSContentsComponent,
        FrenchTOSContentsComponent,
        CastilianTOSContentsComponent,
        RussianTOSContentsComponent,
        GermanTOSContentsComponent,
        LithuanianTOSContentsComponent,
    ],
    imports: [CommonModule, CoreModule],
    exports: [AgreeToTOSDialogComponent]
})
export class AgreeToTOSDialogModule {}
