import { Routes } from '@angular/router';
import { AuthLayoutComponent } from './core/layout/auth-layout/auth-layout.component';
import { ContentLayoutComponent } from './core/layout/content-layout/content-layout.component';
import { AuthGuard } from './core/guards/auth.guard';
import { NoAuthGuard } from '@core/guards/no-auth.guard';
import { SuperAdminGuard } from '@guards/super-admin.guard';
import { CompanyAdminGuard } from '@guards/company-admin.guard';
import { AnyAdminGuard } from '@guards/any-admin.guard';

export const appRoutes: Routes = [
  {
    path: '',
    redirectTo: '/auth/login',
    pathMatch: 'full',
  },
  {
    path: 'action',
    canActivate: [NoAuthGuard],
    loadChildren: () => import('@modules/action/action.module').then((m) => m.ActionModule),
  },
  {
    path: '',
    component: ContentLayoutComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'profile',
        loadChildren: () => import('@modules/profile/profile.module').then((m) => m.ProfileModule),
      },
      {
        path: 'super_admin',
        canActivate: [SuperAdminGuard],
        loadChildren: () =>
          import('@modules/super_admin/super-admin.module').then((m) => m.SuperAdminModule),
      },
      {
        path: 'company_admin',
        canActivate: [CompanyAdminGuard],
        loadChildren: () =>
          import('@modules/company_admin/company-admin.module').then((m) => m.CompanyAdminModule),
      },
      {
        path: 'installations',
        loadChildren: () =>
          import('@modules/installations/installations.module').then((m) => m.InstallationsModule),
      },
      {
        path: 'installation',
        loadChildren: () =>
          import('@modules/single-installation/single-installation.module').then(
            (m) => m.SingleInstallationModule
          ),
      },
      {
        path: 'companies',
        canActivate: [SuperAdminGuard],
        loadChildren: () =>
          import('@modules/companies/companies.module').then((m) => m.CompaniesModule),
      },
      {
        path: 'company',
        canActivate: [CompanyAdminGuard],
        loadChildren: () => import('@modules/company/company.module').then((m) => m.CompanyModule),
      },
      {
        path: 'price-plans',
        canActivate: [AnyAdminGuard],
        loadChildren: () =>
          import('@modules/price-plans/price-plans.module').then((m) => m.PricePlansModule),
      },
      {
        path: 'subscriptions',
        canActivate: [AnyAdminGuard],
        loadChildren: () =>
          import('@modules/subscriptions/subscriptions.module').then((m) => m.SubscriptionsModule),
      },
      {
        path: 'payments',
        canActivate: [SuperAdminGuard],
        loadChildren: () =>
          import('@modules/payments/payments.module').then((m) => m.PaymentsModule),
      },
      {
        path: 'dashboard',
        canActivate: [SuperAdminGuard],
        loadChildren: () =>
          import('@modules/dashboard/dashboard.module').then((m) => m.DashboardModule),
      },
      {
        path: 'all/subscriptions',
        canActivate: [SuperAdminGuard],
        loadChildren: () =>
          import('@modules/subscriptions/subscriptions.module').then((m) => m.SubscriptionsModule),
      },
      {
        path: 'subscriptions',
        canActivate: [CompanyAdminGuard],
        loadChildren: () =>
          import('@modules/subscriptions/subscriptions.module').then((m) => m.SubscriptionsModule),
      },
      {
        path: 'resources',
        loadChildren: () =>
          import('@modules/resources/resources.module').then((m) => m.ResourcesModule),
      },
      {
        path: 'preferences',
        canActivate: [SuperAdminGuard],
        loadChildren: () =>
          import('@modules/preferences/preferences.module').then((m) => m.PreferencesModule),
      },
    ],
  },
  { path: 'auth', redirectTo: 'auth/login', pathMatch: 'full' },
  {
    path: 'auth',
    canActivateChild: [NoAuthGuard],
    component: AuthLayoutComponent,
    loadChildren: () => import('./modules/auth/auth.module').then((m) => m.AuthModule),
  },
  { path: '**', redirectTo: '/auth/login' },
];
