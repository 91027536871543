<ol>
  <li>
    <p>
      <b>Darstellung und Identifizierung des CONTROLLERS</b>
    </p>
    <p>
      Die Website und das Logo sind Eigentum von GESCASER (im Folgenden der VERANTWORTLICHE) mit Mehrwert
      Steueridentifikationsnummer A25276635 und Firmensitz Sant Pere 1 von Almacelles.
    </p>
  </li>
  <li>
    <p><b>Beschreibung der Aktivität</b></p>
    <p>
      Der VERANTWORTLICHE möchte Sie über diese Website über seine Aktivitäten und Dienstleistungen informieren.
      Der VERANTWORTLICHE kann je nach Entwicklung und Fortschritt des Unternehmens und der Branche
      seine Website um neue Dienste, Aktivitäten oder Inhalte zu erweitern, um die zu verbessern
      Dienstleistungen und Qualität der erbrachten Dienstleistungen. Umgekehrt behält sich der VERANTWORTLICHE auch die
      Recht, die Inhalte, Dienste oder Aktivitäten zu löschen, zu ändern, zu ersetzen oder einzuschränken,
      ausdrücklich und ohne vorherige Ankündigung an die Benutzer.
    </p>
    <p>
      Diese Website richtet sich sowohl an Kunden als auch an alle anderen Nutzer, die sie besuchen (nachfolgend bezeichnet als
      als BENUTZER).
    </p>
    <p>
      Wenn Sie Fragen zu diesen Allgemeinen Geschäftsbedingungen haben, kontaktieren Sie uns bitte unter
      gescaser@gescaser.com
    </p>
  </li>
  <li>
    <p>
      <b>Akzeptanz der Allgemeinen Geschäftsbedingungen und Nutzung der Website</b>
    </p>
    <p>
      Die Nutzung dieser Website wird durch die folgenden Allgemeinen Nutzungsbedingungen (im Folgenden:
      die ALLGEMEINEN BEDINGUNGEN). Bitte lesen Sie sie sorgfältig durch, da der Zugriff auf diese Website und deren Nutzung
      Inhalt impliziert die volle Akzeptanz von ihnen.
    </p>
    <p>
      Der Zugang zu dieser Website und den darin enthaltenen Informationen ist völlig kostenlos,
      unbeschadet der Kosten für die Telefonverbindung und die zum Herstellen der Verbindung verwendeten Mittel
      Verbindung.
    </p>
    <p>
      Auf dieser Website können Sie sich jedoch über die verschiedenen Dienste informieren, die von gemietet werden können
      Kontaktaufnahme mit dem CONTROLLER; die gemäß den vereinbarten Besonderen Bedingungen bereitgestellt werden
      zwischen den Parteien (im Folgenden die BESONDEREN BEDINGUNGEN).
    </p>
    <p>
      Wir weisen Sie darauf hin, dass wir Ihre Daten nicht benötigen, um auf die Informationen auf der Website zugreifen zu können
      persönliche Daten. Für den Fall, dass Sie eine Online-Anfrage stellen oder Vorkehrungen treffen
      im Zusammenhang mit den angebotenen Dienstleistungen oder fordern Sie eine der spezifischen Dienstleistungen an, die über die angeboten werden
      Website ist es notwendig, Ihre persönlichen Daten zu erheben, und in diesem Fall bitten wir Sie darum
      Lesen Sie unsere DATENSCHUTZERKLÄRUNG sorgfältig durch, die ebenfalls gelesen, verstanden und akzeptiert werden muss.
    </p>
    <p>Indem Sie diesen Vertrag akzeptieren, erklären Sie:</p>
    <ol type="a">
      <li>
        Dass Sie volljährig und/oder geschäftsfähig sind, gemäß der
        Allgemeine Vertragsgesetze des Staates, in dem Sie Ihren Wohnsitz haben.
      </li>
      <li>
        Dass Sie zuvor die ALLGEMEINEN BEDINGUNGEN, die DATENSCHUTZERKLÄRUNG gelesen, verstanden und akzeptiert haben
        POLITIK und gegebenenfalls die BESONDEREN BEDINGUNGEN.
      </li>
    </ol>
  </li>
  <li>
    <p><b>Änderungen der ALLGEMEINEN BEDINGUNGEN</b></p>
    <p>
      Die Informationen auf dieser Website und die angebotenen Dienste werden ständig geändert und aktualisiert
      entwickelt, um die bestmögliche Erfahrung und den bestmöglichen Wert zu bieten.
    </p>
    <p>
      Wenn etwas geändert wird, bedeutet dies eine Änderung der ALLGEMEINEN BEDINGUNGEN, der BESONDEREN
      BEDINGUNGEN und/oder der DATENSCHUTZERKLÄRUNG, diese Änderung vorgenommen und veröffentlicht werden
      und aktualisiert. Daher wird empfohlen, sie alle regelmäßig und/oder oben zu lesen
      alles, bevor Sie einen Vertrag abschließen.
    </p>
    <p>
      Die aktualisierten ALLGEMEINEN BEDINGUNGEN, BESONDEREN BEDINGUNGEN und/oder DATENSCHUTZERKLÄRUNGEN werden auf verfügbar sein
      diese Website jederzeit.
    </p>
    <p>
      Der BENUTZER dieser Website akzeptiert solche Änderungen ab dem Zeitpunkt der weiteren Nutzung
      die Website und/oder die bereitgestellten Dienste.
    </p>
  </li>
  <li>
    <p><b>Wesentliche Pflichten des NUTZERS.</b></p>
    <p>
      Alle BENUTZER ab dem Moment, in dem sie beginnen, die Website zu nutzen und/oder einen der Dienste in Anspruch zu nehmen
      darauf angeboten werden, sind verpflichtet, die folgenden wesentlichen Pflichten gegenüber Dritten einzuhalten
      Parteien und der VERANTWORTLICHE:
    </p>
    <ol type="a">
      <li>
        Die Website zu nutzen, ohne illegale oder rechtswidrige Handlungen oder Handlungen zu begehen, die dem widersprechen
        die Bestimmungen der ALLGEMEINEN BEDINGUNGEN, der DATENSCHUTZERKLÄRUNG und gegebenenfalls der
        BESONDERE BEDINGUNGEN und deren Aktualisierungen.
      </li>
      <li>
        Die Website nicht zu beschädigen, zu deaktivieren, zu überlasten oder zu beeinträchtigen oder ihre normale Nutzung zu verhindern oder
        Vergnügen.
      </li>
      <li>
        Keine Versuche zu unternehmen, Zugriffsrechte zu verletzen, Daten zu manipulieren, zu duplizieren und zu exportieren
        Daten oder Informationen, die durch geistiges Eigentum oder andere gesetzliche Rechte geschützt sind, Zugriff
        eingeschränkten Bereichen der Computersysteme des VERANTWORTLICHEN oder Dritter, einzuführen
        Programme, Viren oder andere Geräte, die Änderungen am Computer verursachen oder verursachen können
        System des VERANTWORTLICHEN oder Dritter.
      </li>
      <li>
        Mit den technischen und rechtlichen Anforderungen vertraut sein, die für den korrekten Zugriff auf erforderlich sind
        diese Website und gegebenenfalls den Vertrag über die Produkte und/oder Dienstleistungen; was evtl
        beinhalten das Herunterladen bestimmter Computerprogramme oder anderer logischer Elemente auf ihre
        Computergeräte.
      </li>
      <li>
        Die Inhalte dürfen in keiner Weise verwendet, verlinkt, offengelegt, abgetreten oder an Dritte weitergegeben werden
        auf der Website oder einem Teil davon oder in den Informationen des VERANTWORTLICHEN veröffentlicht,
        ohne die ausdrückliche vorherige schriftliche Genehmigung des VERANTWORTLICHEN.
      </li>
      <li>
        Das Ansehen des CONTROLLERS in keiner Weise zu beschädigen, noch irgendwelche Aktivitäten durchzuführen oder zu machen
        jede Äußerung, die dem Ansehen und dem guten Namen von GESCASER schaden könnte.
      </li>
      <li>
        Die ALLGEMEINEN BEDINGUNGEN, die DATENSCHUTZERKLÄRUNG zu lesen, zu verstehen und, wenn Sie damit einverstanden sind, zu akzeptieren
        und gegebenenfalls die BESONDEREN BEDINGUNGEN und ihre Aktualisierungen und insbesondere die
        LINKS- UND/ODER COOKIES-RICHTLINIE des VERANTWORTLICHEN.
      </li>
    </ol>
  </li>
  <li>
    <p><b>Geistiges Eigentum</b></p>
    <p>
      Diese Website, ihr Inhalt und ihre Logos sind durch spanische und internationale geschützt
      Gesetze zum geistigen und gewerblichen Eigentum, und alle diese Rechte entsprechen ausschließlich den
      VERANTWORTLICHER, deren Verwendung vorbehalten bleibt.
    </p>
    <p>
      Die Vervielfältigung, Verbreitung, Übermittlung, Umwandlung und/oder öffentliche Zugänglichmachung und, in
      allgemein jede andere Form der unbefugten Nutzung des gesamten oder eines Teils des Inhalts von
      diese Website, ihr Design, ihre Auswahl und Präsentation der hierin enthaltenen Materialien ist
      verboten; Gleiches gilt für die auf dieser Website enthaltenen Marken und Handelsnamen. Solch
      Handlungen dürfen nur mit ausdrücklicher vorheriger schriftlicher Genehmigung des VERANTWORTLICHEN durchgeführt werden
      und sofern ausdrücklich auf das Eigentum des VERANTWORTLICHEN hingewiesen wird
      vorgenannte Rechte an geistigem und gewerblichem Eigentum.
    </p>
    <p>
      Durch die Nutzung der Website und/oder die Beauftragung der Dienste erwirbt der BENUTZER keinerlei Rechte daran
      sie, lediglich berechtigt, sie gemäß den Bestimmungen dieser ALLGEMEINEN zu verwenden
      BEDINGUNGEN und/oder gegebenenfalls die BESONDEREN BEDINGUNGEN.
    </p>
    <p>
      Für den Fall, dass Sie, der BENUTZER, Aktivitäten feststellen, die geistige oder geistige Eigentumsrechte verletzen könnten
      gewerblichen Schutzrechten oder sonstigen Rechten teilen Sie uns dies bitte per Nachricht an mit
      die folgende E-Mail-Adresse: gescaser@gescaser.com
    </p>
  </li>
  <li>
    <p><b>Datenschutzerklärung</b></p>
    <p>
      n Einhaltung der Bestimmungen der geltenden nationalen und europäischen Rechtsvorschriften über die
      Schutz personenbezogener Daten Wir informieren Sie, dass die personenbezogenen Daten unserer Kunden bzw
      Potenzielle Kunden können uns (beide als „Kunde(n)“ bezeichnet) entweder per
      unter Verwendung des auf unserer Website bereitgestellten Formulars (die „Website“), indem Sie eine E-Mail an die Adresse senden
      zu diesem Zweck durch E-Mails, die an unsere Fachleute oder andere Personen adressiert sind
      Kommunikationsmittel, werden in eine Datei aufgenommen, von der GESCASER (im Folgenden die
      VERANTWORTLICHER) mit der Umsatzsteuer-Identifikationsnummer A25276635 und Sitz in Sant Pere 1 aus
      Almacelles, ist Eigentümer und Datenverantwortlicher.
    </p>
    <ol type="a">
      <li>
        Einwilligung und erhobene Daten
        <p>
          Indem er dem VERANTWORTLICHEN die angeforderten personenbezogenen Daten zur Verfügung stellt, erklärt der Kunde dies
          er/sie hat die vorliegende Datenschutzerklärung gelesen und ausdrücklich akzeptiert und gibt sie daher ab
          seine/ihre freie, spezifische, eindeutige und informierte Zustimmung zur Verarbeitung durch den VERANTWORTLICHEN
          seine/ihre personenbezogenen Daten gemäß den Bestimmungen dieses Dokuments.
        </p>
        <p>
          Sofern nicht ausdrücklich anders angegeben, alle Informationen, die wir von unseren Kunden anfordern
          auf den auf der Website verfügbaren Formularen ist erforderlich. Weigerung, die erforderlichen Daten bereitzustellen
          die Bearbeitung der konkreten Anfrage unmöglich machen. Bitte informieren Sie uns
          unverzüglich über Änderungen Ihrer Daten, damit die Informationen in unseren Dateien enthalten sind
          immer aktuell und fehlerfrei. Der Kunde erklärt diesbezüglich, dass die
          bereitgestellten Informationen und Daten korrekt, aktuell und wahrheitsgemäß sind.
        </p>
      </li>
      <li>
        Zweck der Verarbeitung und Rechtsgrundlage
        <p>
          Zweck der Verarbeitung durch den VERANTWORTLICHEN ist die Bearbeitung der gestellten Anfrage, Beantwortung
          Ihre Anfragen und Auskunftsersuchen und pflegen und verwalten gegebenenfalls die
          berufliche, kommerzielle und/oder vertragliche Beziehung, die mit einem unserer
          Büros und/oder Fachkräfte (im Folgenden „Service(s)“).
        </p>
        <p>
          Die Rechtsgrundlage für die wird bearbeitet Ihrer personenbezogenen Daten durch den VERANTWORTLICHEN basiert auf: i)
          die Zustimmung des Kunden zu der in dieser Datenschutzrichtlinie beschriebenen Verarbeitung und ii) die
          Bereitstellung der vom Kunden angeforderten Dienste des VERANTWORTLICHEN.
        </p>
      </li>
      <li>
        An wen wir Ihre Daten weitergeben
        <p>
          Der VERANTWORTLICHE wird Ihre personenbezogenen Daten nicht an Dritte weitergeben, es sei denn, dies ist gesetzlich vorgeschrieben
          erforderlich oder wenn der Kunde uns zuvor dazu ermächtigt hat oder wenn es erforderlich ist
          für die Bereitstellung der angeforderten Dienste.
        </p>
        <p>
          Um dem Kunden den passenden Service anbieten zu können und die
          Beziehung mit Ihnen, der VERANTWORTLICHE beauftragt die Erbringung von Dienstleistungen mit bestimmten
          Unternehmen, zu denen unter Einhaltung der gesetzlichen Vorschriften Zugang gewährt werden kann
          Ihre personenbezogenen Daten ausschließlich für die Zwecke der Bereitstellung des Dienstes.
        </p>
      </li>
      <li>
        Internationale Übermittlung personenbezogener Daten
        <p>
          Die Datenschutz-Grundverordnung (DSGVO) bietet mehrere Mechanismen, um dies zu ermöglichen
          Übermittlung personenbezogener Daten außerhalb der Europäischen Union (EU). Diese Mechanismen wurden eingestellt
          bis zur Überprüfung, ob das Schutzniveau angemessen ist, oder zur Sicherstellung der Umsetzung
          angemessene Sicherheitsmaßnahmen bei der Übermittlung personenbezogener Daten in ein Nicht-EU-Land.
        </p>
        <p>
          Dieses Sicherheitsniveau kann durch Standardvertragsklauseln erreicht werden. es ist
          möglich, durch Verifizierung zu bestätigen, dass das Schutzniveau angemessen ist
          Verfahren wie die im Rahmen des EU-US Privacy Shield.
        </p>
        <p>
          In unseren laufenden Verträgen zur Datenverarbeitung verpflichten wir uns, einen Mechanismus vorzuhalten, der
          ermöglicht die Übermittlung personenbezogener Daten außerhalb der EU, wie im Datenschutz festgelegt
          Richtlinie. Darüber hinaus werden wir ab dem 25. Mai 2018 die entsprechende Zusage abgeben
          (einschließlich), wenn die DSGVO in Kraft tritt.
        </p>
        <p>
          Auch die europäischen Datenschutzbehörden haben unseren Standardvertrag bestätigt
          Klauseln erfüllen die erforderlichen Sicherheitsanforderungen. Dies zeigt, dass GESCASER
          vertraglichen Verpflichtungen der Datenschutzrichtlinie entsprechen und eine gesetzliche Grundlage bieten
          Rahmen für die Übermittlung personenbezogener Daten aus der EU in andere Teile der Welt.
        </p>
      </li>
      <li>
        Dauer der Speicherung personenbezogener Daten
        <p>
          Wir speichern Ihre personenbezogenen Daten für die Dauer des Vertragsverhältnisses bzw.
          ggf. bis Sie Ihren Wunsch äußern, Ihre personenbezogenen Daten aus unseren löschen zu lassen
          Datei. Von diesem Moment an wird der VERANTWORTLICHE Ihre personenbezogenen Daten für die Dauer gesperrt halten
          gesetzlich vorgeschriebenen Fristen, jedenfalls für die Dauer von 10 Jahren gem
          die Vorschriften zur Verhinderung von Geldwäsche und Terrorismusfinanzierung. Wir werden
          vernichten Sie Ihre personenbezogenen Daten nach Ablauf der gesetzlich vorgeschriebenen Frist.
        </p>
      </li>
      <li>
        Ausübung von Rechten
        <p>
          Sie können Ihre Rechte auf Auskunft, Berichtigung, Löschung, Einschränkung der
          Verarbeitung, Widerspruch und Übertragbarkeit personenbezogener Daten durch Zusendung einer schriftlichen Mitteilung
          mit einer Fotokopie Ihres Personalausweises an den VERANTWORTLICHEN unter folgender E-Mail-Adresse:
          gescaser@gescaser.com
        </p>
      </li>
      <li>
        Beschwerde bei einer Aufsichtsbehörde
        <p>
          Sie werden darüber informiert, dass Sie das Recht haben, eine Beschwerde bei den spanischen Daten einzureichen
          Protection Agency (www.aepd.es) als Aufsichtsbehörde, wenn Sie der Meinung sind, dass die
          Der VERANTWORTLICHE hat Ihre personenbezogenen Daten nicht gemäß den geltenden Bestimmungen verarbeitet
          Gesetze zum Schutz personenbezogener Daten.
        </p>
      </li>
      <li>
        Sicherheit
        <p>
          Der VERANTWORTLICHE verpflichtet sich, die technischen und organisatorischen Sicherheitsmaßnahmen zu treffen
          gesetzlich vorgeschrieben, um die Sicherheit Ihrer personenbezogenen Daten zu gewährleisten und deren Verhinderung zu verhindern
          Veränderung, Verlust, unbefugte Bearbeitung oder Zugriff unter Berücksichtigung des Zustands
          Technologie, die Art der gespeicherten Daten und die Risiken, denen sie ausgesetzt sind, alles in allem
          gemäß den Bestimmungen der geltenden Vorschriften.
        </p>
      </li>
      <li>
        Zuordnung von Drittdaten zum CONTROLLER
        <p>
          Im Falle der Bereitstellung der erforderlichen personenbezogenen Daten Dritter an den VERANTWORTLICHEN
          für die Bereitstellung der Dienste erklären Sie, dass Sie deren zuvor erhalten haben
          ausdrückliche Zustimmung, sie an den VERANTWORTLICHEN zu übertragen.
        </p>
      </li>
      <li>
        Änderungen unserer Datenschutzerklärung
        <p>
          Der VERANTWORTLICHE kann diese Datenschutzrichtlinie jederzeit aktualisieren. Eine aktualisierte Version davon
          Die Datenschutzrichtlinie ist immer auf der Plattform verfügbar. Wenn diese Änderungen Zwecke betreffen
          nicht in dieser Datenschutzerklärung vorgesehen, werden wir Sie rechtzeitig darüber informieren
          diese Nachbearbeitung per E-Mail (an die zuletzt angegebene Adresse), damit Sie ggf
          die Rechte auszuüben, die Sie für angemessen halten und die Ihnen im Rahmen der
          geltende Vorschriften.
        </p>
      </li>
    </ol>
  </li>
  <li>
    <p><b>Haftung</b></p>
    <p>
      Der VERANTWORTLICHE bemüht sich jederzeit, den Dienst mit dem höchstmöglichen Wert zu erbringen
      Qualität. Unbeschadet des Vorstehenden lehnen wir jede Haftung ab, die sich aus Fehlern,
      Unterbrechungen oder Schäden, die durch Systemausfälle, Interferenzen oder Unterbrechungen oder durch die
      Fehlfunktion des Dienstes.
    </p>
    <p>
      Ebenso lehnt der CONTROLLER jegliche Haftung und/oder Verantwortung für Schäden jeglicher Art ab
      oder Natur, die aus der rechtswidrigen Handlung Dritter mit irgendwelchen Mitteln oder aus der Verwendung resultieren
      der Website auf unangemessene oder unangemessene Weise durch die Benutzer und unter keinen Umständen
      trägt der CONTROLLER jegliche Haftung für die Handlung oder Fahrlässigkeit anderer, die
      kann die Server und andere Computerausrüstung von direkt oder indirekt beeinflussen oder nicht
      NUTZER oder Dritte.
    </p>
    <p>
      Der BENUTZER erklärt sich damit einverstanden, den VERANTWORTLICHEN, seine Tochtergesellschaften, Mitarbeiter,
      Manager, Mitarbeiter und/oder Mitarbeiter von jeglichen Ansprüchen oder Forderungen, einschließlich Anwaltskosten,
      die von Dritten als Folge der unangemessenen oder unbefugten Nutzung des BENUTZERS vorgenommen wurden
      Website und/oder die angebotenen Dienste oder der Verstoß des BENUTZERS gegen die ALLGEMEINEN BEDINGUNGEN, SONDERBEDINGUNGEN
      BEDINGUNGEN, falls zutreffend, und/oder DATENSCHUTZERKLÄRUNG sowie Verstöße gegen Gesetze oder andere
      andere Rechte.
    </p>
    <p>
      Der VERANTWORTLICHE kann die Abwesenheit von Viren oder anderer Malware, die die Daten beeinträchtigen könnten, nicht garantieren
      Computerausrüstung des BENUTZERS. Letzteres sollte daher möglichst vermieden werden
      Risiken dieser Art angesichts des aktuellen Stands der Telekommunikation.
    </p>
    <p>
      Der VERANTWORTLICHE ist nicht verantwortlich für Unterbrechungen der Website, die durch höhere Gewalt oder verursacht werden
      Umstände, die außerhalb seiner Kontrolle liegen.
    </p>
    <p>
      Ebenso kann der VERANTWORTLICHE den Zugriff vorübergehend unterbrechen, aus Sicherheitsgründen oder aufgrund der
      Umstrukturierung von Computerressourcen, um den Dienst zur Nutzung der Website zu verbessern.
    </p>
    <p>
      Der VERANTWORTLICHE stellt dem NUTZER nur die auf der Website veröffentlichten Informationen zur Verfügung. Der Nutzer,
      ist daher allein verantwortlich für die korrekte Verwendung dieser Informationen und dafür, dies sicherzustellen
      eine solche Nutzung erfolgt in Übereinstimmung mit den ALLGEMEINEN BEDINGUNGEN, wo die BESONDEREN BEDINGUNGEN
      anwendbar, und die DATENSCHUTZERKLÄRUNG.
    </p>
    <p>
      Wenn der BENUTZER mit der Nutzung der Website, ihren Inhalten oder Diensten oder mit irgendwelchen
      Teil dieser ALLGEMEINEN BEDINGUNGEN, der DATENSCHUTZERKLÄRUNG und/oder BESONDEREN BEDINGUNGEN, sein oder ihr
      Das einzige und ausschließliche Rechtsmittel besteht darin, den Zugriff auf die Website und/oder die Nutzung ihrer Dienste einzustellen.
    </p>
  </li>
  <li>
    <p><b>Links und&nbsp;Cookies</b></p>
    <p>
      Wenn Sie eine Verbindung zu einem anderen Link oder Banner als dieser Website herstellen, sollten Sie sich bewusst sein, dass Sie
      eine andere Website im Internet besuchen, deren Nutzungsregeln und Datenschutzrichtlinien gelten
      außerhalb der Kontrolle des CONTROLLERS liegen. Daher ist es ratsam, solche Regeln zu überprüfen
      Nutzungsbedingungen und Datenschutzbestimmungen, um die geltenden Regeln und deren Verarbeitung zu erfahren
      aus Ihren persönlichen Daten machen.
    </p>
    <p>
      Es ist untersagt, Inhalte oder Dienste in Websites Dritter einzubinden, die deren schaden könnten
      Bild dieser Website oder des VERANTWORTLICHEN, und letzterer behält sich das Recht vor, dieses zu verbieten, einzuschränken
      oder die Löschung der hergestellten Links zu verlangen, wenn es angemessen erscheint, ohne unter irgendwelchen
      Umstände erforderlich sind, um aus diesem Grund irgendeine Art von Entschädigung zu leisten.
    </p>
    <p>
      Die Website www (die „Website“) des VERANTWORTLICHEN verwendet Cookies, bei denen es sich um kleine Dateien handelt
      Informationen über Ihr Surfen auf dieser Website enthalten und deren Hauptzweck darin besteht
      Vereinfachen Sie Ihr Surfen hierin. Bitte lesen Sie weiter, um mehr über die von uns verwendeten Cookies zu erfahren.
      ihren Zweck und andere Informationen von Interesse.
    </p>
    <p>Was sind Cookies?</p>
    <p>
      Ein Cookie ist eine Datei, die auf der Website platziert wird, wenn Sie darauf zugreifen und sie verwenden. Cookies ermöglichen uns dies
      Informationen über Ihre Surfgewohnheiten auf der Website speichern und abrufen. Die Informationen, die sie
      beziehen sich beispielsweise auf die Anzahl der von Ihnen besuchten Seiten, die Häufigkeit und Wiederholung
      der Besuche, die Dauer Ihres Besuchs usw.
    </p>
    <p>Was Art von Cookies, die wir verwenden?</p>
    <ol type="a">
      <li>
        Nach Angaben der Stelle, die sie verwaltet:
        <p>
          Eigene Cookies: solche, die von unseren Computern oder Domains an Ihren Computer gesendet werden
          die die von Ihnen angeforderte Dienstleistung erbringen.
        </p>
        <p>
          Drittanbieter-Cookies: Cookies, die von einem Computer oder einer Domäne an Ihren Computer gesendet werden
          wird nicht von uns verwaltet, sondern von einer anderen verbundenen Organisation.
        </p>
      </li>
      <li>
        Je nachdem, wie lange sie aktiv bleiben:
        <p>
          Sitzungscookies: diejenigen, die die notwendigen Informationen während der Sitzung speichern und die
          damit zusammenhängende Änderungen.
        </p>
        <p>
          Permanente Cookies: Sie werden auf der Festplatte gespeichert und lesen unsere Website jedes Mal, wenn Sie sie verwenden
          einen Gegenbesuch machen. Eine permanente Website hat ein bestimmtes Ablaufdatum. Das Cookie stoppt
          nach diesem Datum arbeiten.
        </p>
      </li>
      <li>
        Je nach Zweck:
        <p>
          Technische Cookies: Sie sind für die Navigation und das ordnungsgemäße Funktionieren der erforderlich
          Webseite.
        </p>
        <p>
          Anpassungscookies: Cookies, mit denen Sie mit vordefinierten Cookies auf den Dienst zugreifen können
          Merkmale abhängig von einer Reihe von Kriterien Merkmale, wie z. B. Sprache, die Art der
          Browser, über den auf den Dienst zugegriffen wird, das Gebietsschema, von dem aus Sie auf den Dienst zugreifen,
          etc.
        </p>
        <p>
          Analyse-Cookies: solche, die es uns ermöglichen, die Anzahl der Nutzer und damit die
          Messung und statistische Analyse der Nutzung der angebotenen Dienste durch die Nutzer.
        </p>
        <p>
          Wenn Sie die Verwendung von Cookies auf unserer Website nicht zulassen möchten, befolgen Sie diese bitte
          Anweisungen:
        </p>
        <p>
          Diese Verfahren unterliegen der Überarbeitung oder Änderung durch die Entwickler von Browsern, also
          Wir können nicht garantieren, dass jederzeit die letzte vollständig konforme Version verfügbar ist.
        </p>
        <p>
          Um die Installation von Cookies zu verhindern, folgen Sie bitte den Anweisungen Ihres Browsers:
        </p>
        <p>
          <b>Internet Explorer:</b> Klicken Sie auf „Extras &gt; Internetoptionen“ und wählen Sie dann aus
          Reiter „Datenschutz“. Wählen Sie die gewünschte Einstellung aus und klicken Sie auf die Schaltfläche Erweiterte Einstellungen.
          Aktivieren Sie das Kästchen „Automatische Cookie-Behandlung aufheben“. Wählen Sie „Akzeptieren“ oder „Blockieren“
          Möglichkeit. Wir empfehlen, die Option „Session-Cookies immer zulassen“ zu aktivieren, um dies zu ermöglichen
          optimales Surfen auf unserer Website.
        </p>
        <p>
          <b>Firefox:</b> Klicken Sie auf „Extras &gt; Optionen“ in der Menüleiste und wählen Sie „Datenschutz“
          Tab. Aktivieren oder deaktivieren Sie je nach verwendeter Browserversion „Cookies akzeptieren“.
          von Websites“ oder „Websites mitteilen, dass ich nicht verfolgt werden möchte“. Alle Cookies können installiert werden
          entfernt, indem Sie auf die Option „Einzelne Cookies entfernen“ klicken.
        </p>
        <p>
          <b>Chrome:</b> Klicken Sie auf „Tools &gt; Einstellungen“ und wählen Sie dann die Registerkarte „Datenschutz &gt;
          Inhaltseinstellungen". Nachdem Sie die Option „Erweiterte Optionen anzeigen“ ausgewählt haben, wählen Sie die gewünschte aus
          Cookie-Einstellung. Wenn Sie nicht möchten, dass Cookies installiert werden, können Sie die Option auswählen
          "Blockieren Sie Cookies von Drittanbietern und Standortdaten".
        </p>
        <p>
          <b>Oper:</b> Unter „Einstellungen &gt; Optionen“ gehen Sie zur Registerkarte „Erweitert“. In dem
          Wählen Sie im Menü „Cookies“ die Optionen „Akzeptieren“ oder „Cookies niemals akzeptieren“. Wenn du möchtest
          Wir haben eine größere Kontrolle über die Cookies, die auf Ihrem Computer installiert werden
          empfehlen Ihnen, die Option „Vor dem Akzeptieren von Cookies fragen“ auszuwählen.
        </p>
        <p>
          <b>Safari:</b> Klicken Sie auf „Tools &gt; Einstellungen“ und wählen Sie die Registerkarte „Sicherheit“. Unter dem
          Überschrift „Cookies akzeptieren“ wählen Sie „Immer“ oder „Nie“. Wenn Sie ein iPhone haben, müssen Sie zu gehen
          „Einstellungen &gt; Safari“ und wählen Sie dann aus, ob Sie Cookies akzeptieren möchten oder nicht.
        </p>
      </li>
    </ol>
    <p>
      Wenn Sie einen anderen Browser verwenden, erhalten Sie weitere Informationen zur Konfiguration der Installation von
      Cookies über den Hilfebereich oder die Hilfe dieses Browsers.
    </p>
    <p>
      Wenn Sie Hilfe bei der Konfiguration Ihrer Browser-Cookies benötigen, senden Sie uns bitte eine
      E-Mail an gescaser@gescaser.com und wir werden uns so schnell wie möglich mit Ihnen in Verbindung setzen, um Ihnen zu helfen.
    </p>
    <p>Aktualisierungen und Änderungen der Cookie-Richtlinie:</p>
    <p>
      Der VERANTWORTLICHE kann diese Cookie-Richtlinie in Übereinstimmung mit gesetzlichen und behördlichen Vorschriften ändern
      Anforderungen oder mit dem Ziel, diese Richtlinie an die Anweisungen des
      Spanische Datenschutzbehörde, weshalb dem Benutzer empfohlen wird, sie regelmäßig zu besuchen.
    </p>
  </li>
  <li>
    <p><b>Nichtigkeit und Unwirksamkeit von Klauseln</b></p>
    <p>
      Wenn ein Jede Klausel, die in diesen ALLGEMEINEN BEDINGUNGEN und/oder den BESONDEREN BEDINGUNGEN enthalten ist, wird erklärt
      ganz oder teilweise nichtig oder unwirksam, so gilt die Nichtigkeit oder Unwirksamkeit
      nur den nichtigen oder unwirksamen Bestimmungsteil und das ALLGEMEINE
      BEDINGUNGEN und BESONDERE BEDINGUNGEN bleiben in jeder anderen Hinsicht in Kraft, und so weiter
      Bestimmung oder ein betroffener Teil davon gilt als nichtig.
    </p>
  </li>
  <li>
    <p><b>Anwendbares Recht und Gerichtsstand</b></p>
    <p>
      Für alle Probleme oder Unstimmigkeiten, die in Bezug auf die Website, Inhalte und Dienste auftreten können
      vertraglich vereinbart, der BENUTZER und der VERANTWORTLICHE, wobei ausdrücklich auf jede andere Gerichtsbarkeit verzichtet wird
      gelten für sie, unterwerfen sich ausdrücklich dem spanischen Recht, der Zuständigkeit der spanischen Gerichte und, in
      insbesondere die Gerichte von Madrid, es sei denn, das anwendbare Recht bestimmt ausdrücklich etwas anderes
      Gerichtsbarkeit oder andere Rechtsvorschriften.
    </p>
  </li>
</ol>