<div class="container" *ngIf="!liteRegistrationCode">
  <div>
    <div class="auth-title-container">
      <div class="auth-title head-line-3">
        {{ 'register.code-form.registerInstallation' | translate }}
      </div>
      <div class="auth-subtitle m3-title-small">
        {{ 'register.code-form.registerFacilities' | translate }}
      </div>
    </div>
    <form
      [formGroup]="registrationCodeForm"
      #f="ngForm"
      (ngSubmit)="validateRegistrationCode()"
      *ngIf="!loading"
    >
      <div class="inputs">
        <div class="mat-form-field-container">
          <span class="fake-label m3-label-large">{{
            'register.code-form.registerCode' | translate
          }}</span>
          <mat-form-field class="input-full-width">
            <mat-label>{{ 'register.code-form.enterCode' | translate }}</mat-label>
            <input matInput formControlName="code" name="code" />
          </mat-form-field>
        </div>
      </div>
      <div class="buttons">
        <button
          type="button"
          type="submit"
          mat-flat-button
          color="primary"
          class="font-button"
          [disabled]="registrationCodeForm.pristine || !registrationCodeForm.valid"
        >
          <span class="material-symbols-outlined"> app_registration </span>
          <span class="btn-text">{{ 'auth.login.registerInstallation' | translate }}</span>
        </button>
        <div class="or-separator m3-label-large">
          {{ 'auth.login.orSeparator' | translate }}
        </div>
        <div
          class="drag-n-drop-container d-flex flex-column justify-content-between align-items-center flex-gap-1"
          appDragNDropFiles
          (fileDropped)="handleTXTFileInput($event)"
        >
          <input
            #fileDropRef
            type="file"
            id="fileDropRef"
            (change)="handleTXTFileInput($event.target.files)"
            [accept]="'.txt'"
          />
          <div class="m3-title-small drag-n-drop-text">
            {{ 'auth.register.drag-n-drop-registration-qr-here' | translate }}
          </div>
          <div>
            <span class="material-symbols-outlined upload-icon"> upload </span>
          </div>
          <button
            type="button"
            mat-stroked-button
            color="primary"
            class="button-primary font-button button-register button-outline"
            (click)="fileDropRef.click()"
          >
            <span class="material-symbols-outlined icon"> login </span>
            <span class="btn-text">{{ 'buttons.browse-for-file' | translate }}</span>
          </button>
        </div>
        <div class="or-separator m3-label-large">
          {{ 'auth.login.orSeparator' | translate }}
        </div>
        <button
          type="button"
          mat-stroked-button
          color="primary"
          class="button-primary font-button button-register button-outline"
          [routerLink]="['/auth/login']"
        >
          <span class="material-symbols-outlined icon"> login </span>
          <span class="btn-text">{{ 'auth.login.login' | translate }}</span>
        </button>
      </div>
    </form>
  </div>
</div>
<app-register-form
  *ngIf="liteRegistrationCode"
  [dataProvider]="dataProvider"
  [registerLiteData]="registerLiteData"
  [knownCompanyName]="knownCompanyName"
></app-register-form>
