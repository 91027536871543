import * as HttpStatus from 'http-status-codes';
import { HttpErrorResponse } from '@angular/common/http';
import { TranslationMWService } from '@services/translation-middleware/translation-middleware.service';
import { ToastrService } from 'ngx-toastr';

export const errorSavingInstallation = (
  error: HttpErrorResponse,
  toastr: ToastrService,
  translationMWService: TranslationMWService
) => {
  if ([HttpStatus.StatusCodes.CONFLICT, HttpStatus.StatusCodes.FORBIDDEN].includes(error.status)) {
    const errorMessageLabel = 'server.error.message.'.concat(
      error.error.error.toLowerCase().replace(/\s/g, '-').replace(/'/g, '')
    );
    toastr.error(translationMWService.instant(errorMessageLabel));
  }
};
