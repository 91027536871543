import * as HttpStatus from 'http-status-codes';
import { v4 as uuid } from 'uuid';
import { Component } from '@angular/core';
import { TranslationMWService } from '@services/translation-middleware/translation-middleware.service';
import { MatDialog } from '@angular/material/dialog';
import { LocalStorageService } from '@services/localStorage/local-storage.service';
import { BaseComponent } from '@shared/components/base.component';
import {
  RegisterLiteActionTokenDataEntity,
  RegisterPlusActionTokenDataEntity,
} from '@entities/action-token-data.entity';
import { LoginDialogComponent } from '@shared/components/dialogs/login-dialog/login-dialog.component';
import { AuthLoginResponse } from '@entities/auth-login-response.entity';
import { BEInstallationsService } from '@services/backend-services/installations/be-installations.service';
import { first } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { InstallationEntity } from '@entities/installation.entity';
import { downloadEncryptedReference, initializeClass } from '@utils/utils';
import { errorSavingInstallation } from '@utils/installations';
import { DataProvidersConstants } from '@constants/data-providers.constants';
import { BEDesktopService } from '@services/backend-services/desktop/be-desktop.service';

@Component({ template: '' })
export class BaseRegisterComponent extends BaseComponent {
  public dataProvider: number;
  public registerLiteData: RegisterLiteActionTokenDataEntity;
  public registerPlusData: RegisterPlusActionTokenDataEntity;

  constructor(
    public router: Router,
    public dialog: MatDialog,
    public toastr: ToastrService,
    public translationMWService: TranslationMWService,
    public localStorageService: LocalStorageService,
    public beInstallationsService: BEInstallationsService,
    public beDesktopService: BEDesktopService
  ) {
    super(localStorageService);
  }

  offerToLogin(): void {
    this.dialog
      .open(LoginDialogComponent)
      .afterClosed()
      .subscribe((response: AuthLoginResponse) => {
        if (response) {
          this.doAfterLogin(response);
        }
      });
  }

  protected doAfterLogin(response: AuthLoginResponse): void {
    this.localStorageService.feedFromLoginResponse(response);
    // set user's language preference
    this.translationMWService.setTransLanguage(response.language);

    if (this.registerLiteData) {
      // claim Lite installation
      this.beInstallationsService
        .claimInstallationByRegistrationCode(this.registerLiteData.registrationCode)
        .pipe(first())
        .subscribe(
          (installation) => {
            this.toastr.success(
              this.translationMWService.instant('installation.claim.toastr.success.message', {
                installation: installation.name,
              }),
              this.translationMWService.instant('installation.claim.toastr.success.title')
            );
            // move to role's specific main page
            void this.router.navigate([this.localStorageService.userRolesMainPage]);
          },
          (error: HttpErrorResponse) => {
            let errorMessageLabel = 'installation.claim.toastr.error.';

            if (error.status === HttpStatus.StatusCodes.NOT_FOUND) {
              errorMessageLabel += 'not-found';
            }
            if (error.status === HttpStatus.StatusCodes.PRECONDITION_FAILED) {
              errorMessageLabel += 'not-claimable';
            }
            if (error.status === HttpStatus.StatusCodes.UNAUTHORIZED) {
              errorMessageLabel += 'not-authorized';
            }
            if (!errorMessageLabel.endsWith('.')) {
              this.toastr.error(this.translationMWService.instant(errorMessageLabel));
            }
            // log user out
            this.localStorageService.clear();
          }
        );
    }
    if (this.registerPlusData && this.registerPlusData.valid) {
      // create new installation with provided reference
      const payload = initializeClass(InstallationEntity, {
        id: uuid(),
        dataProvider: DataProvidersConstants.CTCPLUS.numberValue,
        name: this.registerPlusData.reference,
        reference: this.registerPlusData.reference,
        desktopKey: this.registerPlusData.desktopKey,
        temperatureUoM: 'C',
        sensorAlarms: [],
      });
      this.beInstallationsService
        .create(payload)
        .pipe(first())
        .subscribe(
          (installation) => {
            this.toastr.success(
              this.translationMWService.instant(`installation.form.create.toastr.success.message`)
            );
            downloadEncryptedReference(this.beDesktopService, this.registerPlusData.reference);
            // move to role's specific main page
            void this.router.navigate([this.localStorageService.userRolesMainPage]);
          },
          (error) => {
            errorSavingInstallation(error, this.toastr, this.translationMWService);
            // log user out
            this.localStorageService.clear();
          }
        );
    }
  }
}
