import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { initializeClass } from '@utils/utils';
import { ToastrService } from 'ngx-toastr';
import { TranslationMWService } from '@services/translation-middleware/translation-middleware.service';
import { ShowFullRegisterFormService } from '@modules/register/show-full-register-form.service';
import { MatDialog } from '@angular/material/dialog';
import { LocalStorageService } from '@services/localStorage/local-storage.service';
import { BEInstallationsService } from '@services/backend-services/installations/be-installations.service';
import { RegisterPlusActionTokenDataEntity } from '@entities/action-token-data.entity';
import { DataProvidersConstants } from '@constants/data-providers.constants';
import { BaseRegisterComponent } from '../components/base-register.component';
import { BEDesktopService } from '@services/backend-services/desktop/be-desktop.service';

@Component({
  selector: 'app-register-qr-code',
  templateUrl: './register-qr-code.component.html',
  styleUrls: ['./register-qr-code.component.scss'],
})
export class RegisterQRCodeComponent extends BaseRegisterComponent implements OnInit {
  public dataProvider: number = DataProvidersConstants.CTCPLUS.numberValue;
  public registerPlusData: RegisterPlusActionTokenDataEntity;

  private checkSum: string;

  constructor(
    public router: Router,
    public dialog: MatDialog,
    public toastr: ToastrService,
    public translationMWService: TranslationMWService,
    public localStorageService: LocalStorageService,
    public beInstallationsService: BEInstallationsService,
    public beDesktopService: BEDesktopService,
    private activatedRoute: ActivatedRoute,
    private showFullRegisterFormService: ShowFullRegisterFormService
  ) {
    super(
      router,
      dialog,
      toastr,
      translationMWService,
      localStorageService,
      beInstallationsService,
      beDesktopService
    );
  }

  ngOnInit() {
    // get all URL parameters
    const reference = atob(this.activatedRoute.snapshot.paramMap.get('reference'));
    const desktopKey = atob(this.activatedRoute.snapshot.paramMap.get('desktopKey'));
    this.registerPlusData = initializeClass(RegisterPlusActionTokenDataEntity, {
      desktopKey,
      reference,
    });
    this.checkSum = this.activatedRoute.snapshot.paramMap.get('checkSum');

    if (this.validCheckSum) {
      this.showFullRegisterFormService.showForm();
      this.offerToLogin();
    }
  }

  get validCheckSum(): boolean {
    return (
      this.checkSum &&
      this.registerPlusData &&
      this.registerPlusData.valid &&
      this.registerPlusData.matchesCheckSum(this.checkSum.toUpperCase())
    );
  }
}
