import { Component, OnDestroy, OnInit } from '@angular/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Observable, Subject, Subscription } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { LocalStorageService } from '@services/localStorage/local-storage.service';
import { BEAuthService } from '@services/backend-services/auth/be-auth.service';
import { BaseComponent } from '@shared/components/base.component';
import { BEProfileService } from '@services/backend-services/profile/be-profile.service';
import { MainMenuItemEntity } from '@core/layout/entities/main-menu-item.entity';
import { initializeClass } from '@utils/utils';
import { MixedDataProvidersNavService } from './mixed-data-providers-nav.service';
import { ViewConfig } from '@config/view.config';
import { NavigationRoutesService } from '@services/navigation-routes/navigation-routes.service';
import { Router } from '@angular/router';
import { RouteInfoEntity } from '@services/navigation-routes/route-info.entity';

@Component({
  selector: 'app-nav',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss'],
})
export class NavigationComponent extends BaseComponent implements OnInit, OnDestroy {
  isHandset$: Observable<boolean> = this.breakpointObserver
    .observe(Breakpoints.HandsetPortrait)
    .pipe(
      map((result) => result.matches),
      shareReplay()
    );

  public menuItems: MainMenuItemEntity[];
  public currentRouteInfo: RouteInfoEntity = RouteInfoEntity.INITIAL;
  public sideNavOpenState = true;
  public mixedDataProviders = false;
  public scrollbarStyle = ViewConfig.SCROLLBAR_STYLE;
  public initials: string;

  private subscriptions: Subscription[];

  constructor(
    public localStorageService: LocalStorageService,
    private breakpointObserver: BreakpointObserver,
    private beAuthService: BEAuthService,
    private beProfileService: BEProfileService,
    private mixedDataProvidersNavService: MixedDataProvidersNavService,
    private navigationRoutesService: NavigationRoutesService,
    private router: Router
  ) {
    super(localStorageService);

    this.menuItems = [
      /*
        ONLY for SUPER-ADMINs
      */
      initializeClass(MainMenuItemEntity, {
        onlyForRoles: [this.superAdminRoleId],
        link: `/dashboard`,
        name: `nav.options.dashboard`,
        icon: 'dashboard',
      }),
      initializeClass(MainMenuItemEntity, {
        onlyForRoles: [this.superAdminRoleId],
        link: `/companies`,
        name: `nav.options.companies`,
        icon: 'domain',
      }),
      initializeClass(MainMenuItemEntity, {
        onlyForRoles: [this.superAdminRoleId],
        name: `nav.options.installations`,
        icon: 'holiday_village',
        link: '/installations/all/ctcplus',
        children: [
          // initializeClass(MainMenuItemEntity, {
          //   link: '/installations/of-company',
          //   name: 'nav.options.available-installations',
          //   icon: 'location_away',
          //   onlyForRoles: [this.superAdminRoleId],
          // }),
          initializeClass(MainMenuItemEntity, {
            link: '/installations/all/ctcplus',
            name: 'nav.options.ctc-plus',
            icon: 'add_to_queue',
            onlyForRoles: [this.superAdminRoleId],
          }),
          initializeClass(MainMenuItemEntity, {
            link: '/installations/all/ctclite',
            name: 'nav.options.ctc-lite',
            icon: 'smartphone',
            onlyForRoles: [this.superAdminRoleId],
          }),
        ],
      }),
      // Old "Administrators" menu option
      // initializeClass(MainMenuItemEntity, {
      //   onlyForRoles: [this.superAdminRoleId],
      //   link: `/${this.superAdminRoleName}/users`,
      //   name: `nav.options.${this.superAdminRoleName}.users`,
      //   icon: 'group',
      // }),

      initializeClass(MainMenuItemEntity, {
        onlyForRoles: [this.superAdminRoleId],
        link: `/${this.superAdminRoleName}/all-users`,
        name: `nav.options.${this.superAdminRoleName}.allUsers`,
        icon: 'group',
      }),
      initializeClass(MainMenuItemEntity, {
        onlyForRoles: [this.superAdminRoleId],
        link: `/all/subscriptions`,
        name: `nav.options.subscriptions`,
        icon: 'subscriptions',
      }),
      initializeClass(MainMenuItemEntity, {
        onlyForRoles: [this.superAdminRoleId],
        link: `/payments`,
        name: `nav.options.payments`,
        icon: 'payments',
      }),
      initializeClass(MainMenuItemEntity, {
        onlyForRoles: [this.superAdminRoleId],
        link: `/price-plans/all`,
        name: `nav.options.price-plans`,
        icon: 'credit_card',
      }),
      initializeClass(MainMenuItemEntity, {
        onlyForRoles: [this.superAdminRoleId],
        link: `/resources/all`,
        name: `nav.options.resources`,
        icon: 'quick_reference_all',
      }),
      initializeClass(MainMenuItemEntity, {
        onlyForRoles: [this.superAdminRoleId],
        link: `/preferences`,
        name: `nav.options.preferences`,
        icon: 'tune',
      }),
      /*
        NOT for SUPER-ADMINs
      */
      // single dataProvider installations
      initializeClass(MainMenuItemEntity, {
        notForRoles: [this.superAdminRoleId],
        link: `/installations/of-my-company`,
        name: `nav.options.installations`,
        icon: 'holiday_village',
        visibility: () => !this.mixedDataProviders,
      }),
      // mixed dataProvider installations
      initializeClass(MainMenuItemEntity, {
        link: '/installations/of-my-company/ctcplus',
        notForRoles: [this.superAdminRoleId],
        name: `nav.options.installations`,
        icon: 'holiday_village',
        children: [
          initializeClass(MainMenuItemEntity, {
            link: '/installations/of-my-company/ctcplus',
            name: 'nav.options.ctc-plus',
            icon: 'add_to_queue',
            onlyForRoles: [],
          }),
          initializeClass(MainMenuItemEntity, {
            link: '/installations/of-my-company/ctclite',
            name: 'nav.options.ctc-lite',
            icon: 'smartphone',
            onlyForRoles: [],
          }),
        ],
        visibility: () => this.mixedDataProviders,
      }),
      initializeClass(MainMenuItemEntity, {
        onlyForRoles: [this.companyAdminRoleId],
        link: `/${this.companyAdminRoleName}/users`,
        name: `nav.options.${this.companyAdminRoleName}.users`,
        icon: 'group',
      }),
      initializeClass(MainMenuItemEntity, {
        onlyForRoles: [this.companyAdminRoleId],
        link: `/subscriptions`,
        name: `nav.options.subscriptions`,
        icon: 'subscriptions',
      }),
      initializeClass(MainMenuItemEntity, {
        onlyForRoles: [this.companyAdminRoleId],
        link: `/price-plans/active`,
        name: `nav.options.price-plans`,
        icon: 'credit_card',
      }),
      initializeClass(MainMenuItemEntity, {
        onlyForRoles: [this.companyAdminRoleId],
        link: `/company/tax_infos`,
        name: `nav.options.company-tax-infos`,
        icon: 'receipt_long',
      }),
      initializeClass(MainMenuItemEntity, {
        notForRoles: [this.superAdminRoleId],
        link: `/resources/public`,
        name: `nav.options.resources`,
        icon: 'quick_reference_all',
      }),
    ];
  }

  ngOnInit() {
    this.beProfileService.getProfile();
    this.subscriptions = [
      this.navigationRoutesService.isInstallationUrl.subscribe((itIs) => {
        // Auto-hide side navigation if the URL contains "installation/uuid"
        this.sideNavOpenState = !itIs;
      }),
      this.navigationRoutesService.routeInfo.subscribe((routeInfo) => {
        this.currentRouteInfo = routeInfo;
      }),
      this.beProfileService.profile.subscribe((user) => {
        if (user) {
          this.initials = user.firstname.substring(0, 1) + user.lastname.substring(0, 1);
        }
      }),
      this.mixedDataProvidersNavService.mixedDataProviders.subscribe((mixedDataProviders) => {
        this.mixedDataProviders = mixedDataProviders.mixed;
      }),
    ];
  }

  goToHome() {
    this.router.navigate(['/']);
  }

  ngOnDestroy() {
    this.subscriptions.forEach((el) => {
      el.unsubscribe();
    });
  }

  logout() {
    this.beAuthService.logout();
  }

  toggleSideNav() {
    this.sideNavOpenState = !this.sideNavOpenState;
  }

  showCurrentPageTitle(): boolean {
    return !['installation'].includes(this.currentRouteInfo.translationLabel);
  }
}
