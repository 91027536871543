import { Routes } from '@angular/router';
import { RegisterActionTokenComponent } from './pages/action-token/register-action-token.component';
import { RegisterOfflinePlusComponent } from './pages/offline-plus/register-offline-plus.component';
import { RegisterQRCodeComponent } from './pages/qr-code/register-qr-code.component';

export const registerRoutes: Routes = [
  {
    // path => '/register-split/offline'
    path: 'offline',
    component: RegisterOfflinePlusComponent,
  },
  {
    // path => '/register-split/token/:id'
    path: 'token/:id',
    component: RegisterActionTokenComponent,
  },
  {
    // path => '/register-split/qr'
    path: 'qr/:reference/:desktopKey/:checkSum',
    component: RegisterQRCodeComponent,
  },
];
