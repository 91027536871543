import { buildChecksum } from '@utils/utils';
import { Expose } from 'class-transformer';

export class RegisterActionTokenDataEntity {
  @Expose()
  public dataProvider: number;
}

export class RegisterPlusActionTokenDataEntity extends RegisterActionTokenDataEntity {
  @Expose()
  public checkSum: string;

  @Expose()
  public desktopKey: string;

  @Expose()
  public reference: string;

  get valid(): boolean {
    return !!this.reference && !!this.desktopKey;
  }

  matchesCheckSum(checkSum: string): boolean {
    return checkSum === buildChecksum(this.reference + this.desktopKey);
  }
}

export class RegisterLiteActionTokenDataEntity extends RegisterActionTokenDataEntity {
  @Expose()
  public registrationCode: string;

  @Expose()
  public email: string;
}
